import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import useAuth from "../hooks/useAuth";
// import SignIn from "../pages/SignIn";
// import MenuButton from "../components/MenuButton/MenuButton";
// import Footer from "../components/footer";
import MenuSider from "../components/MenuSider";
import "./LayoutAdmin.css";
import LandingPage from "../pages/Landing/LandingPage";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Contact from "../pages/Contact";

export default function LayoutAdmin(props) {
  const { routes } = props;
  const { Content } = Layout;
  const { user, isLoading } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [menuCollapsed, setMenuCollapsed] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const contactus = urlParams.get('contactus');

  if (token !== null) {
    const to = `/resetpassword:${token}`;
    return (
      <>
        <Route path="/resetpassword:token" component={ResetPassword} />
        <Redirect to={to} />
      </>
    );
  }

  if (contactus !== null) {
    return (
      <>
        <Route path="/contact" component={Contact} />
        <Redirect to="/contact" />
      </>
    );
  }

  if (!user && !isLoading) {
    return (
      <>
        <Route path="/landing" component={LandingPage} />
        <Redirect to="/landing" />
      </>
    );
  }

  if (user && !isLoading) {
    return (
      <Layout style={{ backgroundColor: "#F6F7F9" }}>
        <MenuSider
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
        />
        <Content style={{ overflowY: "auto", backgroundColor: "#F6F7F9" }} className="admin-content">
          <LoadRoutes routes={routes} />
        </Content>
        {/* <MenuButton /> */}
        {/* <Footer /> */}
      </Layout>
    );
  }

  return null;
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
