import React, { useState } from "react";
import { Col, Row, Spin, Button, Input, Select } from "antd";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { API_URL, apiVersion } from "../../api/config";
import TextArea from "antd/lib/input/TextArea";
import Logo from "../../assets/logo_vivi.png";
import "./Contact.css";
import { useHistory } from "react-router-dom";

const { Option } = Select;

export default function Contact() {
  const [nameLastName, setNameLastName] = useState("");
  const [email, setEmail] = useState("");
  const [reasonContact, setReasonContact] = useState(
    "Problemas con el pago de mi consulta"
  );
  const [description, setDescription] = useState("");

  const history = useHistory();

  const clickHome = () => {
    let currentUrl = window.location.href;
    let cleanedUrl = currentUrl.split("?")[0];

    window.history.replaceState({}, document.title, cleanedUrl);
    history.push({
      pathname: "/",
    });
  };

  const sendContact = async () => {
    if (!nameLastName) {
      toast.error("El nombre y apellido es obligatorio.", {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (!email) {
        toast.error("El Email es obligatorio.", {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (!description) {
          toast.error("La consulta es obligatoria.", {
            position: "top-right",
            autoClose: 2800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const operation_system = navigator.userAgentData.platform;
          const navegador = navigator.userAgent;
          const device = navigator.userAgentData.mobile
            ? "Dispositivo móvil"
            : "Computadora de escritorio";
          let latitud = "";
          let longitud = "";

          // if (navigator.geolocation) {
          //     navigator.geolocation.getCurrentPosition((position) => {
          //         latitud = position.coords.latitude;
          //         longitud = position.coords.longitude;
          //     }, (error) => {
          //         console.error('Error al obtener la ubicación:', error.message);
          //     });
          // }

          const result = await axios.post(`${API_URL}${apiVersion}/contactus`, {
            operation_system,
            navegador,
            device,
            latitud,
            longitud,
            description,
            email,
            nameLastName,
            reasonContact,
          });

          if (result.status === 200) {
            toast.success(result.data.message, {
              position: "top-right",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            // setTimeout(() => clickHome(), 3000);
          } else {
            toast.error(result.data.message, {
              position: "top-right",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          setDescription("");
          setNameLastName("");
          setEmail("");
        }
      }
    }
  };

  return (
    <>
      <div className="contact-page">
        <Row gutter={24} className="row-without-margin">
          <Col span={24}>
            <Row
              gutter={24}
              className="row-without-margin"
              style={{ padding: "0rem 0rem 2rem 0rem" }}
            >
              <Col span={24}>
                <label className="label-form">FORMULARIO DE CONTACTO</label>
                <h1
                  style={{ fontWeight: "bold", paddingTop: "1rem" }}
                  className="titleContactForm"
                >
                  Enviar un mensaje
                </h1>
              </Col>
            </Row>

            <Row gutter={24} className="row-without-margin">
              <Col span={24}>
                <Input
                  type="text"
                  onChange={(e) => setNameLastName(e.target.value)}
                  value={nameLastName}
                  className="register-form__input input-backgroundcolor"
                  placeholder="Nombre y Apellido"
                ></Input>
              </Col>
            </Row>

            <Row
              gutter={24}
              className="row-without-margin"
              style={{ paddingTop: "1rem" }}
            >
              <Col span={24}>
                <Input
                  rows={8}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="register-form__input input-backgroundcolor"
                  placeholder="Correo electrónico"
                ></Input>
              </Col>
            </Row>

            <Row
              gutter={24}
              className="row-without-margin"
              style={{ paddingTop: "2rem" }}
            >
              <Col span={24}>
                <TextArea
                  rows={8}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="input-backgroundcolor"
                  placeholder="Consulta"
                  style={{ borderRadius: "16px" }}
                ></TextArea>
              </Col>
            </Row>

            <Row
              gutter={24}
              className="row-without-margin"
              style={{ paddingTop: "2rem" }}
            >
              <Col span={24}>
                <Button
                  onClick={sendContact}
                  style={{
                    height: "40px",
                    backgroundColor: "#006AFF",
                    color: `white`,
                    borderRadius: "50px",
                    minWidth: "16vh",
                  }}
                >
                  Enviar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <ToastContainer></ToastContainer>
    </>
  );
}
