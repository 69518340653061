import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { API_URL, apiVersion } from "../api/config";
import { getAccessTokenApi } from "../api/auth";
import moment from "moment";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("USER"));
  const [notificationCount, setNotificationCount] = useState(0);
  const [reload, setReload] = useState(false);
  const token = getAccessTokenApi();

  const updateNotificationCount = (count) => {
    setNotificationCount(count);
  };

  const obtenerCantidadDeNotificaciones = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `${API_URL}${apiVersion}/notifications-by-userid?user_id=${user._id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (data?.notifications) {
          let notis = [];
          let notisHistory = [];
          let arraySinDuplicados = [];

          //mchuquimia (solución provisoria hasta encontrar el xq de los duplicados a la hora de guardar las notificaciones)
          data.notifications.forEach((obj, indice) => {
            const fechaFormateada = moment(obj.created).format(
              "DD/MM/YYYY HH:mm"
            );

            // Verificar si la fecha formateada ya existe en el nuevo array
            const existe = arraySinDuplicados.some((o) => {
              const fechaObj = moment(o.created).format("DD/MM/YYYY HH:mm");
              return (
                fechaObj === fechaFormateada &&
                obj.description === o.description
              );
            });

            if (!existe) {
              arraySinDuplicados.push(obj);
            }
          });

          arraySinDuplicados.forEach((element) => {
            element.created = moment(element.created).format(
              "DD/MM/YYYY HH:mm"
            );
            if (element.seen) {
              notisHistory.push(element);
            } else {
              notis.push(element);
              // updateNotificationSeen(element);
            }
          });

          updateNotificationCount(notis.length);
        }
      }
    } catch (error) {
      console.log("error get notifications count", error);
      updateNotificationCount(0);
    }
  };

  useEffect(() => {
    // Lógica para actualizar la cantidad de notificaciones
    obtenerCantidadDeNotificaciones(); // Debes implementar esta función
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, reload]);

  return (
    <NotificationContext.Provider
      value={{
        notificationCount,
        updateNotificationCount,
        setReload,
        reload,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
