import { API_URL, apiVersion } from "./config";

export function getBanks(token) {
    const url = `${API_URL}${apiVersion}/list-banks-c2p/`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function createPaymentBancamiga(token, bodyObj) {
    const url = `${API_URL}${apiVersion}/create-payment-bancamiga/`;
    const params = {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function cargoBolivares(token, bodyObj) {
    const url = `${API_URL}${apiVersion}/cargo-bolivares/`;
    const params = {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function CreateOrderBancamiga(token, bodyObj) {
    const url = `${API_URL}${apiVersion}/create-order/`;
    const params = {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function RetrieveOrderBancamiga(token, OrderID) {
    const url = `${API_URL}${apiVersion}/retrieve-order/${OrderID}`;
    const params = {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}