import React, { useEffect, useState } from "react";
import { getUserApi } from "../../api/user";
import useAuth from "../../hooks/useAuth";
import { getAccessTokenApi } from "../../api/auth";
import ViewProfile from "./components/ViewProfile";
import ViewAccountBank from "./components/ViewAccountBank";
import EditProfileForm from "./components/EditProfileForm";
import EditAccountBank from "./components/EditAccountBank";
import { Avatar, Spin, Row, Col } from "antd";
import NoAvatar from "../../assets/no-avatar.png";
import "./Perfil.css";
import { CameraAltOutlined } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import Modal from "../../components/Modal/";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import ChangeTariff from "../../components/ChangeTariff/ChangeTariff";

export default function Perfil() {
  const { setReloadUser, reloadUser } = useLocation();

  const { user } = useAuth();
  const token = getAccessTokenApi();
  const [userActive, setUserActive] = useState(null);
  const [state, setState] = useState("view");
  const [loading, setLoaading] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [reload, setReload] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [title, setTitle] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const getUserActive = () => {
    setLoaading(true);
    getUserApi(token, user.id).then((response) => {
      setLoaading(false);
      setUserActive(response.user);
    });
  };

  useEffect(() => {
    getUserActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const changeAvatar = async () => {
    document.getElementById(`avatar`).click();
  };

  const changePassword = () => {
    setVisibleModal(true);
    setTitle("Cambiar contraseña");
    setContentModal(
      <>
        <ChangePassword setVisibleModal={setVisibleModal} user={user} />
      </>
    );
  };

  const changeTariff = () => {
    setVisibleModal(true);
    setTitle("Tarifario");
    setContentModal(
      <>
        <ChangeTariff
          setVisibleModal={setVisibleModal}
          user={user}
          userActive={userActive}
          setReload={setReload}
          reload={reload}
        />
      </>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      {/* <Row style={{ display: "grid" }} className="containerMiPerfil"> */}
      <Row style={{ display: "grid" }}>
        <Row>
          <span className="span-text-title my-profile">Mi perfil</span>
        </Row>
        <Row>
          <Col xs={24} xl={6} className="col-card-profile-1">
            <div className="card-profile-1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {state === "edit" ? (
                  <div
                    style={{ position: "relative", cursor: "pointer" }}
                    onClick={changeAvatar}
                    className="avatar"
                  >
                    <Avatar size={80} src={userActive?.imageUrl || NoAvatar} />
                    <CameraAltOutlined
                      style={{
                        position: "absolute",
                        left: "24px",
                        bottom: "20px",
                        // right: "5px",
                        fontSize: 40,
                        color: "#fff",
                      }}
                    />
                    <input
                      id={`avatar`}
                      type="file"
                      onChange={(e) => {
                        const url = URL.createObjectURL(e.target.files[0]);

                        setUserActive({
                          ...userActive,
                          imageUrl: url,
                        });
                        setImageAvatar({
                          rawFile: e.target.files[0],
                        });
                      }}
                      style={{
                        position: `absolute`,
                        right: -10000,
                        display: "none",
                      }}
                    />
                  </div>
                ) : (
                  <div className="avatar">
                    <Avatar size={80} src={userActive?.imageUrl || NoAvatar} />
                  </div>
                )}
              </div>
              <div className="name-profile">
                <div>
                  <h3>{userActive?.fullName}</h3>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span
                    style={{
                      color: "#A5B4CB",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                    className="textRoboto"
                  >
                    {userActive?.branchesOfMedicine.length > 0
                      ? userActive?.branchesOfMedicine[0].title
                      : ""}
                  </span>
                </div>
              </div>

              <div
                style={{ color: state === "view" ? "#006AFF" : "black" }}
                className="menuItemPerfil btn-changePassword"
              >
                <span
                  onClick={() => {
                    setState("view");
                  }}
                  className="textRoboto"
                >
                  Datos personales
                </span>
              </div>

              {userActive?.role === "doctor" && (
                <>
                  <div
                    style={{
                      color: state === "viewAccount" ? "#006AFF" : "black",
                    }}
                    className="menuItemPerfil btn-changePassword"
                  >
                    <span
                      onClick={() => {
                        setState("viewAccount");
                      }}
                      className="textRoboto"
                    >
                      Datos bancarios
                    </span>
                  </div>

                  <div
                    style={{ color: "black" }}
                    className="menuItemPerfil btn-changePassword"
                  >
                    <span onClick={changeTariff} className="textRoboto">
                      Tarifario
                    </span>
                  </div>
                </>
              )}

              {userActive?.google === undefined && (
                <div
                  style={{ color: "black", paddingBottom: "5rem" }}
                  className="menuItemPerfil btn-changePassword"
                >
                  <span onClick={changePassword} className="textRoboto">
                    Cambiar contraseña
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} xl={18} className="col-card-profile-2">
            {state === "view" && <ViewProfile setState={setState} />}
            {state === "edit" && (
              <EditProfileForm
                user={userActive}
                setReloadUser={setReloadUser}
                setState={setState}
                imageAvatar={imageAvatar}
                reloadUser={reloadUser}
                state={state}
                setReload={setReload}
                reload={reload}
              />
            )}

            {/* BankAccount */}
            {state === "viewAccount" && <ViewAccountBank setState={setState} />}
            {state === "editAccount" && (
              <EditAccountBank
                user={userActive}
                setReloadUser={setReloadUser}
                setState={setState}
                imageAvatar={imageAvatar}
                reloadUser={reloadUser}
                state={state}
                setReload={setReload}
                reload={reload}
              />
            )}
          </Col>
        </Row>
      </Row>

      <Modal
        children={contentModal}
        title={title}
        visible={visibleModal}
        setIsVisible={setVisibleModal}
        closable={false}
        className="modalSmall"
      />
    </>
  );
}
