import React, { useState, useEffect } from "react";
import "./CookiesNotice.css";

const CookieNotice = ({ cookiesAccepted }) => {
  const [accepted, setAccepted] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [activeTab, setActiveTab] = useState("consent");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (cookiesAccepted === "true" || cookiesAccepted === "false") {
      setAccepted(true);
    }
  }, [cookiesAccepted]);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", true);
    setAccepted(true);
  };

  const rechazoCookies = () => {
    localStorage.setItem("cookiesAccepted", false);
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <div className="cookie-banner">
      {!detailView ? (
        <div className="cookie-content">
          <span className="cookie-text">
            Las cookies de este sitio web se usan para personalizar el contenido
            y los anuncios, ofrecer funciones de redes sociales y analizar el
            tráfico. Además, compartimos información sobre el uso que haga del
            sitio web con nuestros partners de redes sociales, publicidad y
            análisis web, quienes pueden combinarla con otra información que les
            haya proporcionado o que hayan recopilado a partir del uso que haya
            hecho de sus servicios.
          </span>
          <span className="cookie-details" onClick={() => setDetailView(true)}>
            {"Mostrar detalles >"}
          </span>
        </div>
      ) : (
        <>
          <div className="cookie-tabs">
            <div
              className={`cookie-tab ${
                activeTab === "consent" ? "active" : ""
              }`}
              onClick={() => handleTabClick("consent")}
            >
              Consentimiento
            </div>
            <div
              className={`cookie-tab ${
                activeTab === "details" ? "active" : ""
              }`}
              onClick={() => handleTabClick("details")}
            >
              Detalles
            </div>
            <div
              className={`cookie-tab ${activeTab === "more" ? "active" : ""}`}
              onClick={() => handleTabClick("more")}
            >
              Acerca de las Cookies
            </div>
          </div>

          {activeTab === "consent" && (
            <div
              className="cookie-content"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <span className="cookie-text">
                Las cookies de este sitio web se usan para personalizar el
                contenido y los anuncios, ofrecer funciones de redes sociales y
                analizar el tráfico. Además, compartimos información sobre el
                uso que haga del sitio web con nuestros partners de redes
                sociales, publicidad y análisis web, quienes pueden combinarla
                con otra información que les haya proporcionado o que hayan
                recopilado a partir del uso que haya hecho de sus servicios.
              </span>
            </div>
          )}

          {activeTab === "details" && (
            <div
              className="cookie-content"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <div className="cookie-item">
                <h4>Necesarias</h4>
                <p>
                  - Las cookies necesarias ayudan a hacer una página web
                  utilizable activando funciones básicas como la navegación en
                  la página y el acceso a áreas seguras de la página web. La
                  página web no puede funcionar adecuadamente sin estas cookies.
                </p>
              </div>
              <div className="cookie-item">
                <h4>Preferencias</h4>
                <p>
                  - Las cookies de preferencias permiten a la página web
                  recordar información que cambia la forma en que la página se
                  comporta o el aspecto que tiene, como su idioma preferido o la
                  región en la que usted se encuentra.
                </p>
              </div>
              <div className="cookie-item">
                <h4>Estadística</h4>
                <p>
                  - Las cookies estadísticas ayudan a los propietarios de
                  páginas web a comprender cómo interactúan los visitantes con
                  las páginas web reuniendo y proporcionando información de
                  forma anónima.
                </p>
              </div>
            </div>
          )}

          {activeTab === "more" && (
            <div
              className="cookie-content"
              style={{ maxHeight: "350px", overflowY: "auto" }}
            >
              <div className="cookie-item">
                <p>
                  - Las cookies son pequeños archivos de texto que las páginas
                  web pueden utilizar para hacer más eficiente la experiencia
                  del usuario.
                </p>
              </div>
              <div className="cookie-item">
                <p>
                  - La ley afirma que podemos almacenar cookies en su
                  dispositivo si son estrictamente necesarias para el
                  funcionamiento de esta página. Para todos los demás tipos de
                  cookies necesitamos su permiso.
                </p>
              </div>
              <div className="cookie-item">
                <p>
                  - Esta página utiliza tipos diferentes de cookies. Algunas
                  cookies son colocadas por servicios de terceros que aparecen
                  en nuestras páginas.
                </p>
              </div>
              <div className="cookie-item">
                <p>
                  - En cualquier momento puede cambiar o retirar su
                  consentimiento desde la Declaración de cookies en nuestro
                  sitio web.
                </p>
              </div>
              <div className="cookie-item">
                <p>
                  - Obtenga más información sobre quiénes somos, cómo puede
                  contactarnos y cómo procesamos los datos personales en nuestra
                  Política de privacidad.
                </p>
              </div>
              <div className="cookie-item">
                <p>
                  - Al contactarnos respecto a su consentimiento, por favor,
                  indique el ID y la fecha de su consentimiento.
                </p>
              </div>
            </div>
          )}
          <div style={{ paddingTop: "2rem" }}>
            <span
              className="cookie-details"
              onClick={() => setDetailView(false)}
            >
              {"< Mostrar menos detalles "}
            </span>
          </div>
        </>
      )}

      <div className="cookie-buttons">
        <button onClick={acceptCookies} className="cookie-accept button-cookie">
          Aceptar
        </button>
        <button
          onClick={rechazoCookies}
          className="cookie-reject button-cookie"
        >
          Rechazar
        </button>
      </div>
    </div>
  );
};

export default CookieNotice;
