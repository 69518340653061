import React, { useEffect, useState } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { updateUserApi } from "../../../api/user";

import { Form, Input, Button, Row, Col, Select, notification } from "antd";

const { Option } = Select;

export default function EditProfile(props) {
  const { user, setState, state, setReload, reload } = props;
  const [userData, setUserData] = useState({});
  const [valueRadio, setValueRadio] = useState(user?.gender || null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserData({
      name: user?.name,
      lastName: user?.lastName,
      email: user?.email,
      documentType: user?.documentType,
      documentNumber: user?.documentNumber,
      date_of_birth: user?.date_of_birth,
      mobilePhone: user?.mobilePhone,
      gender: user?.gender,
      imageUrl: user?.imageUrl,
      nameBank: user?.nameBank,
      swift: user?.swift,
      aba: user?.aba,
      accountNumber: user?.accountNumber,
    });
  }, [user, state]);

  const updateUser = async (e) => {
    if (userData.accountNumber !== userData.confirmAccountNumber) {
      notification["error"]({
        message: "Los campos de numero de cuenta deben ser iguales",
      });
      return;
    }

    if (
      !userData.accountNumber &&
      !userData.confirmAccountNumber &&
      !userData.nameBank &&
      !userData.aba &&
      !userData.swift
    ) {
      notification["error"]({
        message: "Debe completar los campos obligatorios.",
      });
      return;
    }
    setLoading(true);
    const token = getAccessTokenApi();
    let userUpdate = userData;

    updateUserApi(token, userUpdate, user._id).then((result) => {
      setLoading(false);

      notification["success"]({
        message: result.message,
      });

      setState("viewAccount");
      setReload(!reload);
    });
  };

  return (
    <div>
      <EditForm
        updateUser={updateUser}
        userData={userData}
        setUserData={setUserData}
        user={user}
        setState={setState}
        setValueRadio={setValueRadio}
        valueRadio={valueRadio}
        loading={loading}
      />
    </div>
  );
}

function EditForm(props) {
  const { updateUser, userData, setUserData, setState, loading } = props;

  return (
    <>
      <div className="card-profile-2">
        <h3 className="datos-personales">Datos bancarios</h3>

        <Form className="form-add-invoice" onFinish={updateUser}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Entidad bancaria</label>
                <Select
                  showSearch
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      nameBank: e,
                    })
                  }
                  placeholder="Seleccione una Entidad bancaria"
                  value={userData.nameBank || null}
                  style={{ width: "100%" }}
                >
                  <Option value="BBVA Provincial, S.A. Banco Universal">
                    BBVA Provincial, S.A. Banco Universal
                  </Option>
                  <Option value="Banesco Banco Universal, C.A.">
                    Banesco Banco Universal, C.A.
                  </Option>
                  <Option value="Mercantil Banco, C.A. Banco Universal">
                    Mercantil Banco, C.A. Banco Universal
                  </Option>
                  <Option value="Banco Nacional De Crédito, C.A.">
                    Banco Nacional De Crédito, C.A.
                  </Option>
                  <Option value="Banco Del Caribe, C.A. Banco Universal">
                    Banco Del Caribe, C.A. Banco Universal
                  </Option>
                  <Option value="Banco Exterior, C.A. Banco Universal">
                    Banco Exterior, C.A. Banco Universal
                  </Option>
                  <Option value="Venezolano De Crédito, S.A. Banco Universal">
                    Venezolano De Crédito, S.A. Banco Universal
                  </Option>
                  <Option value="Banco Fondo Común, C.A. Banco Universal">
                    Banco Fondo Común, C.A. Banco Universal
                  </Option>
                  <Option value="Banplus Banco Universal, C.A.">
                    Banplus Banco Universal, C.A.
                  </Option>
                  <Option value="Banco Plaza, C.A., Banco Universal">
                    Banco Plaza, C.A., Banco Universal
                  </Option>
                  <Option value="Banco Sofitasa, Banco Universal, C.A.">
                    Banco Sofitasa, Banco Universal, C.A.
                  </Option>
                  <Option value="Banco Caroní, C.A., Banco Universal">
                    Banco Caroní, C.A., Banco Universal
                  </Option>
                  <Option value="Banco Activo, C.A. Banco Universal">
                    Banco Activo, C.A. Banco Universal
                  </Option>
                  <Option value="Del Sur Banco Universal, C.A.">
                    Del Sur Banco Universal, C.A.
                  </Option>
                  <Option value="100% Banco, Banco Universal, C.A.">
                    100% Banco, Banco Universal, C.A.
                  </Option>
                  <Option value="Bancamiga, Banco Universal, C.A.">
                    Bancamiga, Banco Universal, C.A.
                  </Option>
                  <Option value="Banco Internacional de Desarrollo, C.A. Banco Universal">
                    Banco Internacional de Desarrollo, C.A. Banco Universal
                  </Option>
                  <Option value="Banco de Venezuela, S.A. Banco Universal">
                    Banco de Venezuela, S.A. Banco Universal
                  </Option>
                  <Option value="Banco Bicentenario del Pueblo, de la Clase Obrera, Mujer y Comunas, Banco Universal, C.A.">
                    Banco Bicentenario del Pueblo, de la Clase Obrera, Mujer y
                    Comunas, Banco Universal, C.A.
                  </Option>
                  <Option value="Banco del Tesoro, C.A. Banco Universal">
                    Banco del Tesoro, C.A. Banco Universal
                  </Option>
                  <Option value="Banco de la Fuerza Armada Nacional Bolivariana, Banco Universal, C.A.">
                    Banco de la Fuerza Armada Nacional Bolivariana, Banco
                    Universal, C.A.
                  </Option>
                  <Option value="Banco Agrícola De Venezuela, C.A. Banco Universal">
                    Banco Agrícola De Venezuela, C.A. Banco Universal
                  </Option>
                  <Option value="Bancrecer, S.A. Banco Microfinanciero">
                    Bancrecer, S.A. Banco Microfinanciero
                  </Option>
                  <Option value="Mi Banco, Banco Microfinanciero, C.A.">
                    Mi Banco, Banco Microfinanciero, C.A.
                  </Option>
                  <Option value="Bangente, C.A.">Bangente, C.A.</Option>
                  <Option value="N58 Banco Digital Banco Microfinanciero">
                    N58 Banco Digital Banco Microfinanciero
                  </Option>
                  <Option value="Banco de Comercio Exterior, C.A. (BANCOEX)">
                    Banco de Comercio Exterior, C.A. (BANCOEX)
                  </Option>
                  <Option value="Banco Nacional de Vivienda y Hábitat (BANAVIH)">
                    Banco Nacional de Vivienda y Hábitat (BANAVIH)
                  </Option>
                  <Option value="Banco de Desarrollo Económico y Social de Venezuela (BANDES)">
                    Banco de Desarrollo Económico y Social de Venezuela (BANDES)
                  </Option>
                  <Option value="Institutos municipales de crédito">
                    Institutos municipales de crédito
                  </Option>
                  <Option value="Instituto Municipal De Crédito Popular (IMCP)">
                    Instituto Municipal De Crédito Popular (IMCP)
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Número de cuenta</label>
                <Input
                  className="register-form__input"
                  placeholder="1234565216/45"
                  value={userData.accountNumber}
                  onChange={(e) =>
                    setUserData({ ...userData, accountNumber: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">
                  Confirme número de cuenta
                </label>
                <Input
                  className="register-form__input"
                  placeholder="1234565216/45"
                  value={userData.confirmAccountNumber}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      confirmAccountNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">ABA</label>
                <Input
                  className="register-form__input"
                  placeholder="indique ABA"
                  value={userData.aba}
                  onChange={(e) =>
                    setUserData({ ...userData, aba: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">SWIFT</label>
                <Input
                  className="register-form__input"
                  placeholder="Indique SWIFT"
                  value={userData.swift}
                  onChange={(e) =>
                    setUserData({ ...userData, swift: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: "2%" }}>
            <Col xs={0} xl={12}></Col>

            <Col xs={12} xl={6}>
              <Button
                onClick={() => {
                  setState("viewAccount");
                }}
                className="btn-cancel"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={12} xl={6}>
              <Button className="btn-save" htmlType="submit" loading={loading}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
