import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Avatar,
  Layout,
  Menu,
  Divider,
  Button,
  Modal,
  notification,
} from "antd";
import {
  UserOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  EditOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import logovivi from "../../assets/logo_vivi.png";
import logovivimobile from "../../assets/logo_vivi_mobile.png";
import useAuth from "../../hooks/useAuth";
import { logout, getAccessTokenApi } from "../../api/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { API_URL, apiVersion } from "../../api/config";
import axios from "axios";
import noAvatar from "../../assets/no-avatar.png";

import "./MenuSider.css";
import { getUserApi } from "../../api/user";

import { useHistory } from "react-router-dom";

import { getSocket } from "../../services/socket";
import { useNotification } from "../../providers/CountNotifications";

function MenuSider(props) {
  const token = getAccessTokenApi();
  const {
    user: { role, id },
  } = useAuth();
  const { menuCollapsed, location, setMenuCollapsed } = props;
  const { Sider } = Layout;
  const [user, setUser] = useState(null);
  const [reloadUser, setReloadUser] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [contentModal, setContentModal] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const [title, setTitle] = useState(null);

  const history = useHistory();
  const dataEmergency = useRef();

  const socket = getSocket();

  const { notificationCount, updateNotificationCount } = useNotification();
  useEffect(() => {
    if (id) {
      setLoading(true);

      getUserApi(token, id).then((res) => {
        setLoading(false);
        setUser(res.user);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadUser]);

  useEffect(() => {
    socket.on("doctorsEmergency", (data) => {
      setTitle(data.title);
      dataEmergency.current = data;
      dataEmergency.current.doctor = data.doctor;
      dataEmergency.current.isPatient = data.isPatient;
      addModalAcceptVideocall(data.msg);
    });

    socket.on("sendToNotification", (data) => {
      if (data.showMsg) {
        notification["success"]({
          message: data.msg,
        });
      }
      updateNotificationCount(data.countNotifications);

      // let eleCountNotifications = document.getElementById("countNotifications");
      // eleCountNotifications.innerHTML = `Notificaciones (${data.countNotifications})`;

      // window.location.reload();
    });

    socket.on("redirectCallbackPayment", (data) => {
      history.push(`/appointments?OrderID=${data.orderId}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const addModalAcceptVideocall = (msg) => {
    setVisibleModal(true);
    setContentModal(
      <div style={{ padding: 5 }}>
        <div
          style={{
            width: "100%",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          <div>
            <p>{msg}</p>
            <p>¿Desea aceptarla?</p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "8%",
            marginBottom: "4%",
            textAlign: "center",
          }}
        >
          <Button
            onClick={() => setVisibleModal(false)}
            className="vivi-modal-btn btn-decline"
          >
            Rechazar
          </Button>
          <Button
            type="primary"
            className="vivi-modal-btn btn-ok"
            style={{
              width: "151px!important",
              height: "40px!important",
              marginLeft: "1rem",
            }}
            onClick={clickAcceptVideocall}
          >
            Aceptar
          </Button>
        </div>
      </div>
    );
  };

  const addModalAcceptedVideocall = () => {
    setVisibleModal(true);
    setContentModal(
      <div style={{ padding: 5 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            fontSize: "20px",
            paddingTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>La videollamada ya fue tomada por otro especialista</span>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              onClick={() => setVisibleModal(false)}
              className="volver-btn"
            >
              Volver
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const clickAcceptVideocall = async () => {
    setVisibleModal(false);

    if (dataEmergency.current.isPatient) {
      history.push({
        pathname: "/appointments",
        objeto: dataEmergency.current,
      });
    } else {
      let url = `${API_URL}${apiVersion}/appointment/${dataEmergency.current.appointment._id}`;
      const r = await axios
        .get(url, {
          headers: {
            Authorization: token,
          },
        })
        .catch((err) => console.log("error", err));
      if (r?.data) {
        if (r.data.appointment.room_id === undefined) {
          url = `${API_URL}${apiVersion}/update-appointment/${dataEmergency.current.appointment._id}`;
          const _user = JSON.parse(localStorage.getItem("USER"));

          const updateAppointment = await axios
            .put(
              url,
              {
                doctor_id: _user._id,
                doctor: _user,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .catch((err) => console.log(err));

          if (updateAppointment?.data) {
            dataEmergency.current.appointment =
              updateAppointment.data.appointment;
            dataEmergency.current.doctorSocketId = socket.id;
            dataEmergency.current.isEmergency = true;
            //socket.emit("acceptDoctor", dataEmergency.current);
            history.push({
              pathname: "/calendar",
              emergency: dataEmergency.current,
            });
          }
        } else addModalAcceptedVideocall();
      }
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("USER_ID");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("USER");

    logout();

    window.location.reload();
  };

  const isAdmin = role === "admin";
  const isDoctor = role === "doctor";
  const isPatient = role === "patient";

  if (!user) {
    return null;
  }

  return (
    <>
      <Sider
        id="menu-lateral-izquierdo"
        className="admin-sider"
        collapsed={menuCollapsed}
        breakpoint="lg"
        // collapsedWidth="0"
        onBreakpoint={(broken) => {
          setMenuCollapsed(menuCollapsed);
        }}
        onCollapse={(collapsed, type) => {
          setMenuCollapsed(!menuCollapsed);
        }}
      >
        <div className="div-logos">
          <img src={logovivi} alt="logo vivi" className="logo-vivi" />
          <img
            src={logovivimobile}
            alt="logo vivi mobile"
            className="logo-vivi-mobile"
          />
        </div>

        <div className="div-icon-user">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              size={!menuCollapsed ? 40 : 20}
              icon={<UserOutlined />}
              src={user.imageUrl ? user.imageUrl : noAvatar}
            />

            {!menuCollapsed && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "auto",
                  paddingLeft: 7,
                }}
              >
                <span className="name">{user.fullName || "Usuario"}</span>
                {isAdmin ? (
                  <span style={{ color: "#A5B4CB", fontSize: "14px" }}>
                    Administrador
                  </span>
                ) : (
                  <span
                    style={{
                      color: "#A5B4CB",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontFamily: "Roboto"
                    }}
                  >
                    {user.branchesOfMedicine.length > 0
                      ? user.branchesOfMedicine[0].title
                      : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="div-divider">
          <Divider orientation="center" className="dividerClass" />
        </div>
        {isAdmin && (
          <Menu
            className="menu-slider"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
          >
            {/* <Menu.Item className="menu-text" key="/">
              <Link to={"/"}>
                <HomeOutlined />
                <span className="nav-text"> Home </span>
              </Link>
            </Menu.Item> */}
            <Menu.Item className="menu-text" key="/users">
              <Link to={"/users"}>
                <UserOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                <span className="nav-text"> Profesionales </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/requestpage">
              <Link to={"/requestpage"}>
                <EditOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                <span className="nav-text"> Solicitudes </span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item className="menu-text" key="/turnosPendientes">
              <Link to={"/turnosPendientes"}>
                <ClockCircleOutlined />
                <span className="nav-text"> Citas Pendientes </span>
              </Link>
            </Menu.Item> */}
            <Menu.Item className="menu-text" key="/transactions">
              <Link to={"/transactions"}>
                <DollarOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                <span className="nav-text"> Transacciones </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/contactus">
              <Link to={"/contactus"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <QuestionCircleOutlined
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text">Soporte</span>
              </Link>
            </Menu.Item>
          </Menu>
        )}

        {isDoctor && (
          <Menu
            className="menu-slider"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
          >
            <Menu.Item className="menu-text" key="/">
              <Link to={"/"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text"> Mi calendario </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/perfil">
              <Link to={{ pathname: "/perfil", setReloadUser, reloadUser }}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <AccountCircleOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text"> Mi perfil </span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item className="menu-text" key="/turnos">
            <Link to={"/turnos"}>
              <CalendarOutlined />
              <span className="nav-text"> Historial de turnos </span>
            </Link>
          </Menu.Item> */}
            <Menu.Item className="menu-text" key="/reporte">
              <Link to={"/reporte"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <FeedOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text">Reporte </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/notifications">
              <Link to={"/notifications"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <BellOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                </span>
                <span className="nav-text" id="">
                  Notificaciones ({notificationCount})
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/contactus">
              <Link to={"/contactus"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <QuestionCircleOutlined
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text">Soporte</span>
              </Link>
            </Menu.Item>
          </Menu>
        )}

        {isPatient && (
          <Menu
            className="menu-slider"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
          >
            <Menu.Item className="menu-text" key="/">
              <Link to={"/"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <MedicalServicesOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text"> Especialidades </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/perfil">
              <Link to={{ pathname: "/perfil", setReloadUser, reloadUser }}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <AccountCircleOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text"> Mi perfil </span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item className="menu-text" key="/recetas">
            <Link to={"/recetas"}>
              <FileTextOutlined />
              <span className="nav-text"> Mis Recetas </span>
            </Link>
          </Menu.Item> */}
            <Menu.Item className="menu-text" key="/appointments">
              <Link to={"/appointments"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text"> Mis consultas </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/videocall">
              <Link to={"/videocall"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <AddIcCallOutlinedIcon
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text"> Llamada inmediata </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/notifications">
              <Link to={"/notifications"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <BellOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                </span>
                <span className="nav-text" id="">
                  Notificaciones ({notificationCount})
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item className="menu-text" key="/contactus">
              <Link to={"/contactus"}>
                <span
                  role="img"
                  aria-label="calendar"
                  className="anticon anticon-calendar"
                >
                  <QuestionCircleOutlined
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  />
                </span>
                <span className="nav-text">Soporte</span>
              </Link>
            </Menu.Item>
          </Menu>
        )}

        {!menuCollapsed ? (
          <div
            className="div-btn-close-sesion nav-text"
            style={{ border: "none", backgroundColor: "#fff" }}
          >
            <Button className="btn-logout" onClick={logoutUser}>
              <LogoutIcon style={{ marginRight: "0.5rem" }} />
              <span className="nav-text"> Cerrar sesión</span>
            </Button>
          </div>
        ) : (
          <div className="div-btn-logout">
            <Button className="btn-logout" onClick={logoutUser}>
              <LogoutIcon />
            </Button>
          </div>
        )}
      </Sider>
      <Modal
        children={contentModal}
        title={title}
        visible={visibleModal}
        setIsVisible={setVisibleModal}
        className={"ModalEmergency"}
        closable={false}
      />
    </>
  );
}

export default withRouter(MenuSider);
