// Layout
import LayoutAdmin from "../layouts/LayoutAdmin";

//  Pages
import Home from "../pages";
// import Specialties from "../pages/Specialties";
import AppointmentsPage from "../pages/Appointments";
import Perfil from "../pages/Perfil";
import LandingPage from "../pages/Landing";
// import ShiftHistory from "../pages/ShiftHistory";
import ReportDoctor from "../pages/ReportDoctor/";
import Users from "../pages/Users";
import PendingShifts from "../pages/PendingShifts";
// import ReserveMode from "../pages/ReserveMode";
// import DoctorAppointment from "../pages/professionalAppointment/professionalAppointment";
import ViewPerfil from "../pages/ViewPerfil/ViewPerfil";
import paymentPlatform from "../pages/PaymentPlatform";
import Videocall from "../pages/Videocall";
import WaitingRoom from "../pages/WaitingRoom";
import Calendar from "../pages/Calendar";
import ResetPassword from "../pages/ResetPassword";
import Notifications from "../pages/Notifications";
import DownloadICS from "../pages/DownloadIcs";
import ContactUs from "../pages/ContactUs";
import Requests from "../pages/Requests";
import Transactions from "../pages/Transaction/Transactions";
import Contact from "../pages/Contact";

// Other
import Error404 from "../pages/Error404";
import Success from "../pages/Success/Success";

const routes = [
  {
    path: "/",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/",
        component: Home,
        exact: true,
      },

      {
        path: "/perfil",
        component: Perfil,
        exact: true,
      },
      // {
      //   path: "/specialities/:id",
      //   component: Specialties,
      //   exact: true,
      // },
      // {
      //   path: "/reservemode/:id",
      //   component: ReserveMode,
      //   exact: true,
      // },
      // {
      //   path: "/doctorappointment/:id",
      //   component: DoctorAppointment,
      //   exact: true,
      // },
      {
        path: "/appointments",
        component: AppointmentsPage,
        exact: true,
      },
      // {
      //   path: "/turnos",
      //   component: ShiftHistory,
      //   exact: true,
      // },
      {
        path: "/reporte",
        component: ReportDoctor,
        exact: true,
      },
      {
        path: "/pago",
        component: paymentPlatform,
        exact: true,
      },
      {
        path: "/users",
        component: Users,
        exact: true,
      },
      {
        path: "/turnosPendientes",
        component: PendingShifts,
        exact: true,
      },
      {
        path: "/viewPerfil/:id",
        component: ViewPerfil,
        exact: true,
      },
      {
        path: "/landing",
        component: LandingPage,
        exact: false,
      },
      {
        path: "/videocall",
        component: Videocall,
        exact: false,
      },
      {
        path: "/waitingRoom",
        component: WaitingRoom,
        exact: false,
      },
      {
        path: "/calendar",
        component: Calendar,
        exact: false,
      },
      {
        path: "/resetpassword",
        component: ResetPassword,
        exact: false,
      },
      {
        path: "/notifications",
        component: Notifications,
        exact: false,
      },
      {
        path: "/downloadics/:appointmentid",
        component: DownloadICS,
        exact: false,
      },
      {
        path: "/contactus/",
        component: ContactUs,
        exact: false,
      },
      {
        path: "/success/",
        component: Success,
        exact: false,
      },
      {
        path: "/requestpage",
        component: Requests,
        exact: true,
      },
      {
        path: "/transactions",
        component: Transactions,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "/contact",
    component: Contact,
    exact: false,
  }
];

export default routes;
