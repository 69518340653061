import { API_URL, apiVersion } from "./config";

export function getDocumentsTypes() {
  const url = `${API_URL}${apiVersion}/documentTypes`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getDocumentTypeByName(name) {
  const url = `${API_URL}${apiVersion}/documentTypeByName?name=${name}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
