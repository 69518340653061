import { VideoCameraFilled } from "@ant-design/icons";
import { Button, Row, Col } from "antd";
import moment from "moment";
import React from "react";

import "./ViewAppoitmentDetail.css";

export default function ViewAppoitmentDetail({
  visible,
  setIsVisible,
  event,
  _connectToRoom,
  twilioHandler,
}) {
  const user = JSON.parse(localStorage.getItem("USER"));

  function calcularEdad(fecha) {
    const hoy = new Date();
    const cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    const m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return isNaN(edad) ? "" : `${edad} años`;
  }

  const isSoon = moment(event?.fecha).unix() - moment().unix() < 900;

  let dateHora = new Date();
  dateHora.setUTCHours(event.time.split(":")[0]);
  dateHora.setUTCMinutes(event.time.split(":")[1]);
  let setHora = moment(dateHora).format("HH:mm");

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
            }}
          >
            <span className="span-modal">Fecha</span>
            <span className="input-modal">
              {moment(event.fecha).format("DD/MM/YYYY")}
            </span>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="span-modal">Hora</span>
            <span className="input-modal">{setHora}</span>
          </div>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
            }}
          >
            <span className="span-modal">Paciente</span>
            <span
              className="input-modal"
              style={{ textTransform: "capitalize" }}
            >
              {event.patient?.fullName || null}
            </span>
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
            }}
          >
            <span className="span-modal">Género</span>
            <span className="input-modal">{event.patient?.gender || null}</span>
          </div>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <span className="span-modal">Edad</span>
            <span className="input-modal">
              {calcularEdad(event.patient?.date_of_birth)}
            </span>
          </div>
        </Col>
        <Col span={12}></Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <span
              className="span-modal"
              style={{
                paddingBottom: "10px",
              }}
            >
              Motivo de la consulta
            </span>
            <div
              style={{
                width: "100%",
                padding: "1rem",
                border: "1px solid #7C7C81",
                borderRadius: "10px",
              }}
            >
              <p style={{ color: "#7C7C81" }}>
                {event.reason || "No se especificó motivo de la consulta"}
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={24} style={{ paddingTop: "1rem" }}>
        <Col span={7}></Col>
        <Col span={10} style={{ textAlign: "center" }}>
          <Button
            onClick={_connectToRoom({
              doctorId: event.doctor?._id,
              patientId: event.patient?._id,
              appointmentId: event?.id,
              roomId: event?.room_id,
            })}
            disabled={
              !(
                twilioHandler.isLoading ||
                event.room_id ||
                (user?.role === "doctor" && isSoon)
              )
            }
            loading={twilioHandler.isLoading}
            className="vivi-modal-btn"
            type={
              !(
                twilioHandler.isLoading ||
                event.room_id ||
                (user?.role === "doctor" && isSoon)
              )
                ? "default"
                : "primary"
            }
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: !(
                twilioHandler.isLoading ||
                event.room_id ||
                (user?.role === "doctor" && isSoon)
              )
                ? "#d9d9d9"
                : "#006AFF",
            }}
          >
            <VideoCameraFilled />
            Ingresar
          </Button>
        </Col>
        <Col span={7}></Col>
      </Row>
    </div>
  );
}
