import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo_vivi.png";
import { LoginForm } from "../../components/auth/loginForm";
import { toast, ToastContainer } from "react-toastify";
import { ACCESS_TOKEN, REFRESH_TOKEN, USERNAME } from "../../utils/constants";
import { signInApi } from "../../api/user";
import RegisterForm from "../../components/registerform";
import { Row, Col, Button, Card } from "antd";
import imageHeaderOne from "../../assets/Group 37611.png";
import imgCardOne from "../../assets/PacienteLanding.png";
import imgCardTwo from "../../assets/DoctoraLanding.png";
import imgCalendar from "../../assets/imagecalendar.png";
import buscaDoctor from "../../assets/buscaDoctor.png";
import "./LandingPage.css";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import pagoMovil from "../../assets/pagomovil3.png";
import pagoVisa from "../../assets/pagoVisa.png";
import pagoMaster from "../../assets/pagoMastercar.png";
import pagoMaestro from "../../assets/pagoMaestro2.png";
import { Link, useHistory } from "react-router-dom";
import { validateEmail } from "../../utils/utils";
import { forgotPasswordAPI } from "../../api/auth";
import Contact from "../Contact/Contact";
import CookieNotice from "../../components/CookiesNotice/CookiesNotice";
const cookiesAccepted = localStorage.getItem("cookiesAccepted");
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}));

export default function LandingPage() {
  const [state, setState] = useState("landingPage");
  const [isloading, setIsloading] = useState(false);
  const [stateUser, setStateUser] = useState("isPatient");
  const [statusHeader, setStatusHeader] = useState("header1");
  const [keyNumber, setKeyNumber] = useState("1");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const userID = localStorage.getItem("USER_ID");

    if (userID !== null && userID !== undefined) {
      history.push("/");
    }
  });

  const clickContactUs = () => {
    const contactUsSection = document.getElementById("contactus");

    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  async function login(email, password) {
    setIsloading(true);
    const inputs = {
      email,
      password,
    };
    const result = await signInApi(inputs);

    if (result.message) {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsloading(false);
    } else {
      const { accessToken, refreshToken, user } = result;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      localStorage.setItem(USERNAME, user.fullName);
      localStorage.setItem("USER_ID", user._id);
      localStorage.setItem("USER", JSON.stringify(user));

      toast.success("Inicio de sesion exitoso", {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  }

  async function forgotPassword(email) {
    if (email === "") {
      toast.error("Debe ingresar el email.", {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (!validateEmail(email)) {
        toast.error("El email no es correcto.", {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const forgotPass = await forgotPasswordAPI(email);
        if (forgotPass) {
          toast.success("Envío de email exitoso", {
            position: "top-right",
            autoClose: 2800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 800px)");
    setIsSmallScreen(mediaQuery.matches);
  }, [isSmallScreen]);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="landing-page">
        {statusHeader === "header1" && (
          <Row
            gutter={24}
            className="row-without-margin"
            style={{ height: "16vh" }}
          >
            <Col xs={0} xl={3}></Col>
            <Col
              xs={10}
              xl={4}
              className="row-without-margin"
              style={{ alignSelf: "center" }}
            >
              <div
                className="leftLogo"
                onClick={() => {
                  setStatusHeader("header1");
                  setState("landingPage");
                }}
              >
                <img src={Logo} className="logo-landing" alt="logo vivi" />
              </div>
            </Col>
            <Col
              xs={14}
              xl={16}
              className="row-without-margin"
              style={{ alignSelf: "center", textAlignLast: "end" }}
            >
              {!isSmallScreen ? (
                <div className="containerNav">
                  <div
                    className="button-in"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setStateUser("isMedical");
                      setState("registration");
                      setStatusHeader("header2");
                      setKeyNumber("2");
                    }}
                  >
                    <span className="span-header header-link">Soy médico</span>
                  </div>

                  <div
                    className="button-in"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setStateUser("isPatient");
                      setState("registration");
                      setStatusHeader("header2");
                      setKeyNumber("1");
                    }}
                  >
                    <span className="span-header header-link">
                      Soy paciente
                    </span>
                  </div>

                  <div
                    className="button-in"
                    style={{ cursor: "pointer" }}
                    onClick={() => clickContactUs()}
                  >
                    <span className="span-header header-link">Contáctenos</span>
                  </div>
                </div>
              ) : (
                <div>
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleClick}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setStateUser("isMedical");
                        setState("registration");
                        setStatusHeader("header2");
                        setKeyNumber("2");
                      }}
                    >
                      <div style={{ cursor: "pointer" }}>
                        <span style={{ fontSize: "15px" }}>Soy médico</span>
                      </div>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setStateUser("isPatient");
                        setState("registration");
                        setStatusHeader("header2");
                        setKeyNumber("1");
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Soy paciente
                        </span>
                      </div>
                    </MenuItem>
                    {/* <MenuItem>
                    <div>
                      <Link
                        activeClass="active"
                        // className="test1"
                        to="opiniones"
                        spy={true}
                        smooth={true}
                        duration={500}
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        Opiniones de usuarios
                      </Link>
                    </div>
                  </MenuItem> */}
                    <MenuItem>
                      <div>
                        <Link
                          activeClass="active"
                          spy={true}
                          smooth={true}
                          duration={500}
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          Contáctenos
                        </Link>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </Col>
            <Col xs={0} xl={3}></Col>
          </Row>
        )}

        {statusHeader === "header2" && (
          <div>
            <div
              className="div-logo-landing"
              onClick={() => {
                window.location.reload();
              }}
            >
              <img src={Logo} className="logo-landing" alt="logo vivi" />
            </div>
          </div>
        )}

        <div
          className="landing-content"
          style={{
            display: state !== "landingPage" ? "flex" : "",
            alignItems: state === "login" ? "" : "center",
          }}
        >
          {state === "login" && (
            <LoginForm
              login={login}
              isloading={isloading}
              state={state}
              setState={setState}
              setStateUser={setStateUser}
              stateUser={stateUser}
              keyNumber={keyNumber}
              setKeyNumber={setKeyNumber}
              forgotPassword={forgotPassword}
            />
          )}

          {state === "registration" && (
            <div className="landing-hijo">
              <RegisterForm
                state={state}
                setState={setState}
                setStateUser={setStateUser}
                stateUser={stateUser}
                keyNumber={keyNumber}
                setKeyNumber={setKeyNumber}
              />
            </div>
          )}

          {state === "landingPage" && (
            <div>
              <section id="header">
                <Row gutter={24} className="row-without-margin">
                  <Col xs={0} xl={3}></Col>
                  <Col xs={24} xl={9}>
                    <div className="containerOne">
                      <span className="span-title">
                        El profesional de la salud que necesitas,
                      </span>
                      <span className="span-title">estés donde estés</span>
                      <span
                        className="span-size subtitleLanding"
                        style={{
                          paddingTop: "1rem",
                        }}
                      >
                        Somos el servicio de videoconsultas médica que te ofrece
                        atención personalizada de manera fácil, rápida y segura
                        desde cualquier lugar. Con solo unos pocos clics, puedes
                        conectarte con un profesional de la salud de cualquier
                        especialidad.
                      </span>
                      <div style={{ paddingTop: "2rem" }}>
                        <Button
                          className="button-in"
                          style={{
                            borderRadius: "100px",
                            backgroundColor: "#006AFF",
                            fontSize: "initial",
                            width: "138px",
                            height: "41px",
                            color: "white",
                          }}
                          onClick={() => {
                            setStateUser("isPatient");
                            setState("registration");
                            setStatusHeader("header2");
                            setKeyNumber("1");
                          }}
                        >
                          Registrarme
                        </Button>

                        <div
                          className="button-in btnIngresar"
                          type="dashed"
                          style={{
                            width: "150px",
                            height: "40px",
                            borderRadius: "20px",
                            borderStyle: "double",
                            backgroundColor: "transparent",
                            marginLeft: "1rem",
                            color: "black",
                            fontSize: "initial",
                            borderWidth: "0px",
                            border: "none",
                          }}
                          onClick={() => {
                            setState("login");
                            setStatusHeader("header2");
                          }}
                        >
                          Ingresar
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} xl={9}>
                    <img
                      src={imageHeaderOne}
                      alt="img header"
                      style={{ maxWidth: "100%" }}
                    ></img>
                  </Col>
                  <Col xs={0} xl={3}></Col>
                </Row>

                <Row gutter={24} className="row-without-margin row-howuse">
                  <Col
                    span={24}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <span
                      className="span-title"
                      style={{ textAlign: "center" }}
                    >
                      Cómo usar Vivi
                    </span>
                  </Col>
                  <Col
                    span={24}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        width: "7vh",
                        height: "2px",
                        marginTop: "1rem",
                        backgroundColor: "black",
                      }}
                    ></div>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    <Col
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      md={{ span: 2 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                    ></Col>
                    <Col
                      xs={{ span: 20 }}
                      sm={{ span: 20 }}
                      md={{ span: 20 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                      style={{ textAlign: "center" }}
                    >
                      <span
                        className="span-size"
                        style={{ textAlign: "center" }}
                      >
                        Vivi es la plataforma que conecta a profesionales de la
                        salud con pacientes de manera virtual. Puedes
                        registrarte como <strong>paciente</strong> o como{" "}
                        <strong>profesional</strong> desde tu computadora,
                        smartphone o tablet.
                      </span>
                    </Col>
                    <Col
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      md={{ span: 2 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                    ></Col>
                  </Col>
                </Row>
              </section>
              <section id="cards">
                <Row
                  gutter={24}
                  className="row-without-margin"
                  style={{ backgroundColor: "#fff" }}
                >
                  <Col xs={0} xl={3}></Col>
                  <Col xl={9} xs={24}>
                    <Card className="card-landing">
                      <div
                        style={{ display: "flex", height: "35vh" }}
                        className="containerCardLanding"
                      >
                        <div style={{ flex: 1, alignSelf: "center" }}>
                          <img
                            alt="imgcardone"
                            src={imgCardOne}
                            style={{ width: "100%" }}
                            className="card-landing-img"
                          />
                        </div>
                        <div
                          style={{
                            flex: 2,
                            alignSelf: "center",
                            padding: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <span className="card-title">Pacientes</span>
                          </div>
                          <div style={{ paddingTop: "2rem" }}>
                            <p className="span-size">
                              Encuentra al médico que necesitas en el momento o
                              agenda tu próxima cita para el día y horario que
                              más te convenga.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={9} xs={24} className="card-landing-col">
                    <Card className="card-landing">
                      <div
                        style={{ display: "flex", height: "35vh" }}
                        className="containerCardLanding"
                      >
                        <div style={{ flex: 1, alignSelf: "center" }}>
                          <img
                            alt="imgcardtwo"
                            src={imgCardTwo}
                            style={{ width: "100%" }}
                            className="card-landing-img"
                          />
                        </div>
                        <div
                          style={{
                            flex: 2,
                            alignSelf: "center",
                            padding: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <span className="card-title">
                              Profesionales de la salud
                            </span>
                          </div>
                          <div style={{ paddingTop: "2rem" }}>
                            <p className="span-size">
                              Configura tu disponibilidad de horarios de
                              atención para que cientos de pacientes puedan
                              atenderse contigo.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={0} xl={3}></Col>
                </Row>
              </section>
              <section
                id="atiendePacientes"
                style={{ backgroundColor: "#fff" }}
              >
                <Row
                  gutter={24}
                  className="row-without-margin"
                  style={{ paddingTop: "4rem" }}
                >
                  <Col xs={0} xl={3}></Col>
                  <Col xs={24} xl={9}>
                    <img
                      src={imgCalendar}
                      alt="img-atiendePacientes"
                      style={{ maxWidth: "100%" }}
                    ></img>
                  </Col>

                  <Col
                    xs={24}
                    xl={9}
                    style={{ alignSelf: "center", padding: "1rem" }}
                  >
                    <div className="containerCardsCentrales">
                      <span className="titleTwo">
                        <span style={{ color: "#006AFF" }}>
                          Atiende pacientes
                        </span>{" "}
                      </span>
                      <span className="titleTwo" style={{ width: "100%" }}>
                        en el lugar y horario que prefieras
                      </span>

                      <div style={{ paddingTop: "2rem" }}>
                        <span className="span-size" style={{ fontWeight: 400 }}>
                          Planifica y personaliza tu disponibilidad en un par de
                          clics para que los pacientes puedan agendar una
                          consulta contigo.
                        </span>
                      </div>

                      <div style={{ paddingTop: "3rem" }}>
                        <Button
                          className="button-in"
                          style={{
                            backgroundColor: "transparent",
                            color: "#458FF6",
                            borderColor: "#458FF6",
                            borderRadius: "20px",
                            width: "150px",
                            height: "40px",
                            fontSize: "initial",
                          }}
                          onClick={() => {
                            setStateUser("isMedical");
                            setState("registration");
                            setStatusHeader("header2");
                            setKeyNumber("2");
                          }}
                        >
                          {" "}
                          Quiero probar{" "}
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={0} xl={3}></Col>
                </Row>

                <Row
                  gutter={24}
                  className="row-without-margin row-search-speciality"
                >
                  <Col xs={0} xl={3}></Col>
                  <Col
                    xs={24}
                    xl={9}
                    style={{ alignSelf: "center", padding: "1rem" }}
                  >
                    <div className="containerCardsCentrales">
                      <span className="titleTwo">
                        Busca una especialidad, elige a un{" "}
                        <span style={{ color: "#006AFF" }}>
                          médico disponible
                        </span>{" "}
                        ¡y listo!
                      </span>

                      <div style={{ paddingTop: "2rem" }}>
                        <span className="span-size" style={{ fontWeight: 400 }}>
                          Puedes agendar las consultas que quieras, de la
                          especialidad que necesites. También puedes consultar
                          un profesional de la salud ¡en el momento!
                        </span>
                      </div>
                      <div style={{ paddingTop: "3rem" }}>
                        <Button
                          className="button-in"
                          style={{
                            backgroundColor: "transparent",
                            color: "#458FF6",
                            borderColor: "#458FF6",
                            borderRadius: "20px",
                            width: "150px",
                            height: "40px",
                            fontSize: "initial",
                          }}
                          onClick={() => {
                            setStateUser("isPatient");
                            setState("registration");
                            setStatusHeader("header2");
                            setKeyNumber("1");
                          }}
                        >
                          {" "}
                          Pedir consulta{" "}
                        </Button>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} xl={9}>
                    <img
                      src={buscaDoctor}
                      alt="img-atiendePacientes"
                      style={{ maxWidth: "100%" }}
                    ></img>
                  </Col>
                  <Col xs={0} xl={3}></Col>
                </Row>
              </section>
              <section id="formasDePago">
                <Row
                  gutter={24}
                  className="row-without-margin containerSectionPagos"
                  // style={{ height: "50vh" }}
                >
                  <Col xs={0} xl={3} md={2}></Col>
                  <Col xs={24} xl={9} md={10} style={{ alignSelf: "center" }}>
                    <div>
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "50px",
                          paddingBottom: "20px",
                          paddingTop: "10px",
                          lineHeight: "56px",
                        }}
                        className="TitleSectionPago"
                      >
                        Paga fácil y seguro
                      </span>
                      <div style={{ paddingTop: "2rem" }}>
                        <span
                          className="span-size"
                          style={{ paddingTop: "20px", textAlign: "center" }}
                        >
                          Puedes pagar tu consulta usando cualquiera de estos
                          métodos de pago. También lo puede hacer cualquier
                          persona desde interior o exterior del país.
                        </span>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={24}
                    xl={9}
                    md={10}
                    style={{
                      alignSelf: "center",
                      paddingTop: "2rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    <Row
                      gutter={24}
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        xs={12}
                        md={12}
                        xl={5}
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <img
                          src={pagoMovil}
                          alt="imgFormaPago"
                          className="imagenPagoMovil"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={12}
                        xl={6}
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <img
                          src={pagoVisa}
                          alt="imgFormaPago"
                          className="imagenPagoMovil"
                          //
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={12}
                        xl={6}
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        className="col-img-pago"
                      >
                        <img
                          src={pagoMaster}
                          alt="imgFormaPago"
                          className="imagenPagoMovil"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={12}
                        xl={6}
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        className="col-img-pago"
                      >
                        <img
                          src={pagoMaestro}
                          alt="imgFormaPago"
                          className="imagenPagoMovil"
                          // style={{height: "80%" }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={0} xl={3} md={2}></Col>
                </Row>
              </section>

              <section id="contactus">
                <Row
                  gutter={24}
                  className="row-without-margin"
                  style={{ backgroundColor: "#fff" }}
                >
                  <Col xs={0} xl={7}></Col>
                  <Col xs={24} xl={9} className="col-contact">
                    <Contact></Contact>
                  </Col>
                  <Col xs={0} xl={7}></Col>
                </Row>
              </section>

              <section id="footer">
                <Row
                  gutter={24}
                  className="row-without-margin"
                  style={{
                    height: "8vh",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Col span={24}>
                    <div>
                      <span style={{ color: "#C5C8D3" }} className="footerText">
                        Grupo Vivi © 2024 - Todos los derechos reservados
                      </span>
                    </div>
                  </Col>
                </Row>
              </section>
            </div>
          )}
        </div>
      </div>

      <CookieNotice cookiesAccepted={cookiesAccepted} />

      <ToastContainer />
    </>
  );
}
