import io from 'socket.io-client';
import { basePath } from "../api/config";

let socket;

export function connectSocket() {
    socket = io.connect(`${basePath}`);
}

export function getSocket() {
    return socket;
}

export function updateUserSocket(userId) {
    socket.emit("updateListConnection", {
        socketId: socket.id,
        userId: userId,
    });
}