import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar as ReactCalendar } from "react-calendar";
import axios from "axios";
import "./CalendarReservationPatient.css";
import "moment/locale/es.js";
import { API_URL, apiVersion } from "../../api/config";
import { getAccessTokenApi } from "../../api/auth";
import { Button, List, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./CalendarReservationPatient.css";
export default function CalendarReservationPatient({
  doctor,
  setVisibleModal,
  previewConfirmAppoiment,
  timeSelected,
  dateSelected,
  reasonWrite,
}) {
  const token = getAccessTokenApi();
  const [date, setDate] = useState({
    date: moment(),
  });
  const [time, setTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState([]);
  const [reason, setReason] = useState(reasonWrite || "");
  const [loadingHours, setLoadingHours] = useState(false);

  useEffect(() => {
    if (timeSelected !== undefined) {
      setTime(timeSelected);
    }
    if (reasonWrite !== undefined) {
      setReason(reasonWrite);
    }
    if (dateSelected?.date !== undefined) {
      setDate({ ...date, date: dateSelected?.date });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelected, dateSelected, reasonWrite]);

  const validateHour = (dateIn) => {
    const now = new Date();
    const currentHour = now.getHours().toString().padStart(2, "0");
    const currentMinute = now.getMinutes().toString().padStart(2, "0");

    const [datePart, timePart] = dateIn.split("T");
    const [year, month, day] = datePart.split("-");
    const [hour, minute] = timePart.split(":");

    const dateInTime = new Date(year, month - 1, day, hour, minute);

    return (
      dateInTime >=
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        currentHour,
        currentMinute
      )
    );
  };

  const getHoursAvailability = async () => {
    setTime(null);
    setLoading(true);
    if (doctor) {
      setLoadingHours(true);
      let url = `${API_URL}${apiVersion}/getBussinesHoursByDoctor?id=${doctor._id}`;

      const r = await axios
        .get(url, {
          headers: {
            Authorization: token,
          },
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });

      if (r?.data) {
        const _date = moment(date.date).format("YYYY-MM-DD");
        const hoursActives = r.data.businessHours?.filter(
          (hour) => hour.date === _date
        );

        console.log("horas activas", hoursActives);
        url = `${API_URL}${apiVersion}/appointments-for-doctor?doctor_id=${doctor._id}&date=${_date}`;

        const resultAppointments = await axios
          .get(url, {
            headers: {
              Authorization: token,
            },
          })
          .catch((err) => {
            setLoadingHours(false);
            setLoading(false);
            console.log(err);
          });

        if (resultAppointments?.data) {
          console.log(
            "resultAppointments.data.appointments",
            resultAppointments.data.appointments
          );
          let times = [];
          hoursActives.map((time) => {
            let dateHora = new Date();
            console.log("get times");
            dateHora.setHours(time.time.split(":")[0]);
            dateHora.setMinutes(time.time.split(":")[1]);
            let setHora = moment(dateHora).format("HH:mm");
            const filterTime = Array.from(
              resultAppointments.data.appointments
            ).filter(
              (x) =>
                new Date(x.dateCompletedStart)
                  .toLocaleTimeString()
                  .slice(0, 5)
                  .toString() === setHora
            );

            console.log("filterTime", filterTime.length);
            console.log("validateHour", validateHour(`${_date}T${setHora}`));
            if (filterTime.length === 0 && validateHour(`${_date}T${setHora}`))
              times.push(time.time);

            return times;
          });
          console.log("times", times);

          times.sort((a, b) => {
            const horaA = moment(a, "HH:mm");
            const horaB = moment(b, "HH:mm");
            return horaA.diff(horaB);
          });

          const unicos = times.filter((item, index) => {
            return times.indexOf(item) === index;
          });
          setLoadingHours(false);

          setHours(unicos);
          setLoading(false);
          if (timeSelected) {
            setTime(timeSelected);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (doctor) {
      getHoursAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor, date]);

  return (
    <>
      <div className="div-calendar-modal">
        <div className="div-calendar-sel-reservation">
          <label
            style={{
              color: "black",
              paddingBottom: 5,
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            Seleccione la fecha
          </label>
          <ReactCalendar
            className="CalendarSelectReservation"
            next2Label=""
            prev2Label=""
            value={new Date(date?.date || new Date())}
            minDate={new Date()}
            onChange={(e) =>
              setDate({
                date: moment(e),
              })
            }
          />
        </div>

        <div style={{ width: "4%" }}></div>

        <div
          style={{
            width: "100%",
            // width: "48%"
            // textAlign: "center",
            // paddingTop: "10px",
            // display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <label
            style={{
              marginBottom: "1rem",
              fontWeight: "400",
              fontSize: "16px",
              color: "black",
            }}
          >
            Elegir horario disponible
          </label>
          <div style={{ maxHeight: "260px", overflowY: "auto" }}>
            {loadingHours ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  paddingTop: "5rem",
                }}
              >
                <Spin />
              </div>
            ) : (
              <>
                {hours.length > 0 ? (
                  <List
                    grid={{
                      gutter: 16,
                      xs: 2,
                      sm: 2,
                      md: 3,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    dataSource={hours}
                    loading={loading}
                    renderItem={(item) => (
                      <List.Item
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Button
                          onClick={() => {
                            // selectDate(item);
                            setTime(item);
                          }}
                          style={{
                            backgroundColor: time === item ? "#fff" : "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAling: "center",
                            borderRadius: "5px",
                            color: time === item ? "#006AFF" : "",
                            fontWeight: "500",
                            borderWidth: 2,
                            borderColor: time === item ? "#006AFF" : "",
                            width: "90%",
                          }}
                        >
                          {item}
                        </Button>
                      </List.Item>
                    )}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "30%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      No hay horarios disponibles
                    </span>{" "}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2%",
        }}
      >
        <label
          style={{
            marginBottom: "1rem",
            fontWeight: "400",
            fontSize: "16px",
            color: "black",
          }}
        >
          Motivo de consulta
        </label>
        <TextArea
          row={3}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        ></TextArea>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "1.5rem",
          paddingBottom: "2rem",
        }}
      >
        <Button
          className="vivi-modal-btn btn-back"
          onClick={() => {
            setDate({ ...date, date: moment() });
            setVisibleModal(false);
          }}
        >
          Volver
        </Button>
        <Button
          // type="primary"
          // style={{
          //   backgroundColor: time === null ? "#B5B2B2" : "#006AFF",
          // }}
          disabled={time ? false : true}
          onClick={() => {
            setVisibleModal(false);
            previewConfirmAppoiment(doctor, date, time, reason);
          }}
          className={
            time ? "vivi-modal-btn btn-ok" : "vivi-modal-btn btn-ok-disabled"
          }
        >
          Siguiente
        </Button>
      </div>
    </>
  );
}
