import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./WaitingRoom.css";
import { useHistory } from "react-router-dom";
import { getSocket } from "../../services/socket";
import { Row, Col, Spin } from "antd";

const WaitingRoom = () => {
  const location = useLocation();
  const objeto = location?.objeto;
  const navigate = useHistory();
  const socket = getSocket();

  useEffect(() => {
    if (!objeto.isSchedule) {
      objeto.patientSocketId = socket.id;
      socket.emit("emergencyVideocall", objeto);
    }

    socket.on("sendToPatientVideocall", (data) => {
      data.acceptDoctor = true;
      navigate.push({
        pathname: "/appointments",
        objeto: data,
      });
    });
  }, []);

  return (
    <>
      <Row
        gutter={24}
        style={{ height: "100%", marginLeft: "0px", marginRight: "0px" }}
      >
        <Col xs={0} xl={8} className="left-section"></Col>
        <Col xs={24} xl={16}>
          <Row className="row-header-waitingroom">
            <Col>
              <span
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "#006AFF",
                  display: "table",
                  margin: "0 auto",
                }}
              >
                Te damos la bienvenida
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span style={{ fontSize: "xx-large", color: "#4177C6" }}>
                {objeto?.fullName}
              </span>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center", height: "75%" }}>
            <Col>
              <div
                style={{
                  fontSize: "25px",
                  textAlign: "center",
                  marginTop: "10%",
                }}
              >
                <span>Por favor aguarda en línea,</span>
                <br></br>
                <span> en breve atenderemos tu consulta.</span>
              </div>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Spin />
          </div>
          <div
            style={{
              position: "fixed",
              bottom: "25%",
            }}
            className="containerTextSalaDeEspera"
          >
            <Row gutter={24} style={{ display: "inline" }}>
              <Col xs={24} xl={24}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#006AFF",
                    color: "white",
                    paddingInline: "15%",
                  }}
                >
                  <span style={{ fontSize: "3vh" }}>Mientras esperas</span>
                </div>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }}>
              <Col xs={24} xl={24}>
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    marginTop: "59px",
                  }}
                >
                  Es importante que no cierres esta ventana hasta que tu
                  consulta haya finalizado. En caso de cerrarla, deberás volver
                  a ingresar desde la sección “Mis consultas”.
                </span>
                <br></br>
                <span>
                  No olvides habilitar los permisos para que tu navegador pueda
                  utilizar el micrófono y la cámara de tu computadora o el
                  dispositivo que estés utilizando para realizar esta
                  videollamada.
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WaitingRoom;
