import React, { useEffect, useState } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { updateUserApi } from "../../../api/user";

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  notification,
  Radio,
} from "antd";
import moment from "moment";
import { createUploadFile } from "../../../api/uploadFiles.api";
import TextArea from "antd/lib/input/TextArea";
import "./ViewProfile.css";

const { Option } = Select;

export default function EditProfile(props) {
  const {
    user,

    setState,
    imageAvatar,

    state,
    setReload,
    reload,
  } = props;
  const [userData, setUserData] = useState({});
  const [valueRadio, setValueRadio] = useState(user?.gender || null);
  const [loading, setLoading] = useState(false);

  const token = getAccessTokenApi();

  useEffect(() => {
    setUserData({
      name: user?.name,
      lastName: user?.lastName,
      email: user?.email,
      documentType: user?.documentType,
      documentNumber: user?.documentNumber,
      date_of_birth: user?.date_of_birth,
      mobilePhone: user?.mobilePhone,
      gender: user?.gender,
      imageUrl: user?.imageUrl,
      bio: user?.bio,
      role: user?.role,
      pendingChange: user?.pendingChange,
      changes: user?.changes,
    });
  }, [user, state]);

  const updateUser = async () => {
    setLoading(true);
    let userUpdate = userData;
    let jsonChanges = null;

    if (imageAvatar?.rawFile) {
      const result = await createUploadFile(imageAvatar.rawFile);

      if (result.state === "ok") {
        const urlData = result.url;
        const resultURL = urlData.split("?");
        userUpdate.imageUrl = resultURL[0];
      }
    }

    if (user.role === "doctor") {
      if (userData.pendingChange) {
        jsonChanges = JSON.parse(userData.changes);
      }

      const fieldsToCheck = [
        { key: "name", title: "Nombre" },
        { key: "lastName", title: "Apellido" },
        { key: "email", title: "Correo electrónico" },
        { key: "documentType", title: "Tipo de documento" },
        { key: "documentNumber", title: "Número de documento" },
        { key: "date_of_birth", title: "Fecha de cumpleaños" },
        { key: "mobilePhone", title: "Teléfono móvil" },
        { key: "gender", title: "Género" },
        { key: "bio", title: "Biografía" },
      ];

      const changes = fieldsToCheck.reduce((acc, field) => {
        const key = field.key;
        const title = field.title;
        const jsonChange =
          jsonChanges === null
            ? undefined
            : jsonChanges.find(
                (f) => f.field === key && f.value !== userUpdate[key]
              );

        if (userUpdate[key] !== user?.[key]) {
          acc.push({ field: key, title: title, value: user[key] });
        }

        if (jsonChange !== undefined)
          acc.push({ field: key, title: title, value: jsonChange.value });

        return acc;
      }, []);

      // if (userUpdate.name !== user?.name) changes.push({ field: "name", title: "Nombre", value: user.name });
      // if (userUpdate.lastName !== user?.lastName) changes.push({ field: "lastName", title: "Apellido", value: user.lastName });
      // if (userUpdate.email !== user?.email) changes.push({ field: "email", title: "Correo Electrónico", value: user.email });
      // if (userUpdate.documentType !== user?.documentType) changes.push({ field: "documentType", title: "Tipo de documento", value: user.documentType });
      // if (userUpdate.documentNumber !== user?.documentNumber) changes.push({ field: "documentNumber", title: "Número de documento", value: user.documentNumber });
      // if (userUpdate.date_of_birth !== user?.date_of_birth) changes.push({ field: "date_of_birth", title: "Fecha de cumpleaños", value: user.date_of_birth });
      // if (userUpdate.mobilePhone !== user?.mobilePhone) changes.push({ field: "mobilePhone", title: "Teléfono móvil", value: user.mobilePhone });
      // if (userUpdate.gender !== user?.gender) changes.push({ field: "gender", title: "Género", value: user.gender });
      // if (userUpdate.bio !== user?.bio) changes.push({ field: "bio", title: "Biografía", value: user.bio });
      userUpdate.pendingChange = changes.length > 0;
      userUpdate.changes = JSON.stringify(changes);
    }

    updateUserApi(token, userUpdate, user._id).then((result) => {
      setLoading(false);

      notification["success"]({
        message: result.message,
      });

      setState("view");
      setReload(!reload);
      if (imageAvatar?.rawFile) {
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <EditForm
        updateUser={updateUser}
        userData={userData}
        setUserData={setUserData}
        user={user}
        setState={setState}
        setValueRadio={setValueRadio}
        valueRadio={valueRadio}
        loading={loading}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    updateUser,
    userData,
    setUserData,
    setState,
    setValueRadio,
    valueRadio,
    loading,
  } = props;

  const onChangeRadioButton = (e) => {
    setValueRadio(e.target.value);
    setUserData({ ...userData, gender: e.target.value });
  };

  return (
    <>
      <div className="card-profile-2">
        <h3 className="datos-personales textRoboto">Datos personales</h3>

        <Form className="form-add-invoice" onFinish={updateUser}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Nombre/s</label>
                <Input
                  className="register-form__input"
                  placeholder="Ingrese su nombre"
                  value={userData.name}
                  onChange={(e) =>
                    setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Apellido</label>
                <Input
                  className="register-form__input"
                  placeholder="Ingrese su apellido"
                  value={userData.lastName}
                  onChange={(e) =>
                    setUserData({ ...userData, lastName: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <label className="label-register">Correo electrónico</label>
                <Input
                  className="register-form__input"
                  placeholder="example@dominio.com"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Tipo de documento</label>

                <Select
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      documentType: e,
                    })
                  }
                  value={userData.documentType}
                  style={{ width: "100%", paddingTop: "6px" }}
                >
                  <Option value="C.I">C.I</Option>
                  <Option value="Pasaporte">Pasaporte</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Número de documento</label>
                <Input
                  className="register-form__input"
                  placeholder="p ej: 0123456789"
                  value={userData.documentNumber}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      documentNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Fecha de nacimiento</label>
                <DatePicker
                  value={
                    userData?.date_of_birth !== undefined
                      ? moment(userData?.date_of_birth)
                      : null
                  }
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      date_of_birth: e == null ? new Date() : e,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <label className="label-register">Teléfono</label>
                <Input
                  className="register-form__input"
                  placeholder="Ingrese su teléfono"
                  value={userData.mobilePhone}
                  onChange={(e) => {
                    let inputPhone = e.target.value;
                    inputPhone = inputPhone.replace(/[^\d+()\- ]/g, "");
                    inputPhone = inputPhone.substring(0, 20);
                    setUserData({ ...userData, mobilePhone: inputPhone });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <label className="label-register">Género</label>
          <Row gutter={24}>
            <Col span={8}>
              <Radio.Group
                onChange={onChangeRadioButton}
                value={valueRadio}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "2rem",
                  fontFamily: "Roboto",
                }}
              >
                <Radio value={"Femenino"}>Femenino</Radio>
                <Radio value={"Masculino"}>Masculino</Radio>
              </Radio.Group>
            </Col>
          </Row>

          {userData?.role === "doctor" && (
            <Row style={{ paddingBottom: 10 }}>
              <Col span={24}>
                <label className="label-register">Descripción</label>
                <TextArea
                  rows={3}
                  placeholder="Ingrese una descripción personal"
                  onChange={(e) =>
                    setUserData({ ...userData, bio: e.target.value })
                  }
                  value={userData.bio}
                />
              </Col>
            </Row>
          )}

          <Row gutter={24}>
            <Col xs={0} xl={12}></Col>
            <Col
              xs={24}
              xl={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="containerButtonsEditProfile"
            >
              <Button
                onClick={() => {
                  setState("view");
                }}
                className="btn-cancel"
              >
                Cancelar
              </Button>
              <Button
                className="btn-save"
                htmlType="submit"
                loading={loading}
                style={{ marginLeft: "1rem" }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
