import React, { useState, useEffect } from "react";
import { Button, notification, Dropdown, Menu, Spin, Row, Col } from "antd";

import Modal from "../../components/Modal";
// import EditUserForm from "../EditUserForm";
import DownloadIcon from "@mui/icons-material/Download";
import { activateUserApi } from "../../api/user";
import { getAccessTokenApi } from "../../api/auth";
import { DownOutlined } from "@ant-design/icons";
import "./Users.css";
import { getUsersActiveApi, getUsersRejectedApi } from "../../api/user";
import DownloadDocument from "../../components/DownloadDocument/DownloadDocument";
import DataTable from "../../components/DataTable";

export default function ListUsers(props) {
  const [viewUsersActives, setViewUsersActives] = useState("Todos");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [usersActive, setUsersAcive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [usersRejected, setUsersRejected] = useState([]);
  const [usersAll, setUsersAll] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = getAccessTokenApi();

  const columnsAllUsers = [
    {
      header: "Nombre",
      accessorKey: "fullName",
    },
    {
      header: "Especialidad",
      accessorKey: "branchesOfMedicine",
    },
    {
      header: "Matrícula",
      accessorKey: "tuition",
    },
    {
      header: "CI",
      accessorKey: "documentNumber",
    },
    {
      header: "Estado",
      accessorKey: "status",
    },
    // {
    //   header: "Documentos",
    //   accessorKey: "_id",
    //   cell: (info) =>
    //     getButtonDownload({
    //       openModalDescarga,
    //       user: { _id: info.getValue() },
    //     }),
    // },
    {
      header: "Acciones",
      accessorKey: "_id",
      cell: (info) =>
        validateButtons({
          desactivateNullUser,
          activateUser,
          desactivateUser,
          user: { _id: info.getValue() },
          rowData: info.row.original,
        }),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setViewUsersActives("Todos");
        }}
      >
        Todos
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setViewUsersActives("Aprobados");
        }}
      >
        Aprobados
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setViewUsersActives("Pendientes");
        }}
      >
        Pendientes
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setViewUsersActives("Rechazados");
        }}
      >
        Rechazados
      </Menu.Item>
    </Menu>
  );

  const desactivateNullUser = (user) => {
    const accesToken = getAccessTokenApi();

    activateUserApi(accesToken, user._id, null)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const desactivateUser = (user) => {
    const accesToken = getAccessTokenApi();

    activateUserApi(accesToken, user._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const activateUser = (user) => {
    const accesToken = getAccessTokenApi();

    activateUserApi(accesToken, user._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  useEffect(() => {
    let DoctorsActive = [];
    let DoctorsInactive = [];
    let DoctorsRejected = [];
    getUsersActiveApi(token, true).then((response) => {
      const doctorFilter = response.users.filter(
        (user) => user.role === "doctor"
      );

      if (doctorFilter.length) {
        doctorFilter.forEach((element) => {
          element.documentNumber = element.documentNumber
            ? element.documentNumber.toString()
            : "sin DNI";
          element.tuition = element.tuition
            ? element.tuition.toString()
            : "sin matrícula";
          element.branchesOfMedicine =
            element.branchesOfMedicine.length > 0
              ? element.branchesOfMedicine[0].title
              : "sin especialidad";
          element.status = "Aprobado";
          DoctorsActive.push(element);
          setUsersAll((prevUsers) => [...prevUsers, element]);
        });
        setUsersAcive(DoctorsActive);
      }
    });
    getUsersActiveApi(token, false).then((response) => {
      const doctorFilter = response.users.filter(
        (user) => user.role === "doctor"
      );

      if (doctorFilter.length) {
        doctorFilter.forEach((element) => {
          element.documentNumber = element.documentNumber
            ? element.documentNumber.toString()
            : "sin DNI";
          element.tuition = element.tuition
            ? element.tuition.toString()
            : "sin matrícula";
          element.branchesOfMedicine = element.branchesOfMedicine
            ? element.branchesOfMedicine[0].title
            : "sin especialidad";
          element.status = "Desactivado";
          DoctorsInactive.push(element);
          setUsersAll((prevUsers) => [...prevUsers, element]);
        });
        setUsersInactive(DoctorsInactive);
      }
    });
    getUsersRejectedApi(token, false).then((response) => {
      const doctorFilter = response.users.filter(
        (user) => user.role === "rejected"
      );

      if (doctorFilter.length) {
        doctorFilter.forEach((element) => {
          element.documentNumber = element.documentNumber
            ? element.documentNumber.toString()
            : "sin DNI";
          element.tuition = element.tuition
            ? element.tuition.toString()
            : "sin matrícula";
          element.branchesOfMedicine =
            element.branchesOfMedicine !== null
              ? element.branchesOfMedicine[0].title
              : "sin especialidad";
          element.status = "Rechazado";
          DoctorsRejected.push(element);
          setUsersAll((prevUsers) => [...prevUsers, element]);
        });
        setUsersRejected(DoctorsRejected);
      }

      setLoading(false);
    });

    setReloadUsers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, reloadUsers]);

  const openModalDescarga = (user) => {
    setIsVisibleModal(true);
    setModalTitle("Descarga de Documentación");
    setModalContent(
      <>
        <DownloadDocument user={user} setIsVisibleModal={setIsVisibleModal} />
      </>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row gutter={24} className="row-without-margin row-main-admin">
        <Col xs={0} xl={1}></Col>
        <Col xs={24} xl={22} className="row-without-padding">
          <Row
            gutter={24}
            className="row-without-margin"
            style={{ alignItems: "center" }}
          >
            <Col
              span={24}
              className="row-without-padding"
              style={{ paddingTop: "3rem" }}
            >
              <Row gutter={24} className="row-without-margin">
                <Col span={24}>
                  <span className="span-text-title">Profesionales</span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={24}
            className="row-without-margin"
            style={{ paddingBottom: "1rem", paddingTop: "2rem" }}
          >
            <Col xl={4} xs={24}>
              <Dropdown overlay={menu}>
                <Button
                  style={{
                    color: "#7D8DA6",
                    fontWeight: "400",
                    fontSize: "15px",
                    width: "100%",
                    borderRadius: "20px",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {viewUsersActives}
                    <DownOutlined />
                  </div>
                </Button>
              </Dropdown>
            </Col>
          </Row>

          <Row gutter={24} className="row-without-margin">
            <Col xl={24} xs={24} className="row-without-padding">
              {viewUsersActives === "Aprobados" ? (
                <DataTable
                  columns={columnsAllUsers}
                  data={usersActive}
                  classDataTable={"dt-users"}
                />
              ) : viewUsersActives === "Pendientes" ? (
                <DataTable
                  columns={columnsAllUsers}
                  data={usersInactive}
                  classDataTable={"dt-users"}
                />
              ) : viewUsersActives === "Rechazados" ? (
                <DataTable
                  columns={columnsAllUsers}
                  data={usersRejected}
                  classDataTable={"dt-users"}
                />
              ) : viewUsersActives === "Todos" ? (
                <DataTable
                  columns={columnsAllUsers}
                  data={usersAll}
                  classDataTable={"dt-users"}
                />
              ) : (
                <DataTable
                  columns={columnsAllUsers}
                  data={usersAll}
                  classDataTable={"dt-users"}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={0} xl={1}></Col>
      </Row>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        closable={true}
      >
        {modalContent}
      </Modal>
    </>
  );
}

function validateButtons(props) {
  const { desactivateNullUser, activateUser, desactivateUser, user, rowData } =
    props;
  return rowData.active
    ? getButtonCancelAccount({ desactivateUser, user })
    : rowData.role === "rejected"
    ? ""
    : getButtonApproveCancel({ desactivateNullUser, activateUser, user });
}

function getButtonDownload(props) {
  const { openModalDescarga, user } = props;
  return (
    <>
      <Button
        onClick={() => openModalDescarga(user)}
        className="btnDownload"
        style={{
          color: "#006edc",
          display: "flex",
          alignItems: "center",
        }}
      >
        <DownloadIcon style={{ color: "#006edc", marginRight: "0.5rem" }} />{" "}
        Descargar
      </Button>
    </>
  );
}

function getButtonCancelAccount(props) {
  const { desactivateUser, user } = props;

  return (
    <>
      <Button onClick={() => desactivateUser(user)} className="btnAnularCuenta">
        Anular cuenta
      </Button>
    </>
  );
}

function getButtonApproveCancel(props) {
  const { desactivateNullUser, activateUser, user } = props;

  return (
    <>
      <Button
        className="btnAnularCuenta"
        style={{ marginRight: "4%" }}
        onClick={() => desactivateNullUser(user)}
      >
        Rechazar
      </Button>

      <Button onClick={() => activateUser(user)} className="btnAprobar">
        Aprobar
      </Button>
    </>
  );
}
