import { API_URL, apiVersion } from "./config";

export async function createUploadFiles(files, dataBody) {
  try {
    await files.map(async (file) => {
      const contentType = file.file.type;
      const nameFile = file.file.path;
      const documentType = file.documentType;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataBody),
      };

      const url = `${API_URL}${apiVersion}/files?name=${nameFile}&contentType=${contentType}&documentType=${documentType}`;

      const responsePromise = await fetch(url, params);
      const response = await responsePromise.json();

      const { signedUrl } = response.data;

      await fetch(signedUrl, {
        method: "PUT",
        body: file.file,
        headers: {
          "Content-Type": `${contentType}`,
        },
      });
    });

    return "OK";
  } catch (err) {
    return err;
  }
}

export async function createUploadFile(file) {
  try {
    const contentType = file.type;
    const nameFile = file.name;

    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL}${apiVersion}/uploadFile?name=${nameFile}&contentType=${contentType}`;

    const responsePromise = await fetch(url, params);
    const response = await responsePromise.json();

    const { signedUrl } = response.data;
    await fetch(signedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": `${contentType}`,
      },
    });

    return { state: "ok", url: signedUrl };
  } catch (err) {
    return err;
  }
}

export function createUploadImage(token, data) {
  const url = `${API_URL}${apiVersion}/uploadImage`;

  const formData = new FormData();
  formData.append("image", data, data.name);

  const params = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
