/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

// import * as BigCalendar from "react-big-calendar";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import axios from "axios";

import { getAccessTokenApi } from "../../api/auth";
import { API_URL, apiVersion } from "../../api/config";
import "./calendar.css";
import {
  Button,
  Col,
  Row,
  // eslint-disable-next-line no-unused-vars
  Modal as ModalAntd,
  Spin,
  Switch,
  notification,
} from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ViewAppoitmentDetail from "../ViewAppoitmentDetail";
import Modal from "../Modal";
import AddBussinesHours from "../AddBussinesHours/AddBussinesHours";
import Room from "../twilio/room";
import { getUserApi, updateUserApi } from "../../api/user";

import { getSocket } from "../../services/socket";

require("moment/locale/es.js");
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const Calendar = ({ setTwilioHandler, twilioHandler, emergency }) => {
  const user = JSON.parse(localStorage.getItem("USER"));
  const token = getAccessTokenApi();
  // eslint-disable-next-line no-unused-vars
  const [appointments, setAppointments] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [contentModal, setContentModal] = useState(null);
  const [title, setTitle] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [classNameModal, setClassNameModal] = useState("");
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataVideo, setDataVideo] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [userActive, setUserActive] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const selectedAppointment = useRef();
  const [closable, setClosable] = useState(true);
  const [emergencySwitch, setEmergencySwitch] = useState(false);

  const socket = getSocket();

  const handleLogout = () => {
    var mld = document.getElementById("menu-lateral-izquierdo");
    mld.style.display = "block";
    setTwilioHandler({ ...twilioHandler, status: `disconnected`, token: null });
  };

  const _createRoom = async (patientId, appointmentId) => {
    const data = await fetch(`${API_URL}${apiVersion}/create-room`, {
      method: "POST",
      body: JSON.stringify({
        patient_id: patientId,
        appointment_id: appointmentId,
        doctor_id: user?._id,
        appointment: appointmentId,
        doctor: user._id,
        patient: patientId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((res) => res.json());

    return data.room;
  };

  const _joinRoom = async (doctorId, roomId) => {
    const { data } = await axios
      .get(`${API_URL}${apiVersion}/room/${roomId}`, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.error(err));

    return data.room;
  };
  const _connectToRoom =
    ({ doctorId, patientId, appointmentId, roomId }) =>
    async () => {
      setVisibleModal(false);
      let room;

      if (user.role === "doctor" && roomId === undefined) {
        room = await _createRoom(patientId, appointmentId);
      } else {
        room = await _joinRoom(doctorId, roomId);
      }

      if (room._id) {
        const data = await fetch(`${API_URL}${apiVersion}/videoToken`, {
          method: "POST",
          body: JSON.stringify({
            identity: user.fullName,
            room: room._id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => res.json());

        if (
          data.token &&
          data.token !== "" &&
          data.token !== "null" &&
          room._id
        ) {
          setTwilioHandler({
            ...twilioHandler,
            isLoading: false,
            status: "connecting",
            token: data.token,
            roomName: room._id,
          });

          let url = `${API_URL}${apiVersion}/update-appointment/${appointmentId}`;

          const r = await axios
            .put(
              url,
              {
                room_id: room._id,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .catch((err) => console.log(err));

          if (emergency?.isEmergency !== undefined)
            socket.emit("acceptDoctor", emergency);
          else {
            await axios.post(
              `${API_URL}${apiVersion}/accept-doctor-scheduled`,
              {
                appointment: r.data.appointment,
                doctor: user,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            );

            // socket.emit("acceptDoctorScheduled", { appointment: r.data.appointment, doctor: user });
          }
        } else {
          setTwilioHandler({
            ...twilioHandler,
            isLoading: false,
            status: "disconnected",
          });
          alert("Error", "No fue posible conectarse a la sala");
        }
      } else {
        alert("Error", "No fue posible crear a la sala");
      }
    };

  async function checkForRoom(id) {
    let arr = [];
    // const polling = setInterval(async () => {
    let url = `${API_URL}${apiVersion}/appointment/${id}`;
    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.log("error", err));
    if (r?.data) {
      const appt = { ...r.data, ...r.data.doctor, ...r.data.patient };
      const idx = arr.findIndex((a) => a?._id === id);
      if (idx >= 0) {
        setAppointments([
          ...arr.slice(0, idx),
          { ...arr[idx], room_id: appt.room_id },
          ...arr.slice(idx + 1),
        ]);
      }
    }
  }

  async function getAppointments() {
    var tmpArr = [];
    setLoading(true);
    let url = `${API_URL}${apiVersion}/appointments-doctor?doctor_id=${user._id}`;

    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    if (r?.data) {
      setLoading(false);
      // console.log('set');
      // console.log('data -> ', r.data);
      // get device timezone eg. -> "Asia/Shanghai"
      const deviceTimeZone = moment.tz.guess();
      // Make moment of right now, using the device timezone
      const today = moment().tz(deviceTimeZone);
      // Get the UTC offset in hours
      const currentTimeZoneOffsetInHours = today.utcOffset() / 60;

      let appts = r.data.appointments.map((appt) => {
        let apptDate = moment
          .utc(appt.appointment_date)
          .utcOffset(currentTimeZoneOffsetInHours);
        let appDateInSeconds = apptDate.valueOf();
        let now = moment().valueOf();
        const HOUR_IN_SECONDS = 1000 * 60 * 60;
        if (appDateInSeconds - now < HOUR_IN_SECONDS) {
          // console.log('GOING UNDER');

          checkForRoom(appt._id);
        }
        return { ...appt, appointment_date: apptDate.format() };
      });
      if (appts && appts.length > 0) {
        let fecha = moment().format("YYYY-MM-DD");
        let citas = [];
        // eslint-disable-next-line array-callback-return
        appts.map((appt) => {
          if (moment(appt.appointment_date).format("YYYY-MM-DD") >= fecha) {
            citas.push(appt);
          }
        });
        setAppointments(citas);
        // eslint-disable-next-line array-callback-return
        citas.map((cita) => {
          tmpArr.push({
            title: "",
            especialidad: cita.specialty?.title,
            patient: cita.patient,
            doctor: cita.doctor,
            subtitle: cita.specialty?.title.substr(0, 8),
            start: new Date(cita.dateCompletedStart),
            end: new Date(cita.dateCompletedEnd),
            color: "#b6cff6",
            id: cita._id,
            fecha: cita.appointment_date,
            time: cita.appointment_time,
            type: "cita",
            reason: cita.reason,
            room_id: cita.room_id,
          });
        });

        // eslint-disable-next-line array-callback-return

        let citasPasadas = [];
        // eslint-disable-next-line array-callback-return
        appts.map((appt) => {
          if (moment(appt.appointment_date).format("YYYY-MM-DD") < fecha) {
            citasPasadas.push(appt);
          }
        });

        // setAppointmentsPast(citasPasadas);
        // setAppointments(appts);
      }
    }

    let urlHour = `${API_URL}${apiVersion}/getBussinesHoursByDoctor?id=${user._id}`;

    const dataHours = await axios
      .get(urlHour, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.log(err));

    if (dataHours?.data) {
      // eslint-disable-next-line array-callback-return
      dataHours.data?.businessHours.map((hour) => {
        if (validateObjectExists(tmpArr, hour)) {
          tmpArr.push({
            title: "",
            subtitle: "",
            start: new Date(hour.start),
            end: new Date(hour.end),
            color: "#FCCF47",
            id: hour._id,
            type: "hour",
          });
        }
      });
    }
    setDataArray(tmpArr);
  }

  function validateObjectExists(array, object) {
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].start.toLocaleString() ===
          new Date(object.start).toLocaleString() &&
        array[i].end.toLocaleString() === new Date(object.end).toLocaleString()
      ) {
        return false;
      }
    }
    return true;
  }

  async function getUserActive() {
    if (user) {
      getUserApi(token, user._id)
        .then((response) => {
          setUserActive(response.user);
          setEmergencySwitch(response.user.emergency);
        })
        .catch((error) => console.log(error));
    }
  }

  useEffect(() => {
    getAppointments();
  }, [reload]);

  useEffect(() => {
    getUserActive();
  }, [refresh]);

  useEffect(() => {
    socket.emit("updateListConnection", {
      socketId: socket.id,
      userId: user._id,
    });

    if (emergency != null) {
      let dateHora = new Date();
      dateHora.setUTCHours(
        emergency.appointment.appointment_time.split(":")[0]
      );
      dateHora.setUTCMinutes(
        emergency.appointment.appointment_time.split(":")[1]
      );
      let setHora = moment(dateHora).format("HH:mm");

      SelectedEvent({
        id: emergency.appointment._id,
        doctor: {
          fullName: user.fullName,
          _id: user._id,
        },
        especialidad: "Medicina de emergencia",
        patient: {
          _id: emergency.appointment.patient._id,
          fullName: emergency.appointment.patient.fullName,
          gender: emergency.appointment.patient.gender,
          date_of_birth: emergency.appointment.patient.date_of_birth,
        },
        type: "cita",
        fecha:
          emergency.appointment.appointment_date.split(" ")[0] + " " + setHora,
        time: setHora,
        reason: "",
        room_id: emergency.appointment.room_id,
      });
    }
  }, []);
  const addBussinesHour = () => {
    setVisibleModal(true);
    setClassNameModal("ModalCalendar");
    setTitle("Disponibilidad");
    setClosable(false);
    setContentModal(
      <div>
        <AddBussinesHours
          visible={visibleModal}
          setIsVisible={setVisibleModal}
          setReload={setReload}
          reload={reload}
        />
      </div>
    );
  };

  // const startOfWeek = moment().startOf("week").toDate();
  // const endOfWeek = moment().endOf("week").toDate();

  const deleteHour = async (event) => {
    const r = await axios
      .delete(
        `${API_URL}${apiVersion}/delete-businessHours/${selectedAppointment.current}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => console.error(err));
    if (r?.data) {
      toast.success("Se elimino el horario seleccionado de manera correcta", {
        position: "top-center",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setVisibleModal(false);
      setReload(!reload);
    }
  };

  const SelectedEvent = async (event) => {
    setDataVideo({
      ...dataVideo,
      doctor: event?.doctor,
      specialty: event?.especialidad,
      patient: event.patient,
      start:
        emergency != null
          ? new Date(emergency.appointment.dateCompletedStart)
          : event.start,
      end:
        emergency != null
          ? new Date(emergency.appointment.dateCompletedEnd)
          : event.end,
      emergency: emergency != null,
      room_id: event?.room_id,
    });
    if (event.type === "cita") {
      setVisibleModal(true);
      setClassNameModal("ModalCalendarViewAppoiment");
      setTitle(`Información de consulta`);
      setClosable(true);
      setContentModal(
        <>
          <ViewAppoitmentDetail
            visible={visibleModal}
            setIsVisible={setVisibleModal}
            event={event}
            _connectToRoom={_connectToRoom}
            twilioHandler={twilioHandler}
          />
        </>
      );
    } else {
      // confirm({
      //   title: "Eliminando horario",
      //   content: `¿Estas seguro que quieres eliminar el horario disponible seleccionado?`,
      //   okText: "Eliminar",
      //   okType: "danger",
      //   cancelText: "Cancelar",
      //   onOk() {
      //     deleteHour(event);
      //   },
      // });
      selectedAppointment.current = event.id;
      setVisibleModal(true);
      setClassNameModal("ModalCalendar DeleteSchedule");
      setTitle(`Eliminando horario`);
      setClosable(false);
      setContentModal(
        <div style={{ padding: 5 }}>
          <div
            style={{
              width: "100%",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            <div style={{ display: "grid" }}>
              <span>¿Estas seguro que quieres eliminar</span>
              <span>el horario disponible seleccionado?</span>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "8%",
              marginBottom: "4%",
            }}
          >
            <Button
              onClick={() => setVisibleModal(false)}
              className="btn-decline btn-cancel-delete"
            >
              Cancelar
            </Button>
            <Button
              type="default"
              className="btn-confirm-delete"
              onClick={deleteHour}
            >
              Eliminar
            </Button>
          </div>
        </div>
      );
    }
  };

  const CustomToolbar = ({ label, onNavigate }) => {
    let weekRange = moment(label);
    const splitStartDate = weekRange._i.split(" – ")[0].split(" ");
    const startDate = `${splitStartDate[1]} ${splitStartDate[0]}`;
    const splitEndDate = weekRange._i.split(" – ")[1].split(" ");
    const endDate = `${
      splitEndDate.length === 1
        ? splitEndDate[0] + " " + splitStartDate[0]
        : splitEndDate[1] + " " + splitEndDate[0]
    }`;
    const timeZones = moment.tz.names();
    const handleTimeZoneChange = (event) => {
      // const selectedTimeZone = event.target.value;
    };

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handlePrevButtonClick = () => {
      onNavigate("PREV");
    };

    const handleNextButtonClick = () => {
      onNavigate("NEXT");
    };

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          fontSize: "15px",
          fontFamily: "Roboto",
          fontWeight: "400",
          color: "#7D8DA6",
          marginBottom: "4%",
        }}
      >
        <div style={{ width: "50%", display: "flex" }}>
          <span className="span-calendar-date">
            {startDate} - {endDate}
          </span>
          <div className="div-calendar-navigate">
            <button
              onClick={handlePrevButtonClick}
              style={{ borderStyle: "none", borderRadius: "4px" }}
            >
              <LeftOutlined />
            </button>
            <button
              onClick={handleNextButtonClick}
              style={{
                marginLeft: "10%",
                borderStyle: "none",
                borderRadius: "4px",
              }}
            >
              <RightOutlined />
            </button>
          </div>
        </div>
        <div style={{ width: "50%", textAlign: "end" }}>
          <select
            onChange={handleTimeZoneChange}
            style={{
              borderStyle: "none",
              width: "60%",
              backgroundColor: "transparent",
            }}
          >
            {timeZones.map((timeZone) => (
              <option
                key={timeZone}
                value={timeZone}
                selected={timeZone === localTimeZone}
              >
                (GMT{moment.tz(timeZone).format("Z")}) {timeZone}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const onChangeEmergencySwitch = (checked) => {
    setEmergencySwitch(!emergencySwitch);
    updateUserApi(token, { emergency: checked }, user._id)
      .then((res) => {
        notification["success"]({
          message: "Su estado de emergencia se modificó exitosamente",
        });
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (twilioHandler.token) {
    return (
      <Room
        roomName={twilioHandler.roomName}
        token={twilioHandler.token}
        handleLogout={handleLogout}
        dataVideo={dataVideo}
        appointments={appointments}
      />
    );
  } else {
    return (
      <>
        <Row
          style={{
            backgroundColor: "#fff",
            marginRight: "0px",
            marginLeft: "0px",
            height: "100%",
          }}
        >
          <Col xs={24} xl={24} style={{ padding: "1rem", height: "100%" }}>
            <Row style={{ marginTop: "1%" }}>
              <Col xs={0} xl={12}></Col>
              <Col className="col-header-calendar" xs={24} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "30px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      paddingRight: 10,
                      color: "#006AFF",
                      fontFamily: "Roboto",
                    }}
                  >
                    EMERGENCIAS
                  </span>
                  <Switch
                    onChange={onChangeEmergencySwitch}
                    checked={emergencySwitch}
                  />
                </div>
                <Button
                  icon={<PlusOutlined />}
                  onClick={addBussinesHour}
                  className="btn-agregate-hour"
                  type="ghost"
                >
                  Agregar
                </Button>
              </Col>
            </Row>
            <Row
              gutter={24}
              className="headerCalendar"
              style={{ marginTop: "2%" }}
            >
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <span className="span-text-title">
                  Mi Calendario
                </span>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginLeft: "-1rem",
                  alignItems: "center",
                }}
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              ></Col>
            </Row>

            <Row style={{ height: "85%", display: "grid" }}>
              <Col span={24}>
                <BigCalendar
                  style={{ height: "100%" }}
                  className="calendar-medico"
                  localizer={localizer}
                  events={dataArray}
                  startAccessor="start"
                  endAccessor="end"
                  view={`week`}
                  onView={(e) => {
                    console.log("view", e);
                  }}
                  // style={{ height: 500 }}
                  views={[`week`]}
                  defaultDate={new Date()}
                  // toolbar={false}
                  // allDayAccessor={false}
                  // step={20}
                  selectable={true}
                  // formats={{
                  //   timeGutterFormat: "HH:mm",
                  //   selectRangeFormat: ({ start, end }, culture, localizer) =>
                  //     localizer.format(start, `HH: mm`) +
                  //     " — " +
                  //     localizer.format(end, `HH: mm`),
                  // }}
                  onSelectEvent={async (event, e) => {
                    SelectedEvent(event);
                  }}
                  // onSelectSlot={({ start, end }) => {
                  //   console.log("adaff");
                  //   setHours(start, end);
                  //   // console.log(start, end);
                  // }}
                  messages={{
                    next: ">",
                    previous: "<",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                  }}
                  eventPropGetter={(event) => {
                    const backgroundColor = event.subtitle
                      ? "#006AFF"
                      : "rgba(0, 106, 255, 0.35)";
                    const color = event.subtitle ? "white" : "#006AFF";
                    const style = {
                      backgroundColor: backgroundColor,
                      color: color,
                      display: "block",
                    };

                    return { style: style };
                  }}
                  components={{
                    toolbar: CustomToolbar,
                    week: {
                      header: ({ date }) => {
                        const formatNumericDay = "D";
                        const formatDay = { weekday: "short" };

                        const day = new Date(date).toLocaleDateString(
                          undefined,
                          formatDay
                        );
                        const formattedDay =
                          day.charAt(0).toUpperCase() + day.slice(1, 3);

                        return (
                          <div className="div-formattedDay">
                            <div
                              style={{ fontWeight: "400", fontSize: "12px" }}
                            >
                              {formattedDay}
                            </div>
                            <div
                              style={{ fontWeight: "400", fontSize: "12px" }}
                            >
                              {moment(date)
                                .format(formatNumericDay)
                                .padStart(2, "0")}
                            </div>
                          </div>
                        );
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <ToastContainer />
        <Modal
          children={contentModal}
          title={title}
          visible={visibleModal}
          setIsVisible={setVisibleModal}
          className={classNameModal}
          closable={closable}
        />
      </>
    );
  }
};

export default Calendar;
