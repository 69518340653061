export const globalStyles = `

html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: linear-gradient(
    to right,
    #5e3aff,
    #5153fe,
    #436dfd,
    #3386fc,
    #259bfc,
    #13b4fa
  );

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #00ccf8, 0 0 5px #00ccf8;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-direction-row {
  flex-direction: row !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

input:focus {
  outline: none !important;
}

:focus {
  outline: none !important;
}
.rw-widget-picker {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rw-widget-picker input {
  font-family: proximasemi !important;
  font-size: 18px;
  box-shadow: none !important;
}

.rw-select-bordered {
  border: none !important;
}

.rw-popup-container {
  z-index: 999999;
  position: relative !important;
}

.rw-popup li,
.rw-calendar-body *,
.rw-calendar-head *,
.rw-calendar-header .rw-calendar-btn-view,
.rw-calendar-footer * {
  font-family: proximasemi !important;
}

.rw-cell.rw-state-selected, .rw-cell.rw-state-selected:hover {
  background-color: #e83518 !important;
  border-color: #e83518 !important;
  color: white;
}

.react-tabs__tab {
  display: inline-block;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Arial !important;
  padding: 12px 24px;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.scroll-head {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  margin: 8px 0px 8px 0px;
  text-align: center;
  height: 18px;
  font-size: 12px;
  background: #e9e9e9;
  visibility: hidden !important;
  display: none !important;
  /* margin-top: 0; */
}

.datepicker-month-label, .blank-space-div { display: none !important; }

.datepicker-button-next, .datepicker-button-previous { background: #2E78D3; }

.datepicker-strip, .datepicker { width: 100% !important; margin: 15px 0 5px !important; }

.wrapper {flex: 1}

.datepicker > .wrapper {flex: 0;}

.datepicker-dateList {
  display: flex;
  margin: 2px 0 2px 0;
  flex: 1;
  padding-left: 5%;
  width: 100%;
}

.date-day-Item-selected {
  color: #2E78D3 !important;
  border-color: #2E78D3 !important;
}

.datepicker-date-day-Item:hover, .datepicker-button-next:hover, .datepicker-button-previous:hover {
  background-color: #2E78D3; color: white !important; 
}

.rbc-row.rbc-time-header-cell {
  flex: 1;
  min-height: 50px;
}

.rbc-header {
  justify-content: center;
  align-items: center;
  display: flex;
  border-bottom: none !important;
}

.rbc-header a {
  display: flex;
  align-self: center;
  justify-self: center;
  flex: 1;
  text-align: center;
  font-family: Roboto, sans-serif;
}

.rbc-header a span {
  flex: 1
}

.rbc-allday-cell {
  display: none !important;
}

.rbc-time-slot {
  padding: 9px 8px 0px;
  font-family: 'Roboto';
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border-radius: 0 !important;
  border: none !important;
}

.rbc-day-slot .rbc-event-label {
  visibility: hidden !important;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 2px !important;
}

.react-datepicker__input-container {
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: text !important;
  display: inline-flex !important;
  position: relative !important;
  font-size: 1rem !important;
  box-sizing: border-box !important;
  align-items: center !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.1876em !important;
  letter-spacing: 0.00938em !important;
}

.react-datepicker__input-container:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.react-datepicker-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}

.react-datepicker__input-container input {
  font-family: Roboto !important;
  color: currentColor !important;
  width: 100% !important;
  border: 0 !important;
  height: 1.2876em !important;
  margin: 0 !important;
  display: block !important;
  padding: 6px 0 7px !important;
  min-width: 0 !important;
  background: none !important;
  box-sizing: content-box !important;
  animation-name: mui-auto-fill-cancel !important;
  letter-spacing: inherit !important;
  animation-duration: 10ms !important;
  -webkit-tap-highlight-color: transparent !important;
  font-size: 15px;
}

.react-tel-input .form-control {
  font-family: Roboto !important;
  font-size: 15px;
}


.react-select-container {
  margin-top: 30px;
}

.react-select__control {
  border-radius: 0 !important;
}

.react-select__control * {
  font-family: Roboto;
}

.kep-login-facebook.metro{
  text-transform: none;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14.2px;
  font-family: Roboto !important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 4px !important;
  box-shadow: rgb(0 0 0 \/ 24%) 0px 2px 2px 0px, rgb(0 0 0 \/ 24%) 0px 0px 1px 0px;
}

`;

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLocaleLowerCase());
}
