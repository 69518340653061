import React, { useEffect, useState } from 'react';
import "./Success.css";
import { useLocation } from "react-router-dom";
import { API_URL, apiVersion } from "../../api/config";
import axios from "axios";
import { getAccessTokenApi } from "../../api/auth";

const Success = () => {
  const token = getAccessTokenApi();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search.substring(1));
    const orderId = searchParams.get("OrderID");
    redirectCallbackPayment(orderId);
  }, []);

  async function redirectCallbackPayment(OrderID) {
    const userID = localStorage.getItem("USER_ID");
    let url = `${API_URL}${apiVersion}/redirect-callback-payment`;
    const r = await axios
      .post(url,
        {
          orderId: OrderID,
          userId: userID
        },
        {
          headers: {
            Authorization: token,
          },
        })
      .catch((err) => {
        console.log("error", err);
        window.close();
      });

    setIsLoading(false);
    window.close();
  }

  return (
    <>
      {isLoading ? (
        <div className="spinner" id="spinner"></div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Success;