import React, { useEffect, useState } from "react";
import { getUserApi } from "../../../api/user";
import useAuth from "../../../hooks/useAuth";
import { getAccessTokenApi } from "../../../api/auth";
import { Row, Col, Form, Checkbox, Button } from "antd";
import moment from "moment";
import "./ViewProfile.css";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "./ViewProfile.css";
export default function ViewProfile(props) {
  const { setState, state } = props;
  const { user } = useAuth();
  const token = getAccessTokenApi();
  const [userActive, setUserActive] = useState(null);
  const [dataPendingChanges, setDataPendingChanges] = useState([]);

  const getUserActive = () => {
    try {
      getUserApi(token, user.id).then((response) => {
        setUserActive(response.user);
        getPendingChanges(response.user);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getPendingChanges = (user) => {
    if (user.pendingChange) {
      setDataPendingChanges(JSON.parse(user.changes));
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    getUserActive();

    return () => {
      ac.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card-profile-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 className="datos-personales textRoboto">Datos personales</h3>
        {state === "view" ? (
          ""
        ) : (
          <Button
            icon={
              <ModeEditOutlineOutlinedIcon
                style={{ marginRight: 5, fontSize: 18 }}
              />
            }
            className="edit-btn"
            onClick={() => setState("edit")}
          >
            Editar
          </Button>
        )}
      </div>

      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Nombre/s</label>
            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find((f) => f.field === "name") !==
                  undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.name || "-"}
            </span>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Apellido</label>
            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find((f) => f.field === "lastName") !==
                  undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.lastName || "-"}
            </span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <label className="label-view">Correo electrónico</label>
            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find((f) => f.field === "email") !==
                  undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.email}
            </span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Tipo de documento</label>

            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find((f) => f.field === "documentType") !==
                  undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.documentType || "-"}
            </span>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Número de documento</label>
            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find(
                    (f) => f.field === "documentNumber"
                  ) !== undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.documentNumber || "-"}
            </span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Fecha de nacimiento</label>
            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find(
                    (f) => f.field === "date_of_birth"
                  ) !== undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.date_of_birth !== undefined
                ? moment(userActive?.date_of_birth).format("DD/MM/YYYY")
                : null}
            </span>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Teléfono</label>
            <span
              className="span-class"
              style={{
                backgroundColor:
                  dataPendingChanges.find((f) => f.field === "mobilePhone") !==
                  undefined
                    ? "#fff3cc"
                    : "none",
              }}
            >
              {userActive?.mobilePhone || "-"}
            </span>
          </Form.Item>
        </Col>
      </Row>
      <label className="label-view">Género</label>
      <Row gutter={24}>
        <Col style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <Form.Item>
            <Checkbox
              checked={userActive?.gender === "Femenino" ? true : false}
              disabled={true}
            >
              Femenino
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={1}>
          <Form.Item style={{ marginTop: "0.5rem" }}>
            <Checkbox
              checked={userActive?.gender === "Masculino" ? true : false}
              disabled={true}
            >
              Masculino
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {userActive?.role === "doctor" && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <label className="label-view">Descripción</label>
              <span
                className="span-class"
                style={{
                  backgroundColor:
                    dataPendingChanges.find((f) => f.field === "bio") !==
                    undefined
                      ? "#fff3cc"
                      : "none",
                }}
              >
                {userActive?.bio || "-"}
              </span>
            </Form.Item>
          </Col>
        </Row>
      )}
      {dataPendingChanges.length > 0 && (
        <Row gutter={24}>
          <Col span={24}>
            <div style={{ display: "grid" }}>
              <span
                className="span-class"
                style={{
                  backgroundColor: "#fff3cc",
                  padding: "1rem",
                  justifyContent: "center",
                  borderRadius: "10px",
                }}
              >
                Sus cambios se encuentran pendientes de aprobación.
              </span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
