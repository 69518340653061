import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./ModalVideoCall.css";

export default function ModalVideoCall({
  setVisibleModal,
  reasonWrite,
}) {
  const [reason, setReason] = useState(reasonWrite || "");
  const [date, setDate] = useState({
    date: moment(),
  });
  const history = useHistory();

  useEffect(() => {
    if (reasonWrite !== undefined) {
      setReason(reasonWrite);
    }
  }, [reasonWrite]);

  return (
    <>
      <div style={{ padding: 5 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 20,
              width: "50%",
            }}
          >
            <span style={{ color: "#c3c3c3" }}>Fecha</span>
            <span style={{ fontSize: 15, fontWeight: "500" }}>
              {moment(date.date).format("DD/MM/YYYY")}
            </span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span style={{ color: "#c3c3c3" }}>Costo</span>
            <span style={{ fontSize: 15, fontWeight: "500" }}>$ 20</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // width: "50%",
            paddingTop: 30,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="title-lateral-light">Motivo de la consulta</span>
            <TextArea className="title-lateral-bold" value={reason} disabled></TextArea>
          </div>
        </div>

        <Row gutter={24}
          style={{
            display: "flex",
            paddingTop: 20,
            marginLeft: 0,
            marginRight: 0
          }}
        >
          <Col xs={0} xl={8}></Col>
          <Col xs={24} xl={16} style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              onClick={() => setVisibleModal(false)}
              className="vivi-modal-btn btn-back"
            >
              Volver
            </Button>
            <Button
              onClick={() => {
                setVisibleModal(false);
                history.push({
                  pathname: "/pago",
                  state: {
                    objeto: {
                      doctor: "",
                      time: moment().format("HH:mm"),
                      date: moment().format("YYYY-MM-DD"),
                      reason: reason,
                      specialty: { title: "Medicina de emergencia", _id: "63ee2eb86c0574e2ead7ab44" },
                      emergency: true,
                      price: 20
                    },
                  },
                });
              }}
              type="primary"
              className="vivi-modal-btn btn-ok"
              style={{
                width: "151px!important",
                height: "40px!important",
              }}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}