import React, { useEffect, useState } from "react";
import { getAccessTokenApi } from "../../api/auth";
import { API_URL, apiVersion } from "../../api/config";
import axios from "axios";
import moment from "moment";

import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Space,
  Card,
  Pagination,
} from "antd";
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  UserOutlined,
  FileOutlined,
  MenuOutlined,
  AppstoreFilled,
  LeftCircleFilled,
} from "@ant-design/icons";
import "./ReportDoctor.css";

export default function ReportDoctor() {
  const token = getAccessTokenApi();
  const user = JSON.parse(localStorage.getItem("USER"));
  const [appointmentsWeekly, setAppointmentsWeekly] = useState([]);
  const [appointmentsMonth, setAppointmentsMonth] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [amountMonth, setAmountMonth] = useState(0);
  const [selectYear, setSelectYear] = useState(null);
  const [state, setState] = useState("Semanal");
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    limit: 0,
    page: 1,
    pages: 0,
    total: 0,
  });

  const [weekRange, setWeekRange] = useState("");
  const [rangeWeek, setRangeWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [currentMonthRange, setCurrentMonthRange] = useState(null);
  const [setHeight, setSetHeight] = useState(true);

  const getAndSetCurrentMonthRange = (month) => {
    const monthRange = getMonthRange(month);
    setCurrentMonthRange(monthRange);
  };

  useEffect(() => {
    getAndSetCurrentMonthRange(new Date().getMonth());
  }, []);

  function getCurrentWeek() {
    const fechaActual = new Date();

    const diaSemana = fechaActual.getDay();

    const inicioSemana = new Date(fechaActual);
    inicioSemana.setDate(fechaActual.getDate() - diaSemana);

    const finSemana = new Date(fechaActual);
    finSemana.setDate(fechaActual.getDate() + (6 - diaSemana));

    const semanaActual = {
      start: moment(inicioSemana).format("YYYY-MM-DD"),
      end: moment(finSemana).format("YYYY-MM-DD"),
    };

    setWeekRange(`${inicioSemana
      .getDate()
      .toLocaleString()
      .padStart(2, "0")} ${getNameMonth(inicioSemana.getMonth())} 
    - ${finSemana.getDate().toLocaleString().padStart(2, "0")} ${getNameMonth(
      finSemana.getMonth()
    )}`);

    setRangeWeek(semanaActual);

    return semanaActual;
  }

  function getMonthRange(month) {
    const fechaActual = new Date();
    const year =
      selectYear == null
        ? fechaActual.getFullYear()
        : month === 12
        ? selectYear + 1
        : month === -1
        ? selectYear - 1
        : selectYear;
    setSelectYear(year);
    const validMonth = month === -1 ? 11 : month === 12 ? 0 : month;
    // Obtener el primer día del mes actual
    const primerDiaMes = new Date(year, validMonth, 1);

    // Obtener el último día del mes actual
    const ultimoDiaMes = new Date(year, validMonth + 1, 0);

    // Crear el objeto con las propiedades startDate y endDate
    const rangoMesActual = {
      start: moment(primerDiaMes).format("YYYY-MM-DD"),
      end: moment(ultimoDiaMes).format("YYYY-MM-DD"),
    };

    setWeekRange(getNameMonth(validMonth));

    setSelectedMonth(validMonth);

    return rangoMesActual;
  }

  function getNameMonth(numeroMes) {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const indiceMes = numeroMes - 1;

    if (numeroMes >= 0 && numeroMes < meses.length) {
      return meses[numeroMes];
    } else {
      return "Mes inválido";
    }
  }

  const getAppointmentsWeeklyDoctor = async (week, page) => {
    //const currentWeek = getCurrentWeek();
    let url = `${API_URL}${apiVersion}/appointments-doctor-report-weekly?doctor_id=${user._id}&date_start=${week.start}&date_end=${week.end}&page=${page}&limit=${limit}`;

    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.log(err));
    if (r?.data) {
      setPagination({
        limit: r?.data.pagination.limit,
        page: r?.data.pagination.page,
        pages: r?.data.pagination.pages,
        total: r?.data.pagination.totalPages,
      });
      const firstDayOfTheMonth = moment()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");
      const lastDayOfTheMonth = moment()
        .endOf("month")
        .format("YYYY-MM-DD hh:mm");

      const appoinmentsByMonth = r.data?.data?.sort((a, b) => {
        const dateA = new Date(a.appointment_date);
        const dateB = new Date(b.appointment_date);
        return dateB - dateA;
      });

      let amount = [];
      appoinmentsByMonth.map((appointment) => amount.push(appointment.amount));

      let amountTotal = amount.reduce((a, b) => a + b, 0);
      setAppointmentsMonth(appoinmentsByMonth);

      setAmountMonth(amountTotal);

      setSetHeight(r.data?.data.length < 9);
    }
  };

  const getAppointmentsMonthlyDoctor = async (selMonth, page) => {
    let url = `${API_URL}${apiVersion}/appointments-doctor-report?doctor_id=${user._id}&page=${page}&limit=${limit}&date_start=${selMonth.start}&date_end=${selMonth.end}`;

    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.log(err));
    if (r?.data?.data) {
      console.log("datra recibida", r.data);
      setPagination({
        limit: r?.data.pagination.limit,
        page: r?.data.pagination.page,
        pages: r?.data.pagination.pages,
        total: r?.data.pagination.totalPages,
      });
      const firstDayOfTheMonth = moment()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");
      const lastDayOfTheMonth = moment()
        .endOf("month")
        .format("YYYY-MM-DD hh:mm");

      const appoinmentsByMonth = r.data?.data?.sort((a, b) => {
        const dateA = new Date(a.appointment_date);
        const dateB = new Date(b.appointment_date);
        return dateB - dateA;
      });
      // const appoinmentsByMonth = sortedAppointments.filter(
      //   (app) =>
      //     moment(app.appointment_date).format("YYYY-MM-DD") >=
      //       firstDayOfTheMonth &&
      //     moment(app.appointment_date).format("YYYY-MM-DD") <= lastDayOfTheMonth
      // );

      let amount = [];
      appoinmentsByMonth.map((appointment) => amount.push(appointment.amount));

      let amountTotal = amount.reduce((a, b) => a + b, 0);
      setAppointmentsWeekly(appoinmentsByMonth);

      setAmountMonth(amountTotal);
    }
  };

  useEffect(() => {
    if (state === "Semanal") {
      getAppointmentsWeeklyDoctor(getCurrentWeek(), page);
    } else
      getAppointmentsMonthlyDoctor(getMonthRange(new Date().getMonth(), page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const changePage = (page, data) => {
    setPage(page);
    if (state === "Mensual") {
      getAppointmentsMonthlyDoctor(currentMonthRange, page);
    } else {
      getAppointmentsWeeklyDoctor(rangeWeek, page);
    }
  };

  const menu = (
    <Menu
      onClick={(e) => {
        setState(e.key);
        setLimit(e.key === "Semanal" ? 8 : 10);
        setPage(1);
      }}
    >
      <Menu.Item key="Semanal">Semanal</Menu.Item>
      <Menu.Item key="Mensual">Mensual</Menu.Item>
    </Menu>
  );

  const selectWeek = (isSum = false) => {
    const isWeekly = state === "Semanal";
    if (isWeekly) {
      const milisegundosPorDia = 24 * 60 * 60 * 1000;
      const fechaInicio = new Date(rangeWeek.start);
      const fechaFin = new Date(rangeWeek.end);

      if (isSum) {
        fechaInicio.setTime(fechaInicio.getTime() + 8 * milisegundosPorDia);
        fechaFin.setTime(fechaFin.getTime() + 8 * milisegundosPorDia);
      } else {
        fechaInicio.setTime(fechaInicio.getTime() - 6 * milisegundosPorDia);
        fechaFin.setTime(fechaFin.getTime() - 6 * milisegundosPorDia);
      }

      const selWeek = {
        start: moment(fechaInicio).format("YYYY-MM-DD"),
        end: moment(fechaFin).format("YYYY-MM-DD"),
      };

      setWeekRange(`${fechaInicio
        .getDate()
        .toLocaleString()
        .padStart(2, "0")} ${getNameMonth(fechaInicio.getMonth())} 
      - ${fechaFin.getDate().toLocaleString().padStart(2, "0")} ${getNameMonth(
        fechaFin.getMonth()
      )}`);

      setRangeWeek(selWeek);

      getAppointmentsWeeklyDoctor(selWeek);
    } else {
      let newMonth = selectedMonth;

      if (isSum) {
        newMonth++;
      } else {
        newMonth--;
      }

      getAppointmentsMonthlyDoctor(getMonthRange(newMonth));
    }
  };

  return (
    <Row style={{ background: "white", height: setHeight ? "100%" : "" }}>
      <Col style={{ width: "100%", margin: "0%", height: "90vh" }}>
        <h3 className="title-reporte textRoboto" style={{ padding: "1rem" }}>
          Reporte
        </h3>

        <Row
          gutter={24}
          className="margin-left-right"
          style={{ padding: "1rem", alignItems: "center" }}
        >
          <Col xs={12} xl={3} className="padding-left-right">
            <Dropdown overlay={menu}>
              <span
                style={{
                  color: "#7D8DA6",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
              >
                <Space>
                  {state}
                  <DownOutlined style={{ display: "flex" }} />
                </Space>
              </span>
            </Dropdown>
          </Col>
          <Col xs={12} xl={2} className="padding-left-right">
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <button className="btn-icons-pointer">
                  <MenuOutlined
                    onClick={() => {
                      console.log("select mensual");
                      setState("Mensual");
                      setLimit(10);
                      setPage(1);
                    }}
                    className="icons"
                  />
                </button>
              </div>
              <div>
                <button className="btn-icons-pointer">
                  <AppstoreFilled
                    onClick={() => {
                      console.log("select semanal");
                      setState("Semanal");
                      setLimit(8);
                      setPage(1);
                    }}
                    className="icons"
                  />
                </button>
              </div>
            </div>
          </Col>
          <Col xs={0} xl={11} className="padding-left-right"></Col>
          <Col xs={24} xl={8} className="col-header-report padding-left-right">
            <span className="semana-text">{weekRange}</span>{" "}
            <Button
              style={{ marginRight: "1rem" }}
              className="next-prev"
              onClick={() => selectWeek(false)}
            >
              <LeftOutlined style={{ marginLeft: "-9px" }} className="icons" />
            </Button>{" "}
            <Button className="next-prev" onClick={() => selectWeek(true)}>
              <RightOutlined style={{ marginLeft: "-9px" }} className="icons" />
            </Button>
          </Col>
        </Row>

        {state === "Semanal" && (
          <>
            <Row
              gutter={24}
              className="margin-left-right report-card-content"
              style={{ overflowY: "auto" }}
            >
              <Col xs={24} xl={24} style={{ padding: "1rem" }}>
                <div className="box-report">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      paddingTop: "-1rem",
                      flexWrap: "wrap",
                    }}
                  >
                    {appointmentsMonth.length > 0 &&
                      appointmentsMonth.map((app) => (
                        <Card className="card-report" key={app._id}>
                          <div style={{ display: "flex" }}>
                            <LeftCircleFilled
                              className="icon-circle-pago"
                              style={{ fontSize: "19px" }}
                            />
                            <h4 style={{ paddingLeft: "10px" }}>
                              Pago realizado
                            </h4>
                          </div>
                          <div
                            style={{ marginTop: "15px", marginLeft: "10px" }}
                          >
                            <label className="label-report">Fecha</label>
                            <div style={{ marginTop: "-0.2rem" }}>
                              <CalendarOutlined
                                style={{ marginTop: "-0.3rem" }}
                              />{" "}
                              <h4
                                style={{
                                  marginLeft: "2rem",
                                  marginTop: "-1.1rem",
                                  fontSize: "14px",
                                }}
                              >
                                {moment(app.appointment_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </h4>
                            </div>
                          </div>

                          <div style={{ marginTop: "3px", marginLeft: "10px" }}>
                            <label className="label-report">Hora</label>
                            <div style={{ marginTop: "-0.2rem" }}>
                              <ClockCircleOutlined
                                style={{ marginTop: "-0.3rem" }}
                              />
                              <h4
                                style={{
                                  marginLeft: "2rem",
                                  marginTop: "-1.1rem",
                                  fontSize: "14px",
                                }}
                              >
                                {moment()
                                  .startOf("day")
                                  .add({
                                    hours: app.appointment_time.split(":")[0],
                                    minutes: app.appointment_time.split(":")[1],
                                  })
                                  .format("HH:mm")}
                              </h4>
                            </div>
                          </div>

                          <div style={{ marginTop: "3px", marginLeft: "10px" }}>
                            <label className="label-report">Especialidad</label>
                            <div style={{ marginTop: "-0.2rem" }}>
                              <MedicineBoxOutlined
                                style={{ marginTop: "-0.3rem" }}
                              />
                              <h4
                                style={{
                                  marginLeft: "2rem",
                                  marginTop: "-1.1rem",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {app.specialty?.title || "Sin detallar"}
                              </h4>
                            </div>
                          </div>

                          <div style={{ marginTop: "3px", marginLeft: "10px" }}>
                            <label className="label-report">Paciente</label>
                            <div style={{ marginTop: "-0.2rem" }}>
                              {" "}
                              <UserOutlined style={{ marginTop: "-0.3rem" }} />
                              <h4
                                style={{
                                  marginLeft: "2rem",
                                  marginTop: "-1.1rem",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {app.patient.fullName}
                              </h4>
                            </div>
                          </div>
                        </Card>
                      ))}

                    {appointmentsMonth.length <= 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 20,
                            paddingTop: "5rem",
                          }}
                        >
                          Sin datos que mostrar
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1rem",
                paddingTop: "0.5rem",
              }}
            >
              <Pagination
                defaultCurrent={pagination?.page}
                total={pagination?.total * 10}
                onChange={changePage}
              />
            </div>
          </>
        )}

        {state === "Mensual" && (
          <>
            <Row
              gutter={24}
              className="margin-left-right report-card-content"
              style={{ overflowY: "auto" }}
            >
              <Col xs={24} xl={24} style={{ padding: "1rem" }}>
                <div className="box-report">
                  {appointmentsWeekly.length > 0 &&
                    appointmentsWeekly.map((app) => (
                      <div className="contenedor" key={app._id}>
                        <div className="1">
                          <FileOutlined
                            style={{
                              paddingRight: "15px",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                          <span
                            style={{ fontFamily: "Avenir Next, sans-serif" }}
                            className="span-info"
                          >
                            {moment(app.appointment_date).format("DD/MM/YYYY")}-{" "}
                            {moment()
                              .startOf("day")
                              .add({
                                hours: app.appointment_time.split(":")[0],
                                minutes: app.appointment_time.split(":")[1],
                              })
                              .format("HH:mm")}{" "}
                            - Traumatología -{" "}
                          </span>
                          <span
                            style={{
                              fontFamily: "Avenir Next, sans-serif",
                              textTransform: "capitalize",
                            }}
                            className="span-info"
                          >
                            {app.patient.fullName}
                          </span>
                        </div>
                        <div className="2">
                          <LeftCircleFilled className="icon-circle-pago" />
                          <span className="pago">Pago realizado</span>
                        </div>
                      </div>
                    ))}

                  {appointmentsWeekly.length <= 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          paddingTop: "5rem",
                        }}
                      >
                        Sin datos que mostrar
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1rem",
                paddingTop: "0.5rem",
              }}
            >
              <Pagination
                defaultCurrent={pagination?.page}
                total={pagination?.total * 10}
                onChange={changePage}
              />
            </div>
          </>
        )}
      </Col>
      <Col></Col>

      {/* <div className="div-right">
          <CalendarSiderRigth />
        </div> */}
    </Row>
  );
}
