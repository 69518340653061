import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { validateEmail } from "../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import { LoginForm } from "../../components/auth/loginForm";
import { getSpecialtiesApi } from "../../api/specialities";
import { signUpApi } from "../../api/user";
import { ACCESS_TOKEN, REFRESH_TOKEN, USERNAME } from "../../utils/constants";
import { signInApi } from "../../api/user";
import { Form, Input, Row, Col, Tabs, Select, Button } from "antd";
import { signInWithGoogle, signInWithFacebook } from "../../services/firebase";
import "react-toastify/dist/ReactToastify.css";
import "./registerForm.css";
import UploadFiles from "../UploadFiles/UploadFiles";
import { createUploadFiles } from "../../api/uploadFiles.api";
import { getDocumentTypeByName } from "../../api/documentTypes.api";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import Modal from "../../components/Modal";
import ModalTermsConditions from "../ModalTermsConditions";

const { Option } = Select;

function RegisterForm(props) {
  const [filesSelected, setFilesSelected] = useState([]);
  const [fileMatricula, setFileMatricula] = useState([]);
  const [fileDocumentoIdentidad, setFileDocumentoIdentidad] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isVisibleLoading, setIsVisibleLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [tuition, setTuition] = useState(null);
  const [lastName, setLastName] = useState("");
  const [specialities, setSpecialities] = useState(null);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [stepNext, setStepNext] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isloading, setIsloading] = useState(false);
  const [specialitiesArr, setSpecialitiesArr] = useState([]);
  const [mobilePhone, setMobilePhone] = useState(null);
  const { state, setState, setStateUser, stateUser, keyNumber, setKeyNumber } =
    props;

  const items = [
    {
      key: '1',
      label: 'Paciente',
    },
    {
      key: '2',
      label: 'Profesional',
    },
  ];

  const [contentModal, setContentModal] = useState(null);
  const [title, setTitle] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [classNameModal, setClassNameModal] = useState("");

  async function login(email, password) {
    setIsloading(true);
    const inputs = {
      email,
      password,
    };
    const result = await signInApi(inputs);

    if (result.message) {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsloading(false);
    } else {
      const { accessToken, refreshToken, user } = result;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      localStorage.setItem(USERNAME, user.fullName);
      localStorage.setItem("USER_ID", user._id);
      localStorage.setItem("USER", JSON.stringify(user));

      //socket.emit("updateListConnection", { socketId: socket.id, userId: user._id });

      toast.success("Inicio de sesion exitoso", {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsloading(false);
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  }

  const registeruser = async () => {
    setIsVisibleLoading(true);

    const inputs = {
      email: email,
      password: password,
      fullName: name + " " + lastName,
      name: name,
      lastName: lastName,
      repeatPassword: repeatPassword,
    };
    await signUpApi(inputs).then((response) => {
      if (response.message) {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const { accessToken, refreshToken, user } = response.result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.setItem(USERNAME, user.fullName);
        localStorage.setItem("USER_ID", user._id);
        localStorage.setItem("USER", JSON.stringify(user));

        //socket.emit("updateListConnection", { socketId: socket.id, userId: user._id });

        toast.success("Registro exitoso", {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    });
    setEmail("");
    setPassword("");
    setRepeatPassword("");
    setName("");
    setLastName("");

    setIsVisibleLoading(false);
  };

  const registerDoctor = async () => {
    setIsVisibleLoading(true);

    const inputs = {
      email: email,
      password: password,
      fullName: name + " " + lastName,
      name: name,
      lastName: lastName,
      repeatPassword: repeatPassword,
      tuition: tuition,
      doctor: true,
      phone: phone || "",
      mobilePhone: mobilePhone,
      branchesOfMedicine: specialities,
    };

    await signUpApi(inputs).then(async (response) => {
      if (response.message) {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const user = response.result.user;
        var filesSelectedFinal = [];
        if (filesSelected?.length > 0) {
          // eslint-disable-next-line array-callback-return
          await getDocumentTypeByName("Titulo Universitario").then(
            (type) => {
              // eslint-disable-next-line array-callback-return
              filesSelected.map((file) => {
                file.user = user._id;
                file.documentType = type.documentType._id;
                filesSelectedFinal.push(file);
              });
            }
          );
        }

        if (fileDocumentoIdentidad?.length > 0) {
          // eslint-disable-next-line array-callback-return
          await getDocumentTypeByName("Documento de Identidad").then(
            (type) => {
              // eslint-disable-next-line array-callback-return
              fileDocumentoIdentidad.map((file) => {
                file.user = user._id;
                file.documentType = type.documentType._id;
                filesSelectedFinal.push(file);
              });
            }
          );
        }

        if (fileMatricula?.length > 0) {
          // eslint-disable-next-line array-callback-return
          await getDocumentTypeByName("Matrícula Médica").then((type) => {
            // eslint-disable-next-line array-callback-return
            fileMatricula.map((file) => {
              file.user = user._id;
              file.documentType = type.documentType._id;
              filesSelectedFinal.push(file);
            });
          });
        }

        let id = user._id;
        // let nameAdd = "opportunity";

        const dataBody = {
          id: id || null,
          user: id,
        };

        if (filesSelectedFinal.length > 0) {
          await createUploadFiles(filesSelectedFinal, dataBody).then(
            (response) => {
              if (response === "OK") {
                console.log("response", response);
              }
            }
          );
        }

        setEmail("");
        setPassword("");
        setRepeatPassword("");
        setName("");
        setTuition(null);
        setPhone(null);
        setMobilePhone(null);
        setSpecialities(null);
        setLastName(null);
        toast.success(
          "Registro exitoso, Aguarde la activacion por parte de VIVI",
          {
            position: "top-right",
            autoClose: 2800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    });

    setIsVisibleLoading(false);
  };

  const openModalTermsConditions = (isPatient) => {
    let valForm = false;

    if (isPatient) {
      if (!email || !password || !repeatPassword || !name || !lastName) {
        toast.error("Debe completar los campos obligatorios.", {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (!validateEmail(email)) {
          toast.error("El email no es correcto", {
            position: "top-right",
            autoClose: 2800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (password !== repeatPassword) {
            toast.error("Las contraseñas no son iguales", {
              position: "top-right",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            if (password.length < 8) {
              toast.error("La contraseña debe tener al menos 8 caracteres", {
                position: "top-right",
                autoClose: 2800,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            else {
              valForm = true;
            };
          }
        }
      }
    }
    else {
      if (
        !email ||
        !password ||
        !repeatPassword ||
        !name ||
        !mobilePhone ||
        !tuition
      ) {
        toast.error("Debe completar los campos obligatorios.", {
          position: "top-right",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (!validateEmail(email)) {
          toast.error("El email no es correcto", {
            position: "top-right",
            autoClose: 2800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (password !== repeatPassword) {
            toast.error("Las contraseñas no son iguales", {
              position: "top-right",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            if (password.length < 8) {
              toast.error("La contraseña debe tener al menos 8 caracteres", {
                position: "top-right",
                autoClose: 2800,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            else {
              valForm = true;
            }
          }
        }
      }
    }

    if (valForm) {
      setVisibleModal(true);
      setTitle("Términos y Condiciones");
      setClassNameModal("modal-choose-date ModalCalendar");
      setContentModal(
        <>
          <ModalTermsConditions acceptTermsConditions={(isPatient) ? registeruser : registerDoctor} isPatient={isPatient} ></ModalTermsConditions>
        </>
      );
    }
  };

  useEffect(
    () => {
      getSpecialtiesApi().then((response) => {
        const arr = [];
        if (response?.specialties) {
          const results = response.specialties;
          for (let i = 0; i < results.length; i++) {
            arr.push({
              ...results[i],
              label: results[i].title,
              imageUrl: results[i].imageUrl,

              departmentServices: [],
              newsPosts: [],
              images: [],
            });
          }
        }

        setSpecialitiesArr(ordenarLista(arr));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function ordenarLista(lista) {
    const comparar = (a, b) => {
      if (a["label"] < b["label"]) {
        return -1;
      }
      if (a["label"] > b["label"]) {
        return 1;
      }
      return 0;
    };

    const listaOrdenada = [...lista];

    listaOrdenada.sort(comparar);

    return listaOrdenada;
  }

  const theme = createTheme({
    typography: { fontSize: 16 },
    stepper: {
      iconColor: "green", // or logic to change color
    },
  });

  const steps = ["Datos personales", "Credenciales", "Aprobación"];

  const CustomisedConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#64CB8E",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#64CB8E",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: "10px",
      backgroundColor: "#c3c3c3",
      borderRadius: 1,
    },
  }));

  const specialtiesform = specialitiesArr || [];

  const onChangeTabs = (key) => {
    if (key === "1") {
      setStateUser("isPatient");
      setKeyNumber(key);
    } else {
      setStateUser("isMedical");
      setKeyNumber(key);
    }
  };
  return (
    <>
      <div className="div-container-registration-patient">
        <Tabs
          defaultActiveKey={keyNumber}
          items={items}
          onChange={onChangeTabs}
          hideAdd={true}
        />
        <h1 className="registro-name">Registro</h1>

        {stateUser === "isPatient" && (
          <>
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <Form.Item>
                  <label className="label-register">
                    Nombre<span className="required"> *</span>
                  </label>
                  <Input
                    className="register-form__input"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <Form.Item>
                  <label className="label-register">
                    Apellido<span className="required"> *</span>
                  </label>
                  <Input
                    className="register-form__input"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                <Form.Item>
                  <label className="label-register">
                    Correo electrónico<span className="required"> *</span>
                  </label>
                  <Input
                    className="register-form__input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value.trim());
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <Form.Item>
                  <label className="label-register">
                    Contraseña<span className="required"> *</span>
                  </label>
                  <Input.Password
                    className="register-form__input"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <Form.Item>
                  <label className="label-register">
                    Repetir Contraseña<span className="required"> *</span>
                  </label>
                  <Input.Password
                    className="register-form__input"
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value);
                    }}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1%"
              }}
            >
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#006AFF",
                  color: `white`,
                  borderRadius: "50px",
                  height: "40px"
                }}
                onClick={() => openModalTermsConditions(true)}
                loading={isloading}
                disabled={isloading}
                disableElevation
              >
                Registrarme
              </Button>
            </div>

            <View style={{
              alignItems: `center`,
              justifyContent: `space-evenly`,
              width: `100%`,
              marginTop: "8%",
            }}>
              <div className="div-container-btn-social-networks">
                {/* <button
                  className="login-with-facebook-btn"
                  onClick={signInWithFacebook}
                ></button> */}

                <button
                  className="login-with-google-btn"
                  onClick={() => signInWithGoogle(false)}
                ></button>

                {/* <button className="login-with-apple-btn"></button> */}
              </div>
            </View>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "3%",
              }}
            >
              <span
                style={{
                  paddingRight: 5,
                }}
              >
                ¿Ya tienes una cuenta?{" "}
              </span>

              <span
                onClick={() => {
                  setState("login");
                }}
                style={{ color: "blue", cursor: "pointer" }}
              >
                Iniciar Sesión
              </span>
            </div>
          </>
        )}

        {stateUser === "isMedical" && (
          // <div className="div-container-registration-medical">
          //   <h1 style={{ marginBottom: "2rem", fontWeight: "bold" }}>
          //     Registro
          //   </h1>
          <>
            <ThemeProvider theme={theme}>
              <Stepper
                activeStep={stepNext}
                alternativeLabel
                className="steperRegisterDoctor"
                connector={<CustomisedConnector />}
              >
                {steps.map((label) => (
                  <Step key={label} style={{ fontFamily: 'Avenir Next, sans-serif' }}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </ThemeProvider>

            {/* STEP 1  */}
            {stepNext === 0 && (
              <>
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Nombre<span className="required">*</span>
                      </label>
                      <Input
                        className="register-form__input"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Apellido<span className="required">*</span>
                      </label>
                      <Input
                        className="register-form__input"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Matricula<span className="required">*</span>
                      </label>
                      <Input
                        className="register-form__input"
                        value={tuition}
                        onChange={(e) => {
                          setTuition(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Seleccionar Especialidades
                      </label>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        value={specialities}
                        onChange={(e) => {
                          setSpecialities(e);
                        }}
                        style={{ marginTop: "6px" }}
                      >
                        {specialtiesform.map((option) => (
                          <Option value={option._id} key={option._id}>
                            {option.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">Teléfono</label>
                      <Input
                        className="register-form__input"
                        value={phone}
                        onChange={(e) => {
                          let inputPhone = e.target.value;
                          inputPhone = inputPhone.replace(/[^\d+()\- ]/g, '');
                          inputPhone = inputPhone.substring(0, 20);
                          setPhone(inputPhone);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Teléfono móvil<span className="required">*</span>
                      </label>
                      <Input
                        className="register-form__input"
                        value={mobilePhone}
                        onChange={(e) => {
                          let inputPhone = e.target.value;
                          inputPhone = inputPhone.replace(/[^\d+\- ]/g, '');
                          inputPhone = inputPhone.substring(0, 20);
                          setMobilePhone(inputPhone);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{
                      marginTop: 10,
                      width: "100%",
                      backgroundColor: "#006AFF",
                      height: "40px",
                      color: `white`,
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      setStepNext(stepNext + 1);
                    }}
                  >
                    Siguiente
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "3%",
                  }}
                >
                  <span
                    style={{
                      paddingRight: 5,
                    }}
                  >
                    ¿Ya tienes una cuenta?{" "}
                  </span>

                  <span
                    style={{ color: "#006AFF", cursor: "pointer" }}
                    onClick={() => {
                      setState("login");
                    }}
                  >
                    Inicia Sesión
                  </span>
                </div>
              </>
            )}
            {/* STEP 1  */}

            {/* STEP 2  */}
            {stepNext === 1 && (
              <>
                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                    xxl={{ span: 24 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Correo electrónico<span className="required">*</span>
                      </label>
                      <Input
                        className="register-form__input"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value.trim());
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Contraseña<span className="required">*</span>
                      </label>
                      <Input.Password
                        className="register-form__input"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Form.Item>
                      <label className="label-register">
                        Repetir Contraseña<span className="required">*</span>
                      </label>
                      <Input.Password
                        className="register-form__input"
                        value={repeatPassword}
                        onChange={(e) => {
                          setRepeatPassword(e.target.value);
                        }}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                    <Button
                      style={{
                        marginTop: 20,
                        width: "100%",
                        height: "40px",
                        border: "1px solid #006AFF",
                        backgroundColor: "#fff",
                        color: `#006AFF`,
                        borderRadius: "50px",
                      }}
                      onClick={() => {
                        setStepNext(stepNext - 1);
                      }}
                    >
                      Volver
                    </Button>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                    <Button
                      style={{
                        marginTop: 20,
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#006AFF",
                        color: `white`,
                        borderRadius: "50px",
                      }}
                      onClick={() => {
                        setStepNext(stepNext + 1);
                      }}
                    >
                      Siguiente
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            {/* STEP 2  */}

            {/* STEP 3  */}
            {stepNext === 2 && (
              <>
                <div>
                  <div
                    className="centerTextRegister"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10vh",
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "600",
                        marginBottom: "1.5rem",
                        fontSize: "16px",
                      }}
                    >
                      ¡Gracias por completar sus datos!
                    </h3>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontSize: "14px", textAlign: "center" }}>
                      Revisaremos la documentación y muy pronto le estaremos{" "}
                      <br></br> contactando vía e-mail para comunicar su
                      aprobación.
                    </p>
                  </div>
                </div>
                <Row
                  gutter={24}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <Col span={12}>
                    <Button
                      style={{
                        marginTop: 20,
                        width: "100%",
                        height: "40px",
                        border: "1px solid #006AFF",
                        backgroundColor: "#fff",
                        color: `#006AFF`,
                        borderRadius: "50px",
                      }}
                      onClick={() => {
                        setStepNext(stepNext - 1);
                      }}
                    >
                      Volver
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      style={{
                        marginTop: 20,
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#006AFF",
                        color: `white`,
                        borderRadius: "50px",
                      }}
                      disableElevation
                      onClick={() => openModalTermsConditions(false)}
                      loading={isloading}
                      disabled={isloading}
                    >
                      Aceptar
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {/* STEP 3  */}
          </>
        )}
      </div>
      {state === "login" && <LoginForm login={login} />}

      <Modal
        children={contentModal}
        title={title}
        visible={visibleModal}
        setIsVisible={setVisibleModal}
        className={classNameModal}
        closable={true}
      >
        {contentModal}
      </Modal>
    </>
  );
}
export default RegisterForm;

const styles = StyleSheet.create({
  formContainer: {
    flex: 1,
    marginTop: 30,
    width: `100%`,
    marginBottom: "4rem",
    height: "100vh",
  },
  inputForm: {
    // width: '100%',
    marginTop: 20,
    flex: 1,
  },
  iconRigth: {
    color: "#c1c1c1",
  },
  btnContainerRegister: {
    marginTop: 20,
    width: "95%",
  },
  btnRegister: {
    backgroundColor: "#2E78D3",
  },
});
