import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../api/auth";
import { activateUserApi, getUserApi } from "../../api/user";
import { useParams } from "react-router-dom";
import { getDocumentMasters } from "../../api/documentMasters.service";
import { Spin, notification, Button } from "antd";
import { useHistory } from "react-router-dom";

export default function ViewPerfil() {
  const token = getAccessTokenApi();
  const idData = useParams();
  const [user, setUser] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useHistory();
  useEffect(() => {
    if (idData) {
      setLoading(true);
      getUserApi(token, idData.id).then((user) => setUser(user.user));
      getDocumentMasters(token, "user", idData.id).then((resp) => {
        setDocuments(resp);

        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idData]);

  const activateUser = () => {
    activateUserApi(token, user._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });

        setTimeout(() => {
          navigate.goBack();
        }, 1500);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
          height: "100vh",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <div style={{ height: "100vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 60,
            alignItems: "center",
            fontSize: 30,
          }}
        >
          <span style={{ fontWeight: "bold", paddingRight: 15, fontSize: 30 }}>
            Nombre y Apellido:
          </span>{" "}
          {user?.fullName.replace(/\b\w/g, (l) => l.toUpperCase())}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
            alignItems: "center",
            fontSize: 30,
          }}
        >
          <span style={{ fontWeight: "bold", paddingRight: 15, fontSize: 30 }}>
            Email:
          </span>{" "}
          <a href={`mailto:${user?.email}`}>{user?.email}</a>
        </div>
        <br />

        {documents.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "flex-start",
              alignItems: "center",
              padding: "50px",
              border: "1px solid black",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {documents.map(
              (doc) =>
                doc.documentType?.code === "2" && (
                  <div>
                    <span
                      style={{
                        paddingRight: 5,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      {" "}
                      Titulo Universitario:{" "}
                    </span>
                    <a
                      href={doc.documentPath}
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: 20 }}
                    >
                      Descargar
                    </a>
                  </div>
                )
            )}

            {documents.map(
              (doc) =>
                doc.documentType?.code === "4" && (
                  <div>
                    <span
                      style={{
                        paddingRight: 5,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      {" "}
                      Documento de Identidad:{" "}
                    </span>
                    <a
                      href={doc.documentPath}
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: 20 }}
                    >
                      Descargar
                    </a>
                  </div>
                )
            )}

            {documents.map(
              (doc) =>
                doc.documentType?.code === "1" && (
                  <div>
                    <span
                      style={{
                        paddingRight: 5,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      {" "}
                      Matrícula Médica{" "}
                    </span>
                    <a
                      href={doc.documentPath}
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: 20 }}
                    >
                      Descargar
                    </a>
                  </div>
                )
            )}
            <div>
              <Button
                onClick={() => navigate.goBack()}
                type="default"
                style={{ marginTop: 30 }}
              >
                Volver
              </Button>
              <Button
                onClick={activateUser}
                type="primary"
                style={{ marginTop: 30, marginLeft: 20 }}
              >
                Activar Usuario
              </Button>
            </div>
          </div>
        ) : (
          <span>No tiene archivos adjuntos</span>
        )}
      </div>
    </>
  );
}
