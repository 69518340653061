import React, { useState, useEffect } from "react";
import { Button, Spin, Row, Col } from "antd";
import DataTable from "../../components/DataTable";
import Modal from "../../components/Modal";
import { getAccessTokenApi } from "../../api/auth";
import "./Requests.css";
import { getUsersPendingChangeApi } from "../../api/user";
import ModalApproveProfile from "../../components/ModalApproveProfile/ModalApproveProfile";
import ModalDeclineProfile from "../../components/ModalDeclineProfile/ModalDeclineProfile";

import { API_URL, apiVersion } from "../../api/config";
import axios from "axios";

export default function Requests(props) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [usersPendingChange, setUsersPendingChange] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = getAccessTokenApi();

  let columns = [
    {
      header: "Nombre",
      accessorKey: "fullName",
    },
    {
      header: "Especialidad",
      accessorKey: "branchesOfMedicine",
    },
    {
      header: "Matrícula",
      accessorKey: "tuition",
    },
    {
      header: "CI",
      accessorKey: "documentNumber",
    },
    {
      header: "Acciones",
      accessorKey: "_id",
      cell: (info) =>
        getButtonApprove({
          openModalApprove,
          _id: info.getValue(),
          usersPendingChange,
        }),
    },
  ];

  useEffect(() => {
    let DoctorsPendingChange = [];

    getUsersPendingChangeApi(token).then((response) => {
      const doctorFilter = response.users.filter(
        (user) => user.role === "doctor"
      );

      if (doctorFilter.length) {
        doctorFilter.forEach((element) => {
          element.documentNumber = element.documentNumber
            ? element.documentNumber.toString()
            : "sin DNI";
          element.tuition = element.tuition
            ? element.tuition.toString()
            : "sin matricula";
          element.branchesOfMedicine = element.branchesOfMedicine
            ? element.branchesOfMedicine[0].title
            : "sin especialidad";
          DoctorsPendingChange.push(element);
        });
        setUsersPendingChange(DoctorsPendingChange);
      }
    });
    setReloadUsers(false);
  }, [token, reloadUsers]);

  const openModalApprove = (user) => {
    setIsVisibleModal(true);
    setModalTitle("Revisión de cambios");
    setModalContent(
      <>
        <ModalApproveProfile
          user={user}
          changeStatus={changeStatus}
          openModalDecline={openModalDecline}
        ></ModalApproveProfile>
      </>
    );
  };

  const changeStatus = async (data) => {
    setLoading(true);
    const r = await axios
      .post(
        `${API_URL}${apiVersion}/change-status-user-doctor`,
        { user_id: data.user._id, status: data.status, comment: data.comment },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch(console.log);

    if (r?.data) {
      window.location.reload();
    }

    setLoading(false);
  };

  const openModalDecline = (user) => {
    setIsVisibleModal(true);
    setModalTitle("Rechazar cambios");
    setModalContent(
      <>
        <ModalDeclineProfile
          user={user.user}
          changeStatus={changeStatus}
        ></ModalDeclineProfile>
      </>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row gutter={24} className="row-without-margin row-main-admin">
        <Col xs={0} xl={1}></Col>
        <Col xs={24} xl={22} className="row-without-padding">
          <Row
            gutter={24}
            className="row-without-margin"
            style={{ alignItems: "center" }}
          >
            <Col
              span={24}
              className="row-without-padding"
              style={{ paddingTop: "3rem" }}
            >
              <Row gutter={24} className="row-without-margin">
                <Col span={24}>
                  <span className="span-text-title">Solicitudes</span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={24}
            className="row-without-margin"
            style={{ paddingTop: "2rem" }}
          >
            <Col xl={24} xs={24} className="row-without-padding">
              <DataTable
                columns={columns}
                data={usersPendingChange}
                classDataTable={"dt-requests"}
                showDownload={false}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} xl={1}></Col>
      </Row>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        closable={true}
      >
        {modalContent}
      </Modal>
    </>
  );
}

function getButtonApprove(props) {
  const { openModalApprove, _id, usersPendingChange } = props;

  let user = usersPendingChange.find((upc) => upc._id === _id);

  return (
    <>
      <Button onClick={() => openModalApprove(user)} className="btnAprobar">
        Ver
      </Button>
    </>
  );
}
