import React, { useState, useEffect } from "react";
import { Button, List } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./ModalCallEmergency.css";

export default function ModalCallEmergency({
  setVisibleModal,
  previewConfirmAppoiment,
  doctor,
  speciality,
  reasonWrite,
}) {
  const [reason, setReason] = useState(null);

  useEffect(() => {
    setReason(reasonWrite);
  }, [reasonWrite]);

  const history = useHistory();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "2rem",
          fontSize: "18px",
        }}
      >
        <span>Esta por ingresar a una videollamada instantanéa.</span>
        <span>Para ello indique el motivo de su consulta</span>
      </div>

      <div style={{ paddingTop: "5rem" }}>
        <label>Motivo de la consulta</label>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "1rem",
          }}
        >
          <TextArea
            row={5}
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            style={{ backgroundColor: "#ccc", height: 200 }}
          ></TextArea>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        <Button
          className="volver-btn"
          onClick={() => {
            setReason(null);
            setVisibleModal(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          style={{
            borderRadius: "50px",
            width: "151px",
            height: "40px",
            backgroundColor: reason === null ? "#B5B2B2" : "#4177C6",
            color: "#fff",
          }}
          disabled={reason ? false : true}
          onClick={() => {
            setVisibleModal(false);
            previewConfirmAppoiment(
              doctor,
              { date: moment() },
              moment().format("HH:mm"),
              reason,
              true
            );
            // history.push({
            //   pathname: "/pago",
            //   state: {
            //     objeto: {
            //       doctor: doctor,
            //       time: moment().format("HH:mm"),
            //       date: { date: moment() },
            //       reason: reason,
            //       specialty: speciality,
            //     },
            //   },
            // });
          }}
        >
          {" "}
          Siguiente
        </Button>
      </div>
    </div>
  );
}
