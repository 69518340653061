import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Text } from "react-native";
import Video from "twilio-video";
import Participant from "./participant";
import AppointmentsSide from "../../../src/components/appointmentsSide/appointmentsSide";
import { Statistic, notification, Row, Col } from "antd";
import { ExpandOutlined } from "@ant-design/icons";
import "./room.css";
import { VideocamOutlined } from "@material-ui/icons";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import { ClockCircleOutlined } from "@ant-design/icons";

const { Countdown } = Statistic;
const deadline = Date.now() + 300 * 60 * 60; // Dayjs is also OK

const Room = ({ roomName, token, handleLogout, dataVideo, appointments }) => {
  const [state, setState] = useState("appointments");
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [muteMicState, setMuteMicState] = useState(false);
  const [disableVideoState, setDisableVideoState] = useState(false);
  const user = JSON.parse(localStorage.getItem("USER"));
  const [classRemoteParticipants, setClassRemoteParticipants] = useState(
    "Noremote-participants"
  );

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const muteMic = () => {
    const state = !muteMicState;
    setMuteMicState(state);
    const micButton = document.querySelector(".micButton");
    if (state) {
      room?.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });
      micButton?.classList.add("muted");
    } else {
      room?.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
      micButton?.classList.remove("muted");
    }
  };

  const disableVideo = () => {
    const state = !disableVideoState;
    setDisableVideoState(state);
    const videoButton = document.querySelector(".videoButton");
    if (state) {
      room?.localParticipant.videoTracks.forEach((videoTrack) => {
        videoTrack.track.disable();
      });
      videoButton?.classList.add("muted");
    } else {
      room?.localParticipant.videoTracks.forEach((videoTrack) => {
        videoTrack.track.enable();
      });
      videoButton?.classList.remove("muted");
    }
  };

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      classNameUnique="remote-video"
    />
  ));

  const onFinish = () => {
    notification["success"]({
      message: "Finalizó el tiempo de consulta",
    });
  };

  const ocultar = () => {
    let dv = document.getElementById("div-video");
    let vp = document.getElementById("video-participant");

    if (!document.fullscreenElement) {
      vp.style.maxHeight = "max-content";
      dv.requestFullscreen();
      setClassRemoteParticipants("Noremote-participants-fullscreen");
    } else {
      vp.style.maxHeight = "450px";
      document.exitFullscreen();
      setClassRemoteParticipants("Noremote-participants");
    }
  };

  function calcularEdad(fecha) {
    const hoy = new Date();
    const cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    const m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return isNaN(edad) ? "" : `${edad} años, `;
  }

  return (
    <Row
      gutter={24}
      style={{ marginLeft: "0px", marginRight: "0px", height: "100%" }}
    >
      <Col xs={24} xl={18}>
        <div id="div-remote-participants" className="remote-participants">
          <div id="div-header" className="div-header-videocall">
            <span
              style={{
                fontFamily: "Roboto",
                fontSize: "x-large",
                fontWeight: "bold",
              }}
            >
              {dataVideo.emergency
                ? "Medicina de emergencia"
                : `Consulta ${dataVideo.specialty}`}
            </span>
            <div className="div-hour-videocall">
              <ClockCircleOutlined />
              <span
                style={{ marginLeft: "5%", color: "black", opacity: "50%" }}
              >
                {dataVideo.start.getHours().toString().padStart(2, "0")}:
                {dataVideo.start.getMinutes().toString().padStart(2, "0")} -{" "}
                {dataVideo.end.getHours().toString().padStart(2, "0")}:
                {dataVideo.end.getMinutes().toString().padStart(2, "0")}
              </span>
            </div>
          </div>

          <div id="div-video">
            {remoteParticipants.length > 0 ? (
              remoteParticipants
            ) : (
              <div
                id="remote-participants"
                className={classRemoteParticipants}
                style={{
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              ></div>
            )}
            <div className="div-name-participant">
              {appointments !== null
                ? `${dataVideo.patient.fullName}`
                : dataVideo.emergency
                ? dataVideo.doctor
                : dataVideo.doctor.fullName}
            </div>
            <div id="div-local-participant" className="local-participant">
              {room ? (
                <Participant
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                  local={true}
                  classNameUnique="local-video"
                />
              ) : (
                ""
              )}
            </div>

            <div
              id="div-bottom-barra-horizontal"
              className="bottom-barra-horizontal"
              style={{
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              <Row
                gutter={24}
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <Col
                  xs={24}
                  xl={15}
                  className={
                    classRemoteParticipants ===
                    "Noremote-participants-fullscreen"
                      ? "col-buttons-videocall centerButtonsFullScreem"
                      : "col-buttons-videocall"
                  }
                >
                  <Button
                    onClick={muteMic}
                    style={{
                      color: muteMicState ? "white" : "black",
                      backgroundColor: muteMicState ? "#FE4343" : "#FFFFFF",
                      opacity: disableVideoState ? "100%" : "85%",
                      minWidth: "0px",
                    }}
                    className="buttonVideoCall"
                  >
                    {muteMicState ? (
                      <MicOffOutlinedIcon style={{ fontSize: "xx-large" }} />
                    ) : (
                      <MicNoneOutlinedIcon style={{ fontSize: "xx-large" }} />
                    )}
                  </Button>
                  <Button
                    onClick={disableVideo}
                    style={{
                      color: disableVideoState ? "white" : "black",
                      backgroundColor: disableVideoState
                        ? "#FE4343"
                        : "#FFFFFF",
                      opacity: disableVideoState ? "100%" : "85%",
                      minWidth: "0px",
                    }}
                    className="buttonVideoCall"
                  >
                    {disableVideoState ? (
                      <VideocamOffOutlinedIcon
                        style={{ fontSize: "xx-large" }}
                      />
                    ) : (
                      <VideocamOutlined style={{ fontSize: "xx-large" }} />
                    )}
                  </Button>
                  <Button
                    className="buttonVideoCall"
                    style={{
                      backgroundColor: "#FE4343",
                      color: "white",
                      minWidth: "0px",
                    }}
                    onClick={handleLogout}
                  >
                    <CallEndOutlinedIcon style={{ fontSize: "xx-large" }} />
                  </Button>
                </Col>
                <Col xs={0} xl={9} style={{ textAlign: "end" }}>
                  <Button
                    style={{ fontSize: "xx-large", color: "#fff" }}
                    onClick={ocultar}
                  >
                    <ExpandOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div id="data-footer" className="div-data-footer">
            <Row gutter={24}>
              <Col xs={0} xl={12}>
                <div className="div-patient-videocall">
                  <div style={{ alignSelf: "center", paddingRight: "1rem" }}>
                    <PersonOutlinedIcon style={{ fontSize: "xx-large" }} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="title-lateral-light">
                      {appointments === null ? "Profesional" : "Paciente"}
                    </span>
                    <Text
                      className="title-lateral-bold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {appointments !== null
                        ? `${dataVideo.patient.fullName} (${calcularEdad(
                            dataVideo.patient?.date_of_birth
                          )}${dataVideo.patient?.gender || ""})`
                        : dataVideo.emergency
                        ? dataVideo.doctor
                        : dataVideo.doctor.fullName}
                    </Text>
                  </div>
                </div>
              </Col>
              <Col xs={0} xl={12}>
                <div style={{ display: appointments == null ? "" : "none" }}>
                  <div style={{ display: "flex", padding: "2% 0% 0% 1%" }}>
                    <div style={{ alignSelf: "center", paddingRight: "1rem" }}>
                      <MedicalServicesOutlinedIcon
                        style={{ fontSize: "xx-large" }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="title-lateral-light">Especialidad</span>
                      <Text className="title-lateral-bold">
                        {dataVideo.emergency
                          ? "Medicina de emergencia"
                          : dataVideo.specialty}
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={0} xl={6}>
        <div id="siderColumn" className="div-sider-column">
          <AppointmentsSide appointments={appointments} />
        </div>
      </Col>
    </Row>
  );
};

export default Room;
