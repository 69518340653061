import { Button, Select, Switch, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Calendar as ReactCalendar } from "react-calendar";
import moment from "moment";
import { getUserApi } from "../../api/user";
import { getAccessTokenApi } from "../../api/auth";
import axios from "axios";
import { API_URL, apiVersion } from "../../api/config";
import { toast, ToastContainer } from "react-toastify";

import "./AddBussinesHours.css";

const { Option } = Select;
export default function AddBussinesHours({
  visible,
  setIsVisible,
  setReload,
  reload,
}) {
  const [data, setData] = useState({
    days: [moment().format("YYYY-MM-DD")],
  });
  const [user, setUser] = useState(null);
  const [distributeSchedule, setDistributeSchedule] = useState(false);
  const [addHourAditional, setAddHourAditional] = useState(false);
  const [initialRangeOfTime, setInitialRangeOfTime] = useState({});
  const [initialRangeOfTimeAditional, setInitialRangeOfTimeAditional] =
    useState(null);

  const hoursInitial = [
    { value: "00:00" },
    { value: "00:30" },
    { value: "01:00" },
    { value: "01:30" },
    { value: "02:00" },
    { value: "02:30" },
    { value: "03:00" },
    { value: "03:30" },
    { value: "04:00" },
    { value: "04:30" },
    { value: "05:00" },
    { value: "05:30" },
    { value: "06:00" },
    { value: "06:30" },
    { value: "07:00" },
    { value: "07:30" },
    { value: "08:00" },
    { value: "08:30" },
    { value: "09:00" },
    { value: "09:30" },
    { value: "10:00" },
    { value: "10:30" },
    { value: "11:00" },
    { value: "11:30" },
    { value: "12:00" },
    { value: "12:30" },
    { value: "13:00" },
    { value: "13:30" },
    { value: "14:00" },
    { value: "14:30" },
    { value: "15:00" },
    { value: "15:30" },
    { value: "16:00" },
    { value: "16:30" },
    { value: "17:00" },
    { value: "17:30" },
    { value: "18:00" },
    { value: "18:30" },
    { value: "19:00" },
    { value: "19:30" },
    { value: "20:00" },
    { value: "20:30" },
    { value: "21:00" },
    { value: "21:30" },
    { value: "22:00" },
    { value: "22:30" },
    { value: "23:00" },
    { value: "23:30" },
  ];
  const token = getAccessTokenApi();
  useEffect(() => {
    const userID = localStorage.getItem("USER_ID");

    if (userID !== null) {
      getUserApi(token, userID).then((response) => {
        setUser(response.user);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData({ ...data, distributeSchedule: distributeSchedule });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributeSchedule]);

  const selectDate = (date) => {
    const dateSelected = [];
    date.map((d) => dateSelected.push(d));
    setData({ ...data, days: dateSelected });
  };

  useEffect(() => {
    if (initialRangeOfTime && !initialRangeOfTimeAditional) {
      setData({
        ...data,
        timeRanges: [initialRangeOfTime],
      });
    } else {
      setData({
        ...data,
        timeRanges: [initialRangeOfTime, initialRangeOfTimeAditional],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRangeOfTime, initialRangeOfTimeAditional]);

  function time(start, end, interval) {
    let s = start.split(":").map((e) => +e);
    let e = end.split(":").map((e) => +e);
    let res = [];
    let h_prev = s[0];
    let m_prev = s[1];
    while (h_prev < e[0] || m_prev < e[1]) {
      s[1] += interval;
      if (s[1] > 59) {
        s[0] += 1;
        s[1] %= 60;
      }
      if (s[0] < e[0] || s[1] <= e[1])
        res.push(h_prev + ":" + (m_prev < 10 ? "0" + m_prev : m_prev));
      h_prev = s[0];
      m_prev = s[1];
      // eslint-disable-next-line eqeqeq
      if (h_prev == e[0] && e[1] - m_prev < interval) break;
    }
    return res;
  }
  const agregateBussinesHours = async () => {
    // if (!data.specialty) {
    //   toast.error("Seleccione una especialidad", {
    //     position: "top-center",
    //     autoClose: 2800,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return;
    // }

    if (Object.entries(data.timeRanges[0]).length === 0) {
      toast.error("Seleccione un rango horario", {
        position: "top-center",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let fechas = [];
    const fechaUno = moment(data.days[0]);

    if (data.days[1]) {
      const fechaDos = moment(data.days[1]);
      const dias = fechaDos.diff(fechaUno, "days") + 1;

      for (let i = 0; i < dias; i++) {
        if (fechas.includes(fechaUno.format("YYYY-MM-DD"))) {
          let fechaAdd = fechaUno.add(1, "days");
          fechas.push(moment(fechaAdd).format("YYYY-MM-DD"));
        } else {
          fechas.push(fechaUno.format("YYYY-MM-DD"));
        }
      }
    } else {
      fechas.push(fechaUno.format("YYYY-MM-DD"));
    }

    let rangesTime = [];

    // eslint-disable-next-line array-callback-return
    data.timeRanges.map((timeRange) => {
      const rango = time(timeRange.start, timeRange.end, 30);
      rangesTime.push(...rango);
    });

    let dataCompleted = [];
    // eslint-disable-next-line array-callback-return

    if (distributeSchedule) {
      const startDate = fechas[0];
      const datesMonth = obtenerFechasMismoMes(startDate);
      let updateDates = [];

      datesMonth.forEach((dateMonth) => {
        const rangeDates = obtenerFechasIncrementales(dateMonth, fechas.length - 1);
        rangeDates.forEach((rangeDate) => {
          updateDates.push(rangeDate);
        });
      });

      fechas = updateDates;
    }

    let hoursByDoctorId = await validateHours();

    fechas.map((fecha) => {
      // eslint-disable-next-line array-callback-return
      rangesTime.map((time) => {
        let appoitment = {};

        let start = moment(fecha + " " + time);
        let end = moment(fecha + " " + time).add(20 * 60, "seconds");
        let filterHours = hoursByDoctorId.filter(hour => hour.start === new Date(start).toISOString());
        let valHour = dataCompleted.find(dc => dc.date === fecha && dc.time === time);

        if (filterHours.length === 0 && valHour === undefined) {
          appoitment.date = fecha;
          appoitment.year = moment(fecha).format("YYYY");
          appoitment.month = moment(fecha).format("MM");
          appoitment.specialty = data.specialty;
          appoitment.specialtyId = data.specialty;
          appoitment.doctorId = user._id;
          appoitment.time = time;
          appoitment.start = new Date(start);
          appoitment.end = new Date(end);
          appoitment.createdBy = user._id;

          dataCompleted.push(appoitment);
        }
      });
    });

    if (dataCompleted.length > 0) {
      const r = await axios
        .post(`${API_URL}${apiVersion}/create-businessHours`, dataCompleted, {
          headers: {
            Authorization: token,
          },
        })
        .catch(console.log);

      if (r?.data) {
        toast.success("Se agrego el horario de manera correcta", {
          position: "top-center",
          autoClose: 2800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          setIsVisible(false);
          setInitialRangeOfTime({});
          setInitialRangeOfTimeAditional({});
          setData({});
          setReload(!reload);
        }, []);
      }
    }
  };

  function obtenerFechasMismoMes(fechaInicial) {
    const fechasMismoMes = [];
    let fechaActual = new Date(fechaInicial);

    while (fechaActual.getMonth() === new Date(fechaInicial).getMonth()) {
      fechasMismoMes.push(fechaActual.toISOString().slice(0, 10));
      fechaActual.setDate(fechaActual.getDate() + 7);
    }

    return fechasMismoMes;
  }

  function obtenerFechasIncrementales(fechaInicial, numero) {
    const fechas = [];
    let fechaActual = new Date(fechaInicial);

    for (let i = 0; i <= numero; i++) {
      fechas.push(fechaActual.toISOString().slice(0, 10));
      fechaActual.setDate(fechaActual.getDate() + 1);
    }

    return fechas;
  }

  async function validateHours() {
    const userID = localStorage.getItem("USER_ID");
    let url = `${API_URL}${apiVersion}/getBussinesHoursByDoctor?id=${userID}`;

    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => {
        console.log(err);
      });
    if (r?.data) {
      return r.data.businessHours;
    }
  }

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} md={12} xl={12}>
          <ReactCalendar
            onChange={(e) => {
              selectDate(e);
            }}
            selectRange={true}
            allowPartialRange
            className="calendarModal"
            minDate={new Date()}
          />
        </Col>
        <Col xs={24} md={12} xl={12}>
          <div className="select-option-calendar">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: 20,
                alignSelf: "center",
                height: "82%"
              }}
            >
              <div>
                <div>
                  <span style={{ color: "black", paddingBottom: 5, fontWeight: "400", fontSize: "16px" }}>
                    Horario
                  </span>
                </div>
                <div style={{ marginTop: "8%" }}>
                  <Select
                    id="startHour"
                    placeholder="09:00"
                    options={hoursInitial}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e) =>
                      setInitialRangeOfTime({ ...initialRangeOfTime, start: e })
                    }
                  ></Select>
                  <span
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      color: "black",
                    }}
                  >
                    a
                  </span>
                  <Select
                    id="endHour"
                    placeholder="12:00"
                    options={hoursInitial}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e) =>
                      setInitialRangeOfTime({ ...initialRangeOfTime, end: e })
                    }
                  ></Select>
                </div>
                <div id="agregateHour"></div>
              </div>
              {addHourAditional === false ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#006AFF",
                      fontWeight: 400,
                      paddingTop: 10,
                    }}
                    onClick={() => setAddHourAditional(true)}
                  >
                    + Agregar otro horario
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ color: "#c3c3c3", paddingBottom: 3 }}>
                    Horario adicional
                  </span>
                  <div>
                    <Select
                      id="startHourAdditional"
                      placeholder="09:00"
                      options={hoursInitial}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.value ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) =>
                        setInitialRangeOfTimeAditional({
                          ...initialRangeOfTimeAditional,
                          start: e,
                        })
                      }
                    ></Select>
                    <span
                      style={{
                        paddingLeft: 5,
                        paddingRight: 5,
                        color: "#c3c3c3",
                      }}
                    >
                      a
                    </span>
                    <Select
                      id="endHourAdditional"
                      placeholder="12:00"
                      options={hoursInitial}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.value ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) =>
                        setInitialRangeOfTimeAditional({
                          ...initialRangeOfTimeAditional,
                          end: e,
                        })
                      }
                    ></Select>
                  </div>
                </div>
              )}
            </div>

            <div style={{ alignSelf: "center" }}>
              <Switch
                defaultChecked={distributeSchedule}
                onChange={() => {
                  setDistributeSchedule(!distributeSchedule);
                }}
              />
              <span style={{ fontWeight: "500", paddingLeft: 8 }}>
                Repetir cada semana
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={0} xl={12}></Col>
        <Col xs={24} xl={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10%",
              width: "100%",
              paddingBottom: "10%"
            }}
          >
            <Button
              type="ghost"
              onClick={() => setIsVisible(false)}
              className="vivi-modal-btn btn-back"
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={agregateBussinesHours}
              className="vivi-modal-btn btn-ok"
            >
              Aceptar
            </Button>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}
