import { Button, Input, notification, Row, Col } from "antd";
import React, { useState } from "react";
import { getAccessTokenApi } from "../../api/auth";
import { updateUserApi } from "../../api/user";
// import { DollarOutlined } from "@ant-design/icons";

export default function ChangeTariff({
  setVisibleModal,
  user,
  userActive,
  setReload,
  reload,
}) {
  const [price, setPrice] = useState(null);

  const cancel = () => {
    setPrice(null);
    setVisibleModal(false);
  };

  const clickChangeTariff = () => {
    const token = getAccessTokenApi();
    if (!price) {
      notification["error"]({
        message: "Debe completar el valor del precio",
      });
      return;
    }

    const userUpdate = {
      price: price.endsWith(".") ? `${price}00` : price,
    };

    updateUserApi(token, userUpdate, user.id).then((result) => {
      notification["success"]({
        message: result.message,
      });
      setPrice(null);
      setVisibleModal(false);
      setReload(!reload);
    });
  };

  return (
    <>
      <Row>
        <Col>
          <span
            style={{
              color: "#006AFF",
              paddingBottom: 3,
              fontWeight: 500,
              fontSize: "1.3rem",
            }}
          >
            Importe actual ${" "}
            {userActive.price === undefined ? "0" : `${userActive.price}`}
          </span>
        </Col>
      </Row>
      <Row gutter={24} style={{ paddingTop: "1rem" }}>
        <Col xl={12} xs={24}>
          <span style={{ color: "#c3c3c3", paddingBottom: 3 }}>
            Nuevo valor
          </span>
          <Input
            type="text"
            className="login-form__input"
            prefix="$"
            value={price}
            onChange={(e) => {
              let newValue = e.target.value;
              newValue = newValue.replace(/,/g, ".");
              const decimalCount = (newValue.match(/\./g) || []).length;
              if (decimalCount > 1) {
                return;
              }
              const decimalIndex = newValue.indexOf(".");
              if (decimalIndex !== -1) {
                newValue = newValue.slice(0, decimalIndex + 3);
              }
              setPrice(newValue);
            }}
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ paddingTop: "3rem", textAlign: "end" }}>
        <Col span={24}>
          <Button
            onClick={cancel}
            style={{ borderRadius: "20px !imoprtant" }}
            className="vivi-modal-btn btn-back"
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            style={{ borderRadius: "20px !important" }}
            className="vivi-modal-btn btn-ok"
            onClick={clickChangeTariff}
          >
            Actualizar
          </Button>
        </Col>
      </Row>
    </>
  );
}
