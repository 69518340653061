import React, { useEffect, useState } from "react";
import { getUserApi } from "../../../api/user";
import useAuth from "../../../hooks/useAuth";
import { getAccessTokenApi } from "../../../api/auth";
import { Row, Col, Form, Checkbox, Button } from "antd";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import moment from "moment";
import "./ViewProfile.css";

export default function ViewProfile(props) {
  const { setState, state } = props;
  const { user } = useAuth();
  const token = getAccessTokenApi();
  const [userActive, setUserActive] = useState(null);

  const getUserActive = () => {
    try {
      getUserApi(token, user.id).then((response) =>
        setUserActive(response.user)
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserActive();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card-profile-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 className="datos-personales textRoboto">Datos bancarios</h3>
        {state === "viewAccount" ? (
          ""
        ) : (
          <Button
            icon={
              <ModeEditOutlineOutlinedIcon
                style={{ marginRight: 5, fontSize: 18 }}
              />
            }
            className="edit-btn"
            onClick={() => setState("editAccount")}
          >
            Editar
          </Button>
        )}
      </div>

      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view textRoboto">Entidad bancaria</label>
            <span className="span-class">{userActive?.nameBank || "-"}</span>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Número de cuenta</label>
            <span className="span-class">
              {userActive?.accountNumber || "-"}
            </span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">ABA</label>
            <span className="span-class">{userActive?.aba || "-"}</span>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Form.Item>
            <label className="label-view">Código SWIFT</label>
            <span className="span-class">{userActive?.swift || "-"}</span>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
