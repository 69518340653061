import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { signInApi, signUpApi } from "../api/user";
import { ACCESS_TOKEN, REFRESH_TOKEN, USERNAME } from "../utils/constants";
import { notification } from "antd";

const firebaseConfig = {
  apiKey: "AIzaSyCGqB_YevMjW0OewV_-JckQrOXia7SM2QE",
  authDomain: "vivi-9c44d.firebaseapp.com",
  projectId: "vivi-9c44d",
  storageBucket: "vivi-9c44d.appspot.com",
  messagingSenderId: "623884979473",
  appId: "1:623884979473:web:44601b06d84e4ae9f890b0",
  measurementId: "G-X952ZL8RPJ",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const providerGoogle = new GoogleAuthProvider();

function getNameAndLastName(fullName = "") {
  const splitFullName = fullName.split(" ");
  let ret = ["", ""];
  if (splitFullName.length === 1)
    ret[0] = splitFullName[0];
  else {
    if (splitFullName.length === 2) {
      ret[0] = splitFullName[0];
      ret[1] = splitFullName[1];
    } else {
      if (splitFullName.length > 2) {
        ret[0] = `${splitFullName[0]} ${splitFullName[1]}`;
        for (let index = 2; index < splitFullName.length; index++) {
          ret[1] += `${splitFullName[index]}${(index !== splitFullName.length - 1) ? " " : ""}`//splitFullName[index];
        }
      }
    }
  }

  return ret;
}

export const signInWithGoogle = async (isLogin) => {
  await signInWithPopup(auth, providerGoogle)
    .then((result) => {
      const name_lastname = getNameAndLastName(result.user.displayName);
      const userData = {
        email: result.user.email,
        fullName: result.user.displayName,
        imageUrl: result.user.photoURL,
        name: name_lastname[0],
        lastName: name_lastname[1],
        mobilePhone: result.user.phoneNumber ?? "",
        google: true,
      };
      if (result.user) {
        if (isLogin) {
          return SignInWithApi(userData);
        }
        else {
          signUpApi(userData).then((res) => {
            return SignInWithApi(userData);
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signInWithApple = async () => {
  const providerApple = new OAuthProvider("apple.com");
  await signInWithPopup(auth, providerApple)
    .then((result) => {
      const userData = {
        email: result.user.email,
        fullName: result.user.displayName,
        imageUrl: result.user.photoURL,
        google: true,
      };
      if (result.user) {
        signUpApi(userData).then((res) => {
          SignInWithApi(userData);
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signInWithFacebook = async () => {
  const providerFacebook = new FacebookAuthProvider();

  await signInWithPopup(auth, providerFacebook)
    .then((resultFacebook) => {
      const name_lastname = getNameAndLastName(resultFacebook.user.displayName);
      const userData = {
        email: resultFacebook.user.email,
        fullName: resultFacebook.user.displayName,
        imageUrl: resultFacebook.user.photoURL,
        name: name_lastname[0],
        lastName: name_lastname[1],
        mobilePhone: resultFacebook.user.phoneNumber ?? "",
        google: true,
      };
      if (resultFacebook.user) {
        signUpApi(userData).then((res) => {
          SignInWithApi(userData);
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

function SignInWithApi(userData) {
  signInApi(userData).then((result) => {
    if (result.user) {
      localStorage.setItem(ACCESS_TOKEN, result.accessToken);
      localStorage.setItem(REFRESH_TOKEN, result.refreshToken);
      localStorage.setItem(USERNAME, result.user.fullName);
      localStorage.setItem("USER_ID", result.user._id);
      localStorage.setItem("USER", JSON.stringify(result.user));
      window.location.href = "/";
    }
    else {
      notification["error"]({
        message: "Usuario no encontrado. Debe registrarse primero.",
      });
    }
  });
}