import { API_URL, apiVersion } from "./config";
import { ACCESS_TOKEN, REFRESH_TOKEN, EMAIL, USERNAME, AVATAR, USER, USERID } from "../utils/constants";
import jwtDecode from "jwt-decode";

export function getAccessTokenApi() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken || accessToken === "null") {
    return null;
  }

  return willExpireToken(accessToken) ? null : accessToken;
}

export function getRefreshTokenApi() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (!refreshToken || refreshToken === "null") {
    return null;
  }

  return willExpireToken(refreshToken) ? null : refreshToken;
}

export function refreshAccessTokenApi(refreshToken) {
  const url = `${API_URL}${apiVersion}/refresh-access-token`;
  const bodyObj = {
    refreshToken: refreshToken,
  };
  const params = {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify(bodyObj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  fetch(url, params)
    .then((response) => {
      if (response.status !== 200) {
        return null;
      }
      return response.json();
    })
    .then((result) => {
      if (!result) {
        logout();
      } else {
        const { accessToken, refreshToken } = result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
      }
    });
}

export function getUser(id) {
  const url = `${API_URL}${apiVersion}/user/${id}`;
  const params = {
    method: "GET",
    mode: 'cors',
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function logout() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(EMAIL);
  localStorage.removeItem(AVATAR);
  localStorage.removeItem(USERID);
  localStorage.removeItem(USER);
}

export function forgotPasswordAPI(email) {
  const url = `${API_URL}${apiVersion}/forgot-password`;
  const bodyObj = {
    email: email,
  };
  const params = {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify(bodyObj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status !== 200) {
        return false;
      } else {
        return true;
      }
    })
}

export function validateTokenAPI(token) {
  const url = `${API_URL}${apiVersion}/validate-token`;
  const bodyObj = {
    token: token,
  };
  const params = {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify(bodyObj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

function willExpireToken(token) {
  const seconds = 60;
  const metaToken = jwtDecode(token);
  const { exp } = metaToken;
  const now = (Date.now() + seconds) / 1000;
  return now > exp;
}