import React, { useEffect, useState } from "react";
import "./Notifications.css";
import { Col, Row, Tabs, Card, Spin, Switch, Divider } from "antd";
import axios from "axios";
import { API_URL, apiVersion } from "../../api/config";
import { getAccessTokenApi } from "../../api/auth";
import moment from "moment-timezone";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "../../components/Modal";

import DataTable from "../../components/DataTable";
import { useNotification } from "../../providers/CountNotifications";

const Notifications = () => {
  const token = getAccessTokenApi();
  const [notifications, setNotifications] = useState([]);
  const [notificationsHistory, setNotificationsHistory] = useState([]);
  const user = JSON.parse(localStorage.getItem("USER"));
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [title, setTitle] = useState(null);
  const [emails, setEmails] = useState([]);
  const [notis, setNotis] = useState([]);
  const { setReload, reload } = useNotification();
  let columns = [
    {
      header: "Fecha",
      accessorKey: "created",
    },
    {
      header: "Título",
      accessorKey: "title",
    },
    {
      header: "Descripción",
      accessorKey: "description",
    },
  ];

  const upcomingNotifications = () => {
    return (
      <Row>
        <Col xl={24} xs={24} style={{ padding: "1rem" }}>
          {/* <div className="container-turnos"> */}
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              tab={<span className="tabPaneFont">Nuevas</span>}
              key="1"
              className="tabPaneFont"
            >
              <DataTable
                columns={columns}
                data={notifications}
                classDataTable={"dt-notifications"}
                showDownload={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="tabPaneFont">Histórico</span>}
              key="2"
            >
              <DataTable
                columns={columns}
                data={notificationsHistory}
                classDataTable={"dt-notifications textRoboto"}
                showDownload={false}
              />
            </Tabs.TabPane>
          </Tabs>
          {/* </div> */}
        </Col>
      </Row>
    );
  };

  async function getNotifications() {
    setLoading(true);
    const dataNotifications = await axios
      .get(
        `${API_URL}${apiVersion}/notifications-by-userid?user_id=${user._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => console.error(err));

    if (dataNotifications?.data) {
      console.log("notifications Data", dataNotifications.data);
      let notis = [];
      let notisHistory = [];
      let arraySinDuplicados = [];

      //mchuquimia (solución provisoria hasta encontrar el xq de los duplicados a la hora de guardar las notificaciones)
      dataNotifications.data.notifications.forEach((obj, indice) => {
        const fechaFormateada = moment(obj.created).format("DD/MM/YYYY HH:mm");

        // Verificar si la fecha formateada ya existe en el nuevo array
        const existe = arraySinDuplicados.some((o) => {
          const fechaObj = moment(o.created).format("DD/MM/YYYY HH:mm");
          return (
            fechaObj === fechaFormateada && obj.description === o.description
          );
        });

        if (!existe) {
          arraySinDuplicados.push(obj);
        }
      });

      arraySinDuplicados.forEach((element) => {
        element.created = moment(element.created).format("DD/MM/YYYY HH:mm");
        if (element.seen) {
          notisHistory.push(element);
        } else {
          notis.push(element);
          updateNotificationSeen(element);
        }
      });

      setNotifications(notis);
      setNotificationsHistory(notisHistory);
      // setReload(!reload);
      // let eleCountNotifications = document.getElementById("countNotifications");
      // eleCountNotifications.innerHTML = `Notificaciones (${notis.length})`;
    }
  }

  async function getConfigurationsNotifications() {
    const dataConfigurations = await axios
      .post(
        `${API_URL}${apiVersion}/get-configuration-notification`,
        {
          user_id: user._id,
          role: user.role,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => console.error(err));

    if (dataConfigurations?.data) {
      const configurationsNotif = dataConfigurations.data.configurationsNotif;
      const dataEmails = configurationsNotif.filter(
        (config) => config.configuration.type === "email"
      );
      setEmails(dataEmails);
      const dataNotis = configurationsNotif.filter(
        (config) => config.configuration.type === "notif"
      );
      setNotis(dataNotis);
    }

    setLoading(false);
  }

  async function updateNotificationSeen(noti) {
    let notiUpdate = noti;

    await axios
      .put(
        `${API_URL}${apiVersion}/update-notification-seen/${notiUpdate._id}`,
        notiUpdate,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => console.error(err));

    setReload(!reload);
  }

  async function onChangeConfig(conf, val) {
    conf.active = val;
    const updateConfig = await axios
      .put(
        `${API_URL}${apiVersion}/update-configuration-notification/${conf._id}`,
        conf,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => console.error(err));

    console.log("update config", updateConfig);
  }

  useEffect(() => {
    const ac = new AbortController();
    getNotifications();
    getConfigurationsNotifications();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const _renderItemNotification = (item) => {
  //   return (
  //     <Card key={item._id}>
  //       <Row
  //         gutter={24}
  //         style={{
  //           marginLeft: "0px",
  //           marginRight: "0px",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Col xs={24} xl={6}>
  //           <span
  //             className="proxConsulta"
  //             style={{ fontSize: 20, fontWeight: "bold" }}
  //           >
  //             {item.title}
  //           </span>
  //         </Col>
  //         <Col xs={24} xl={6}>
  //           <span> {moment(item.created).format("DD/MM/YYYY HH:mm")}</span>
  //         </Col>
  //         <Col xs={24} xl={12}>
  //           <span style={{ marginRight: "1rem", fontSize: "15px" }}>
  //             {item.description}
  //           </span>
  //         </Col>
  //       </Row>
  //     </Card>
  //   );
  // };

  const _renderConfig = (conf) => {
    return (
      <Card style={{ marginTop: "1rem", border: "none" }}>
        <Row
          gutter={24}
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            alignItems: "center",
          }}
        >
          <Col xs={12} xl={20}>
            <span style={{ fontSize: "1.2rem" }} className="textRoboto">
              {conf.configuration.description}
            </span>
          </Col>
          <Col xs={12} xl={4} style={{ textAlign: "center" }}>
            <Switch
              defaultChecked={conf.active}
              onChange={(e) => onChangeConfig(conf, e)}
            />
          </Col>
        </Row>
      </Card>
    );
  };

  const openModalSettingsNotifications = () => {
    setVisibleModal(true);
    setTitle("Configuración de notificaciones");
    setContentModal(
      <>
        <Row>
          <Col xl={24} xs={24} style={{ padding: "1rem" }}>
            {emails.length > 0 && (
              <div>
                <h4 style={{ fontWeight: "bold" }} className="textRoboto">
                  Correo electrónico
                </h4>
                {emails.map((email) => _renderConfig(email))}
              </div>
            )}
            <Divider />
            {notis.length > 0 && (
              <>
                <h4 style={{ fontWeight: "bold" }} className="textRoboto">
                  Notificaciones push
                </h4>
                {notis.map((noti) => _renderConfig(noti))}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col xl={12} xs={12} style={{ padding: "3rem 0rem 2rem 1rem" }}>
          <span
            style={{ marginTop: "3rem" }}
            className="span-text-title"
          >
            Mis notificaciones
          </span>
        </Col>
        <Col
          xl={12}
          xs={12}
          // style={{
          //   padding: "3rem 1rem 2rem 1rem",
          //   alignSelf: "center",
          //   textAlign: "end",
          // }}
        >
          {/* <Button onClick={() => openModalSettingsNotifications()}> */}
          <div
            style={{
              padding: "3rem 3rem 2rem 1rem",
              alignSelf: "center",
              textAlign: "end",
              cursor: "pointer",
            }}
          >
            <SettingsIcon onClick={() => openModalSettingsNotifications()} />
          </div>
          {/* </Button> */}
        </Col>
      </Row>
      {upcomingNotifications()}

      <Modal
        children={contentModal}
        title={title}
        visible={visibleModal}
        setIsVisible={setVisibleModal}
        closable={true}
      />
    </>
  );
};

export default Notifications;
