import React, { useState } from "react";
import "./ContactUs.css";
import { Col, Row, Spin, Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { API_URL, apiVersion } from "../../api/config";
import { getAccessTokenApi } from "../../api/auth";
import TextArea from "antd/lib/input/TextArea";
import UploadFiles from "../../components/UploadFiles/UploadFiles";
import { createUploadFile } from "../../api/uploadFiles.api";

const ContactUs = () => {
  const token = getAccessTokenApi();
  const user = JSON.parse(localStorage.getItem("USER"));
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);

  const sendReport = async () => {
    setLoading(true);

    if (!description) {
      toast.error("La descripción es obligatorio.", {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const operation_system = navigator.userAgentData.platform;
      const navegador = navigator.userAgent;
      const device = navigator.userAgentData.mobile
        ? "Dispositivo móvil"
        : "Computadora de escritorio";
      let latitud = "";
      let longitud = "";

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            latitud = position.coords.latitude;
            longitud = position.coords.longitude;
          },
          (error) => {
            console.error("Error al obtener la ubicación:", error.message);
          }
        );
      }

      let strLinks = "";

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          const fileResult = await createUploadFile(file.file);
          if (fileResult.state === "ok") {
            strLinks += `${fileResult.url.split("?AWSAccessKeyId")[0]};`;
          }
        });

        await Promise.all(uploadPromises);
      }

      const result = await axios.post(
        `${API_URL}${apiVersion}/contact-us`,
        {
          operation_system,
          navegador,
          device,
          latitud,
          longitud,
          description,
          strLinks,
          user_id: user._id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success(result.message, {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setDescription("");
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Col xl={4} xs={24}></Col>
        <Col
          xl={16}
          xs={24}
          style={{ paddingTop: "3rem" }}
          className="row-without-padding"
        >
          <Row
            gutter={24}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              paddingBottom: "2rem",
            }}
          >
            <Col xl={24} xs={24}>
              <span className="span-text-title">Soporte</span>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              paddingBottom: "2rem",
            }}
          >
            <Col xl={24} xs={24}>
              <span
                style={{ fontSize: "1.3rem", fontFamily: "Roboto" }}
                className="textRoboto"
              >
                Mediante el siguiente formulario puede usted reportar al equipo
                de Vivi una necesidad o falla del sistema para que pueda ser
                atendido por nuestros especialistas. Para hacerlo, por favor
                describa con el mayor detalle posible lo que desea comunicarnos
                y si es posible adjuntar una imágen que haga de evidencia y nos
                ayude a comprender mejor su necesidad.
              </span>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }}>
            <Col xl={24} xs={24} className="padding-contact-us">
              <label className="label-form-contactus textRoboto">
                Descripción
              </label>
              <TextArea
                rows={8}
                className="register-form__input textarea-input"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              ></TextArea>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }}>
            <Col xl={24} xs={24} className="padding-contact-us">
              <UploadFiles
                setFileSelected={setFiles}
                strClass="insertFile"
                strStyle="#c3c3c3"
              />
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <Col span={24} style={{ textAlign: "end" }}>
              <Button onClick={sendReport} className="btn-enviar">
                Enviar
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xl={4} xs={0}></Col>
      </Row>

      <ToastContainer />
    </>
  );
};

export default ContactUs;
