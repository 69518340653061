import "animate.css";
import "./Home.css";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Dimensions, FlatList } from "react-native";
import { getSpecialtiesApi } from "../api/specialities";
import { Spin, Form, Select, Button, Row, Col } from "antd";
import useAuth from "../hooks/useAuth";
import { Redirect } from "react-router-dom";
import Calendar from "./Calendar";
import { getUserApi, getBrancOfMedicine } from "../api/user";
import { getAccessTokenApi } from "../api/auth";
import CardSelectDoctor from "../components/CardSelectDoctor";
import CalendarReservationPatient from "../components/CalendarReservationPatient";
import { DownOutlined } from "@ant-design/icons";
import Modal from "../components/Modal";
import moment from "moment";
import ModalCallEmergency from "../components/ModalCallEmergency/ModalCallEmergency";

const { Option } = Select;

const CustomSuffix = ({ onClick }) => {
  return (
    <span onClick={onClick} style={{ cursor: "pointer", paddingRight: "10px" }}>
      <DownOutlined style={{ color: "#006AFF", fontSize: 18 }} />
    </span>
  );
};

const Home = (props) => {
  const [userActive, setUser] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [loading, setloading] = useState(false);
  const { user, isLoading } = useAuth();
  const token = getAccessTokenApi();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectSpecilty, setSelectSpecialty] = useState(null);
  const [doctorsActive, setDoctorsActive] = useState([]);
  const [contentModal, setContentModal] = useState(null);
  const [title, setTitle] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const history = useHistory();
  const [classNameModal, setClassNameModal] = useState("");
  const [specialty, setSpecialty] = useState("");

  const [open, setOpen] = useState(false);

  const handleSuffixClick = () => {
    setOpen(!open);
  };

  const onChange = (id) => {
    try {
      getBrancOfMedicine(token, id).then((response) => {
        if (response?.users) {
          setDoctorsActive(response.users);
        }
      });
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  function getSpecialties() {
    const arr = [];
    setloading(true);
    try {
      getSpecialtiesApi().then((response) => {
        setloading(false);
        if (response?.specialties) {
          const results = response.specialties;
          for (let i = 0; i < results.length; i++) {
            arr.push({
              ...results[i],
              label: results[i].title,
              imageUrl: results[i].imageUrl,

              departmentServices: [],
              newsPosts: [],
              images: [],
            });
            setSpecialties(_.uniqBy(ordenarLista(arr), "label"));
          }

          const specialty = arr.find((f) => f.title === "Medicina general");
          if (specialty) {
            onChange(specialty._id);
            setSelectSpecialty(specialty);
            setSpecialty(specialty.label);
          }
        }
      });
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  }

  function ordenarLista(lista) {
    const comparar = (a, b) => {
      if (a["label"] < b["label"]) {
        return -1;
      }
      if (a["label"] > b["label"]) {
        return 1;
      }
      return 0;
    };

    const listaOrdenada = [...lista];

    listaOrdenada.sort(comparar);

    return listaOrdenada;
  }

  useEffect(() => {
    const ac = new AbortController();

    if (typeof window !== "undefined") {
      setloading(true);
      const idUser = localStorage.getItem("USER_ID");

      getUserApi(token, idUser).then((user) => {
        setloading(false);
        setUser(user.user);

        if (user.user.role === "admin") {
          history.push({
            pathname: "/users",
          });
        }
      });
    }

    getSpecialties();

    return () => {
      ac.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);
  }, [isSmallScreen]);

  const openModalCalendarReservation = (
    userActive,
    previewConfirmAppoiment,
    date,
    time,
    reason
  ) => {
    setVisibleModal(true);
    setTitle("Disponibilidad");
    setClassNameModal("modal-choose-date ModalCalendar");
    setContentModal(
      <>
        <CalendarReservationPatient
          doctor={userActive}
          setVisibleModal={setVisibleModal}
          previewConfirmAppoiment={previewConfirmAppoiment}
          timeSelected={time}
          dateSelected={date}
          reasonWrite={reason}
        />
      </>
    );
  };

  const previewConfirmAppoiment = (doctor, date, time, reason, emergency) => {
    setVisibleModal(true);
    setTitle("Confirma tu consulta");
    setClassNameModal("modal-confirm-date");
    setContentModal(
      <>
        <div style={{ padding: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: 20,
                width: "50%",
              }}
            >
              <span className="span-modal">Especialidad</span>
              <span className="input-modal">{selectSpecilty.title}</span>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <span className="span-modal">Profesional de la salud</span>
              <span
                className="input-modal"
                style={{ textTransform: "capitalize" }}
              >
                {doctor.fullName}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingTop: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: 20,
                width: "50%",
              }}
            >
              <span className="span-modal">Fecha</span>
              <span className="input-modal">
                {moment(date.date).format("DD/MM/YYYY")}
              </span>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <span className="span-modal">Hora</span>
              <span className="input-modal">{time}</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              marginTop: "5%",
            }}
          >
            <span style={{ fontSize: 18, fontWeight: "500" }}>
              Costo: $ {doctor.price}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <span
              className="span-modal"
              style={{
                paddingBottom: "10px",
              }}
            >
              Motivo de la consulta
            </span>
            <div
              style={{
                width: "100%",
                padding: "1rem",
                border: "1px solid #7C7C81",
                borderRadius: "10px",
              }}
            >
              <p style={{ color: "#7C7C81" }}>{reason || "Sin motivo"}</p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              paddingTop: "5%",
            }}
          >
            <Button
              onClick={() =>
                emergency
                  ? openModalReasonCallEmergency(
                      doctor,
                      previewConfirmAppoiment,
                      date,
                      time,
                      reason
                    )
                  : openModalCalendarReservation(
                      doctor,
                      previewConfirmAppoiment,
                      date,
                      time,
                      reason
                    )
              }
              className="vivi-modal-btn btn-back"
            >
              Volver
            </Button>
            <Button
              onClick={() => {
                setVisibleModal(false);
                history.push({
                  pathname: "/pago",
                  state: {
                    objeto: {
                      doctor: doctor,
                      time: time,
                      date: date,
                      reason: reason,
                      specialty: selectSpecilty,
                    },
                  },
                });
              }}
              className="vivi-modal-btn btn-ok"
              style={{
                width: "151px!important",
                height: "40px!important",
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </>
    );
  };

  const openModalReasonCallEmergency = (
    userActive,
    previewConfirmAppoiment,
    date,
    time,
    reason
  ) => {
    setVisibleModal(true);
    setTitle("");
    setContentModal(
      <>
        <ModalCallEmergency
          doctor={userActive}
          setVisibleModal={setVisibleModal}
          speciality={selectSpecilty}
          previewConfirmAppoiment={previewConfirmAppoiment}
          openModalReasonCallEmergency={openModalReasonCallEmergency}
          reasonWrite={reason}
        />
      </>
    );
  };

  if (!user && !isLoading) {
    return (
      <>
        <Redirect to="/landing" />
      </>
    );
  }

  const dimensions = Dimensions.get("window");
  if (!dimensions.width) return null;
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!userActive) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  //to do: revisar despues de relevamiento que hacer con la validación de userActive !== null
  return (
    <>
      {userActive.role === "doctor" && <Calendar />}

      {userActive.role === "patient" && (
        <>
          <Row gutter={24} className="row-without-margin">
            <Col span={24} className="row-without-padding container-home">
              <div style={{ textAlign: "center" }}>
                <span className="text-ppal-page">¡Encuentra al</span>
                <span
                  className="text-ppal-page"
                  style={{ color: "#FCCF47", marginLeft: "8px" }}
                >
                  Profesional de la Salud
                </span>
                <br></br>
                <span className="text-ppal-page">que estás buscando!</span>
              </div>
            </Col>
          </Row>

          <Row gutter={24} className="row-without-margin">
            <Col
              span={24}
              className="row-without-padding container-header-speciality"
              style={{ marginTop: "-1.5rem" }}
            >
              {/* Dropdown Selecionar Especialidades */}
              <div className="div-dropdown">
                <Form.Item className="search-btn-home">
                  <Select
                    showSearch
                    className="selectEspecialities"
                    optionFilterProp="children"
                    open={open}
                    onDropdownVisibleChange={setOpen}
                    placeholder="Especialidad"
                    dropdownMatchSelectWidth={false}
                    style={{
                      height: "5vh",
                      minHeight: "50px",

                      zIndex: 100,
                    }}
                    placel
                    suffixIcon={<CustomSuffix onClick={handleSuffixClick} />}
                    bordered={false}
                    size="large"
                    onSelect={(e, a) => {
                      onChange(e);
                      setSelectSpecialty(a.data);
                      setSpecialty(a.data.label);
                    }}
                    value={specialty}
                  >
                    {specialties.map((specialtie) => (
                      <Option
                        key={specialtie._id}
                        value={specialtie._id}
                        data={specialtie}
                      >
                        {specialtie.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={24} className="row-without-margin">
            <Col span={24} className="row-without-padding">
              {/*  Card Medicos Selecionados */}
              {doctorsActive.length > 0 ? (
                <FlatList
                  data={doctorsActive}
                  renderItem={({ item }) => (
                    <div className="containerCardsDoctors">
                      <CardSelectDoctor
                        userActive={item}
                        speciality={selectSpecilty}
                        openModalCalendarReservation={
                          openModalCalendarReservation
                        }
                        previewConfirmAppoiment={previewConfirmAppoiment}
                        openModalReasonCallEmergency={
                          openModalReasonCallEmergency
                        }
                      />
                    </div>
                  )}
                  keyExtractor={(item) => item._id}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      textAlign: "center",
                    }}
                  >
                    No hay profesionales de la salud disponibles para la
                    especialidad seleccionada
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}

      {userActive.role === "admin" && (
        <div style={{ height: "100vh" }}>
          <span> Sos Administrador</span>
        </div>
      )}
      <Modal
        children={contentModal}
        title={title}
        visible={visibleModal}
        setIsVisible={setVisibleModal}
        className={classNameModal}
        closable={true}
      />
    </>
  );
};

export default Home;
