import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./config/routes";
import AuthProvider from "./providers/AuthProvider";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-tabs/style/react-tabs.css";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { connectSocket } from "./services/socket";
import { NotificationProvider } from "./providers/CountNotifications";

function App() {
  connectSocket();

  return (
    <AuthProvider>
      <NotificationProvider>
        <Router>
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </Router>
      </NotificationProvider>
    </AuthProvider>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component routes={route.routes} {...props} />}
    />
  );
}

export default App;
