import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL, apiVersion } from "../../api/config";
import { getAccessTokenApi } from "../../api/auth";
import { Spin } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const DownloadIcs = () => {
    const { appointmentid } = useParams();
    const token = getAccessTokenApi();
    const user = JSON.parse(localStorage.getItem("USER"));
    const [loading, setLoading] = useState(false);

    async function downloadIcs() {
        setLoading(true);
        const ics = await axios
            .get(`${API_URL}${apiVersion}/get-file-ics?appointmentid=${appointmentid}`, {
                headers: {
                    Authorization: token,
                },
            })
            .catch((err) => console.error(err));

        if (ics?.data) {
            const link = document.createElement('a');
            link.href = `data:text/plain;base64,${ics.data}`;
            link.download = `${appointmentid}.ics`;
            link.click();
        }

        setLoading(false);
    };

    useEffect(() => {
        const ac = new AbortController();
        downloadIcs();
        return () => ac.abort();
    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (<></>);
}

export default DownloadIcs;