import React, { useState } from "react";
import Calendar from "../../components/calendar/index";
import { useLocation } from "react-router-dom";
import "./Calendar.css";

export default function Calendario() {
  const location = useLocation();
  const emergencyData = location?.emergency;
  const [twilioHandler, setTwilioHandler] = useState({
    isAudioEnabled: true,
    status: `disconnected`,
    isLoading: false,
    token: null,
  });

  return (
    <>
      <Calendar
        setTwilioHandler={setTwilioHandler}
        twilioHandler={twilioHandler}
        emergency={emergencyData}
      />
    </>
  );
}
