import { Button, Input, notification } from "antd";
import React, { useState } from "react";
import { getAccessTokenApi } from "../../api/auth";
import { updateUserApi } from "../../api/user";
import Divider from "../divider";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import "./ChangePassword.css";

export default function ChangePassword({ setVisibleModal, user }) {
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [repeatNewPassword, setRepeatNewPassword] = useState(null);

  const cancel = () => {
    setCurrentPassword(null);
    setNewPassword(null);
    setRepeatNewPassword(null);
    setVisibleModal(false);
  };

  const changePassword = () => {
    const token = getAccessTokenApi();
    if (!currentPassword || !newPassword || !repeatNewPassword) {
      notification["error"]({
        message: "Debe completar todos los campos requeridos ",
      });
      return;
    }
    if (newPassword !== repeatNewPassword) {
      notification["error"]({
        message: "Las contraseñas no coinciden",
      });
      return;
    }

    if (newPassword.length < 8) {
      notification["error"]({
        message: "La contraseña debe tener al menos 8 caracteres",
      });
      return;
    }

    const userUpdate = {
      currentPassword: currentPassword,
      password: newPassword,
    };

    updateUserApi(token, userUpdate, user.id).then((result) => {
      if (result.message === "La contraseña actual indicada es incorrecta.") {
        notification["error"]({
          message: result.message,
        });
      } else {
        notification["success"]({
          message: result.message,
        });
      }
      setCurrentPassword(null);
      setNewPassword(null);
      setRepeatNewPassword(null);
      setVisibleModal(false);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",

        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: 50,

          width: "100%",
        }}
      >
        <span style={{ color: "#c3c3c3", paddingBottom: 3 }}>
          Contraseña actual
        </span>
        <Input.Password
          className="login-form__input"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          maxLength={30}
          autoComplete="new-password"
        />

        <span style={{ color: "#c3c3c3", paddingBottom: 3, paddingTop: 15 }}>
          Nueva contraseña
        </span>
        <Input.Password
          className="login-form__input"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          maxLength={30}
          autoComplete="new-password"
        />
        <span style={{ color: "#c3c3c3", paddingBottom: 3, paddingTop: 15 }}>
          Repetir nueva contraseña
        </span>
        <Input.Password
          className="login-form__input"
          value={repeatNewPassword}
          onChange={(e) => {
            setRepeatNewPassword(e.target.value);
          }}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          maxLength={30}
          autoComplete="new-password"
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={cancel}
          style={{ borderRadius: "20px !important" }}
          className="vivi-modal-btn btn-back"
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          style={{ borderRadius: "20px !important" }}
          className="vivi-modal-btn btn-ok"
          onClick={changePassword}
        >
          Cambiar
        </Button>
      </div>
    </div>
  );
}
