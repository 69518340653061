import React from "react";
import { Modal as ModalAntd } from "antd";

export default function Modal(props) {
  const { children, title, isVisible, setIsVisible, closable, ...other } =
    props;

  return (
    <ModalAntd
      title={title}
      centered
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      closable={closable}
      footer={false}
      {...other}
      style={{
        fontFamily: "Roboto",
      }}
    >
      {children}
    </ModalAntd>
  );
}
