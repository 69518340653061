import React from "react";
import { Spin, Form, Select, Card, Avatar, Collapse, Button } from "antd";
import NoAvatar from "../../assets/no-avatar.png";
import {
  CalendarOutlined,
  CaretRightOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import "./CardSelectDoctor.css";

export default function CardSelectDoctor({
  userActive,
  speciality,
  openModalCalendarReservation,
  previewConfirmAppoiment,
  openModalReasonCallEmergency,
}) {
  const { Panel } = Collapse;
  const history = useHistory();
  return (
    <Card className="card-medico">
      <div className="div-card-medico">
        <div className="avatar">
          <Avatar
            className="avatar-card-select-doctor"
            src={userActive?.imageUrl || NoAvatar}
          />
        </div>
        <div className="names-btn">
          <span className="name-doctor">{userActive.fullName || "-"}</span>
          <span className="specilitie-doctor">
            {userActive.branchesOfMedicine.map((a) => a.title)}
          </span>
        </div>
        <span className="counter-hour">
          {userActive.price === undefined ? "" : `$${userActive.price}`}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            zIndex: 2,
            paddingRight: 20,
          }}
        >
          {/* {userActive.emergency && (
            <Button
              className="emergency-btn"
              onClick={() => {
                openModalReasonCallEmergency(
                  userActive,
                  previewConfirmAppoiment
                );
              }}
            >
              <VideoCallOutlinedIcon style={{ color: "#fff", fontSize: 25 }} />
            </Button>
          )} */}

          <Button
            className="calendar-btn"
            onClick={() =>
              openModalCalendarReservation(userActive, previewConfirmAppoiment)
            }
          >
            <CalendarMonthOutlinedIcon
              style={{ color: "#fff", fontSize: 25 }}
            />
          </Button>
        </div>
      </div>

      <div>
        <Collapse
          collapsible="header"
          bordered={false}
          expandIconPosition="end"
          ghost={true}
          expandIcon={({ isActive }) => (
            <DownOutlined
              rotate={isActive ? 180 : 0}
              style={{
                fontWeight: "bold",
                fontSize: 20,
                width: "100%",
                height: "8vh",
                textAlign: "end",
                cursor: "pointer",
                marginLeft: "1%",
              }}
            />
          )}
          className="div-collapse"
        >
          <Panel key="1">
            <p
              style={{
                marginTop: "2rem",
                paddingLeft: "4.3rem",
                paddingRight: "8rem",
                color: "#7C7C81",
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              {userActive.bio || "-"}
            </p>
          </Panel>
        </Collapse>
      </div>
    </Card>
  );
}
