import { Button, Row, Col } from "antd";
import React from "react";
import moment from "moment";

export default function ModalApproveProfile({ user, changeStatus, openModalDecline }) {
  const changes = getDataOriginal();

  function getDataOriginal() {
    let data = JSON.parse(user.changes);
    return data;
  }

  return (
    <>
      {changes?.map(
        (change) =>
          <>
            <Row gutter={24}>
              <Col xl={8} xs={24}>
                <span
                  style={{
                    paddingRight: 5,
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  {change.title}:
                </span>
              </Col>
              <Col xl={8} xs={24}>
                <span
                  style={{ fontSize: 15 }}
                >
                  {change.value}
                </span>
              </Col>
              <Col xl={8} xs={24}>
                <span
                  style={{ fontSize: 15, fontWeight: "500", backgroundColor: "#fccf47" }}
                >
                  {(change.field === "date_of_birth") ? moment(user[change.field]).format('DD/MM/YYYY') : user[change.field]}
                </span>
              </Col>
            </Row>
          </>
      )}
      <Row gutter={24}>
        <Col xl={24} xs={24} style={{ paddingTop: "1rem", textAlign: "end" }}>
          <Button className="btnAnularCuenta" style={{ marginRight: "4%", }} onClick={() => openModalDecline({ user })}>
            Rechazar
          </Button>

          <Button
            onClick={() => changeStatus({ status: true, user: user })}
            className="btnAprobar"
          >
            Aprobar
          </Button>
        </Col>
      </Row>
    </>
  );
}
