import React, { useEffect, useState } from "react";
import { getAccessTokenApi } from "../../api/auth";
import { API_URL, apiVersion } from "../../api/config";
import axios from "axios";
import moment from "moment";
import "./PendingShifts.css";
import { CheckOutlined, EyeOutlined, RightOutlined } from "@ant-design/icons";

import { notification, Spin } from "antd";

export default function PendingShifts() {
  const token = getAccessTokenApi();

  const [appointmentsMonth, setAppointmentsMonth] = useState([]);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAppointmentsDoctor = async () => {
    setLoading(true);
    let url = `${API_URL}${apiVersion}/appointments?status=pending`;

    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    if (r?.data) {
      setLoading(false);

      const firstDayOfTheMonth = moment()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");

      const appoinmentsByMonth = r.data.appointments.filter(
        (app) =>
          moment(app.appointment_date).format("YYYY-MM-DD hh:mm") >=
          firstDayOfTheMonth
      );

      setAppointmentsMonth(appoinmentsByMonth);
    }
  };

  useEffect(() => {
    getAppointmentsDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const aceptedAppoinment = async (id) => {
    let url = `${API_URL}${apiVersion}/update-appointment/${id}`;

    const r = await axios
      .put(
        url,
        {
          status: "acepted",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => console.log(err));
    if (r?.data) {
      notification["success"]({
        message: r.data.message,
      });
      setReload(true);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 50,
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ height: "100vh" }}>
      <div style={{ paddingTop: 50, paddingLeft: 40 }}>
        <h2 style={{ fontWeight: "bold" }}>Consultas pendientes de aprobación</h2>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 30,
        }}
      >
        {appointmentsMonth.length > 0 &&
          appointmentsMonth?.map((cita) => (
            <div
              key={cita._id}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 60,
                paddingRight: 60,
                border: "1px solid black",
                alignItems: "center",
              }}
            >
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <RightOutlined style={{ marginRight: 5 }} />
                <span style={{ paddingRight: 10, fontWeight: "bold" }}>
                  Fecha:
                </span>
                {moment(cita.appointment_date).format("DD/MM/YYYY")}
                <span
                  style={{
                    paddingRight: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Hora:
                </span>
                {moment().startOf('day').add({ hours: cita.appointment_time.split(':')[0], minutes: cita.appointment_time.split(':')[1] }).format('HH:mm')}
                <span
                  style={{
                    paddingRight: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Doctor:
                </span>
                {cita.doctor?.fullName}
                <span
                  style={{
                    paddingRight: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Paciente:
                </span>
                {cita.patient?.fullName}
                <span
                  style={{
                    paddingRight: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Monto:
                </span>
                {cita.amount}
                <span
                  style={{
                    paddingRight: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Cupón:
                </span>
                {cita.coupon === null ? "No" : "Si"}
                <span
                  style={{
                    paddingRight: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Metodo de pago:
                </span>
                {cita.payment_method}
              </div>

              <div>
                <div>
                  <a href={cita.zelleVoucher} target="_blank" rel="noreferrer">
                    <EyeOutlined style={{ fontSize: 30 }} />
                  </a>
                  <CheckOutlined
                    onClick={() => aceptedAppoinment(cita._id)}
                    style={{ fontSize: 30, paddingLeft: 20, color: "#22BB33" }}
                  />
                </div>
              </div>
            </div>
          ))}

        {appointmentsMonth.length <= 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              No hay consultas pendiente de aprobación
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
