import React, { useState } from "react";
import { Form, Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import ModalVideoCall from "../../components/ModalVideoCall/ModalVideoCall";
import "./Videocall.css";
import { Col, Row } from "antd";
import { toast, ToastContainer } from "react-toastify";

const Videocall = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [reason, setReason] = useState("");

  const openModalVideoCall = () => {
    if (reason === "") {
      toast.error("Debe ingresar el motivo de la consulta", {
        position: "top-right",
        autoClose: 2800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setVisibleModal(true);
      setContentModal(
        <>
          <ModalVideoCall
            setVisibleModal={setVisibleModal}
            reasonWrite={reason}
          />
        </>
      );
    }
  };

  return (
    <>
      <Row>
        <Col xl={24} xs={24} className="full-img extraClass">
          <span className="text-ppal-page">Videollamada inmediata</span>
        </Col>

        <div className="containerTextVideollamadaInmediata">
          <Col xl={24} xs={24} className="text-container">
            <div
              style={{
                fontSize: "16px",
                fontWeight: "300",
                position: "relative",
                fontFamily: "Roboto",
              }}
            >
              <h2
                style={{ fontSize: "1.6rem", fontWeight: "500" }}
                className="textRoboto"
              >
                En esta sección podrás realizar una llamada de urgencia para que
                un profesional de guardia te atienda.
              </h2>
              <span className="textRoboto">
                Una vez completado el motivo de tu consulta pasarás a la sala de
                espera mientras buscamos un profesional disponible para
                atenderte.
              </span>
            </div>

            <Form.Item style={{ marginTop: "4%" }}>
              <label className="label-register">
                Motivo de tu consulta <span className="required"> *</span>
              </label>
              <TextArea
                className="register-form__input textarea-input"
                onChange={(e) => setReason(e.target.value)}
                value={reason}
              ></TextArea>
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                type="primary"
                className="btnAgendar"
                onClick={openModalVideoCall}
              >
                Aceptar
              </Button>
            </div>
          </Col>
        </div>
      </Row>
      <Modal
        children={contentModal}
        title="Confirma tu consulta"
        visible={visibleModal}
        setIsVisible={setVisibleModal}
        closable={false}
      />
      <ToastContainer />
    </>
  );
};

export default Videocall;
