import React, { useState, useEffect } from "react";
import { Card, Divider } from "antd";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

import "./appointmentsSide.css";

export default function AppointmentsSide({ appointments }) {
  const [appointmentsPending, setAppointmentsPending] = useState([]);

  useEffect(() => {
    if (appointments != null) {
      const filterAppointments = appointments.filter(f => new Date(f.appointment_date) >= new Date());
      if (filterAppointments.length > 0) {
        filterAppointments.sort((a, b) =>
          a.appointment_date.localeCompare(b.appointment_date));

        setAppointmentsPending(Array.from(filterAppointments));
      }
    }
    else
      setAppointmentsPending([]);
  }, []);

  const _renderItem = (item) => {
    let dateHora = new Date();
    dateHora.setUTCHours(item.appointment_time.split(':')[0]);
    dateHora.setUTCMinutes(item.appointment_time.split(':')[1]);
    let setHora = moment(dateHora).format('HH:mm');

    return (
      <Card className="card-side">
        <div style={{ padding: "2%" }}>
          <span className="card-span">
            {item.specialty.title}
          </span>
          <div className="card-div">
            <div>
              <div className="card-div-align">
                <CalendarOutlined />
                <span style={{ marginLeft: "7px" }}>
                  {item.date}
                </span>
              </div>
            </div>
            <div className="card-divide-info">
              <div className="card-div-align">
                <ClockCircleOutlined />
                <span style={{ marginLeft: "7px" }}>
                  {setHora}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div style={{ overflow: "auto" }}>
      <div style={{ padding: "18% 15% 0% 15%" }}>
        <h3 className="title-appo-side">
          Próximas citas
        </h3>

        <Divider></Divider>
      </div>
      <div>
        {appointmentsPending.map((app) => _renderItem(app))}
      </div>
    </div>
  );
}
