import { Button, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";

export default function ModalDeclineProfile({ user, changeStatus }) {
  const [reason, setReason] = useState("");

  useEffect(() => {
    setReason("");
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xl={24} xs={24}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              // width: "50%",
              paddingTop: 30,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="title-lateral-light">Motivo del rechazo</span>
              <TextArea
                className="title-lateral-bold"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></TextArea>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xl={24} xs={24} style={{ paddingTop: "1rem", textAlign: "end" }}>
          <Button className="btnAnularCuenta" style={{ marginRight: "4%", }} onClick={() => changeStatus({ status: false, user: user, comment: reason })}>
            Rechazar
          </Button>
        </Col>
      </Row>
    </>
  );
}
