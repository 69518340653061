import React, { useState } from "react";
import { View } from "react-native";
import {
  signInWithGoogle,
  // signInWithFacebook,
  // signInWithApple,
} from "../../services/firebase";
import { Form, Input, Button, Tabs } from "antd";
import RegisterForm from "../../components/registerform";
import "./loginForm.css";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

export const LoginForm = (props) => {
  const { state, setState, stateUser, setStateUser, keyNumber, setKeyNumber } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [showButton, setShowButton] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const items = [
    {
      key: "1",
      label: "Paciente",
    },
    {
      key: "2",
      label: "Profesional",
    },
  ];

  const onChangeTabs = (key) => {
    if (key === "1") {
      setStateUser("isPatient");
      setKeyNumber(key);
    } else {
      setStateUser("isMedical");
      setKeyNumber(key);
    }
  };

  const clickLoginGoogle = () => {
    signInWithGoogle(true);
  };

  const keyPressEnter = (event) => {
    if (event.key === "Enter") {
      props.login(credentials.email, credentials.password);
    }
  };

  return (
    <div
      style={{
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
      }}
    >
      {state === "login" && (
        <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
          <div className="div-container-login-patient">
            <Tabs
              defaultActiveKey={keyNumber}
              items={items}
              onChange={onChangeTabs}
              hideAdd={true}
            />
            {stateUser === "isPatient" && (
              <>
                <div className="div-container-login">
                  <h1 className="registro-name">Inicio de sesión</h1>

                  <Form.Item style={{ width: "100%" }}>
                    <label className="label-login">Correo electrónico</label>
                    <Input
                      className="login-form__input"
                      value={credentials.email}
                      onChange={(e) => {
                        setCredentials({
                          ...credentials,
                          email: e.target.value.trim(),
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item style={{ width: "100%" }}>
                    <label className="label-login">Contraseña</label>
                    <Input.Password
                      className="login-form__input"
                      value={credentials.password}
                      onChange={(e) => {
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        });
                      }}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onKeyDown={keyPressEnter}
                    />
                  </Form.Item>

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      props.login(credentials.email, credentials.password);
                    }}
                    className="signIn__button"
                    loading={props.isloading}
                    disabled={props.isloading}
                  >
                    Ingresar
                  </Button>

                  {showButton && (
                    <div className="div-btn-medical">
                      <button className="btn-medical-login">
                        Soy paciente
                      </button>
                    </div>
                  )}

                  <View
                    style={{
                      alignItems: `center`,
                      justifyContent: `space-evenly`,
                      width: `100%`,
                      marginTop: "8%",
                    }}
                  >
                    <div className="div-container-btn-social-networks">
                      {/* <button
                      className="login-with-facebook-btn"
                      onClick={signInWithFacebook}
                    ></button> */}

                      <button
                        className="login-with-google-btn"
                        onClick={clickLoginGoogle}
                      ></button>

                      {/* <button
                      className="login-with-apple-btn"
                      onClick={signInWithApple}
                    ></button> */}
                    </div>

                    <div className="div-btn-registration">
                      ¿No tienes una cuenta?{" "}
                      <span
                        style={{ color: "#4177c6", cursor: "pointer" }}
                        onClick={() => {
                          setState("registration");
                        }}
                      >
                        Registrarme
                      </span>
                    </div>

                    <div className="div-btn-registration">
                      ¿Ha olvidado su contraseña?{" "}
                      <span
                        style={{ color: "#4177c6", cursor: "pointer" }}
                        onClick={() => {
                          props.forgotPassword(credentials.email);
                        }}
                      >
                        Haz click aquí
                      </span>
                    </div>
                  </View>
                </div>
              </>
            )}

            {stateUser === "isMedical" && (
              <>
                <div className="div-container-login">
                  <h1 className="registro-name">Inicio de sesión</h1>

                  <Form.Item style={{ width: "100%" }}>
                    <label className="label-login">Correo electrónico</label>
                    <Input
                      className="login-form__input"
                      value={credentials.email}
                      onChange={(e) => {
                        setCredentials({
                          ...credentials,
                          email: e.target.value.trim(),
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item style={{ width: "100%" }}>
                    <label className="label-login">Contraseña</label>
                    <Input.Password
                      className="login-form__input"
                      value={credentials.password}
                      onChange={(e) => {
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        });
                      }}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onKeyDown={keyPressEnter}
                    />
                  </Form.Item>

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      props.login(credentials.email, credentials.password);
                    }}
                    className="signIn__button"
                    loading={props.isloading}
                    disabled={props.isloading}
                  >
                    Ingresar
                  </Button>

                  {showButton && (
                    <div className="div-btn-medical">
                      <button className="btn-medical-login">
                        Soy paciente
                      </button>
                    </div>
                  )}

                  <View
                    style={{
                      alignItems: `center`,
                      justifyContent: `space-evenly`,
                      width: `100%`,
                      marginTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <div className="div-btn-registration">
                      ¿No tienes una cuenta?{" "}
                      <span
                        style={{ color: "#4177c6", cursor: "pointer" }}
                        onClick={() => {
                          setState("registration");
                        }}
                      >
                        Registrarme
                      </span>
                    </div>

                    <div className="div-btn-registration">
                      ¿Ha olvidado su contraseña?{" "}
                      <span
                        style={{ color: "#4177c6", cursor: "pointer" }}
                        onClick={() => {
                          setState("registration");
                        }}
                      >
                        Haz click aquí
                      </span>
                    </div>
                  </View>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {state === "registration" && <RegisterForm />}
    </div>
  );
};
