import React, { useState, useEffect } from "react";
import { Button, Tabs, Card, Divider, Row, Col } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import Room from "../twilio/room";
import { getAccessTokenApi } from "../../api/auth";
import { API_URL, apiVersion } from "../../api/config";
import { VideoCameraFilled } from "@ant-design/icons";
import "./appointments.css";
import { useHistory } from "react-router-dom";
import { updateUserSocket } from "../../services/socket";

const Appointments = (props) => {
  const { dataEmergency, pagoMovil } = props;
  const token = getAccessTokenApi();
  const user = JSON.parse(localStorage.getItem("USER"));

  const navigate = useHistory();

  const handleLogout = () => {
    window.location.reload();
  };
  const [twilioHandler, setTwilioHandler] = useState({
    isAudioEnabled: true,
    status: `disconnected`,
    isLoading: false,
    token: null,
  });
  const [hasCheckedAppointments, setHasCheckedAppointments] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsPast, setAppointmentsPast] = useState([]);
  const [dataVideo, setDataVideo] = useState({});

  const upcomingAppointments = () => {
    if (
      twilioHandler.status !== "connected" &&
      twilioHandler.status !== "connecting"
    )
      return (
        <Row>
          <Col xl={24} xs={24} style={{ padding: "0rem 1rem" }}>
            {/* <div className="container-turnos"> */}
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Próximas Consultas" key="1">
                {appointments.map((app) => {
                  return (
                    <div className="containerCardsAppoiments">
                      {_renderItem(app)}
                    </div>
                  );
                })}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Consultas Pasadas" key="2">
                {appointmentsPast.map((app) => {
                  return (
                    <div className="containerCardsAppoiments">
                      {_renderItem(app)}
                    </div>
                  );
                })}
              </Tabs.TabPane>
            </Tabs>
            {/* </div> */}
          </Col>
        </Row>
      );
  };

  async function checkForRoom(id) {
    let arr = [];
    // const polling = setInterval(async () => {
    let url = `${API_URL}${apiVersion}/appointment/${id}`;
    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.log("error", err));
    if (r?.data) {
      const appt = { ...r.data, ...r.data.doctor, ...r.data.patient };
      const idx = arr.findIndex((a) => a?._id === id);
      if (idx >= 0) {
        setAppointments([
          ...arr.slice(0, idx),
          { ...arr[idx], room_id: appt.room_id },
          ...arr.slice(idx + 1),
        ]);
      }
    }
  }

  async function getAppointments() {
    // let arr = [];
    if (user.role === "patient") {
      let url = `${API_URL}${apiVersion}/appointments-patient?patient_id=${user._id}`;

      const r = await axios
        .get(url, {
          headers: {
            Authorization: token,
          },
        })
        .catch((err) => console.log(err));
      if (r?.data) {
        // console.log('set');
        // console.log('data -> ', r.data);
        // get device timezone eg. -> "Asia/Shanghai"
        const deviceTimeZone = moment.tz.guess();
        // Make moment of right now, using the device timezone
        const today = moment().tz(deviceTimeZone);
        // Get the UTC offset in hours
        const currentTimeZoneOffsetInHours = today.utcOffset() / 60;
        let appts;

        if (r.data.appointments.length > 0) {
          appts = r.data.appointments.map((appt) => {
            let apptDate = moment
              .utc(appt.appointment_date)
              .utcOffset(currentTimeZoneOffsetInHours);
            let appDateInSeconds = apptDate.valueOf();
            let now = moment().valueOf();
            const HOUR_IN_SECONDS = 1000 * 60 * 60;
            if (appDateInSeconds - now < HOUR_IN_SECONDS) {
              // console.log('GOING UNDER');
              checkForRoom(appt._id);
            }
            return { ...appt, appointment_date: apptDate.format() };
          });
        }
        if (appts && appts.length > 0) {
          let fecha = moment().format("YYYY-MM-DD");
          let citas = [];
          let citasPasadas = [];
          // eslint-disable-next-line array-callback-return
          appts.map((appt) => {
            if (moment(appt.appointment_date).format("YYYY-MM-DD") >= fecha) {
              appt.showBtn = true;
              citas.push(appt);
            } else {
              appt.showBtn = false;
              citasPasadas.push(appt);
            }
          });
          setAppointments(citas);
          setAppointmentsPast(citasPasadas);
          // setAppointments(appts);
        }
      }
    } else if (user.role === "doctor") {
      let url = `${API_URL}${apiVersion}/appointments-doctor?doctor_id=${user._id}`;

      const r = await axios
        .get(url, {
          headers: {
            Authorization: token,
          },
        })
        .catch((err) => console.log(err));
      if (r?.data) {
        // console.log('set');
        // console.log('data -> ', r.data);
        // get device timezone eg. -> "Asia/Shanghai"
        const deviceTimeZone = moment.tz.guess();
        // Make moment of right now, using the device timezone
        const today = moment().tz(deviceTimeZone);
        // Get the UTC offset in hours
        const currentTimeZoneOffsetInHours = today.utcOffset() / 60;

        let appts = r.data.appointments.map((appt) => {
          let apptDate = moment
            .utc(appt.appointment_date)
            .utcOffset(currentTimeZoneOffsetInHours);
          let appDateInSeconds = apptDate.valueOf();
          let now = moment().valueOf();
          const HOUR_IN_SECONDS = 1000 * 60 * 60;
          if (appDateInSeconds - now < HOUR_IN_SECONDS) {
            // console.log('GOING UNDER');

            checkForRoom(appt._id);
          }
          return { ...appt, appointment_date: apptDate.format() };
        });
        if (appts && appts.length > 0) {
          let fecha = moment().format("YYYY-MM-DD");
          let citas = [];
          let citasPasadas = [];
          // eslint-disable-next-line array-callback-return
          appts.map((appt) => {
            if (moment(appt.appointment_date).format("YYYY-MM-DD") >= fecha) {
              appt.showBtn = true;
              citas.push(appt);
            } else {
              appt.showBtn = false;
              citasPasadas.push(appt);
            }
          });
          setAppointments(citas);
          setAppointmentsPast(citasPasadas);
          // setAppointments(appts);
        }
      }
    }
  }

  useEffect(() => {
    if (pagoMovil) {
      getAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagoMovil]);

  useEffect(() => {
    updateUserSocket(user._id);

    // eslint-disable-next-line no-unused-vars
    let pastAtrr = [];
    let mainPolling = null;

    // eslint-disable-next-line no-unused-vars
    let componentMounted = true;

    if (!hasCheckedAppointments) {
      if (user) {
        getAppointments();
        // getPastAppointments();
        setHasCheckedAppointments(true);
      }
      if (mainPolling) {
        clearInterval(mainPolling);
        mainPolling = null;
      }
    }
    if (hasCheckedAppointments) {
      mainPolling = setInterval(() => {
        if (twilioHandler.status === "disconnected") {
          if (user) {
            getAppointments();
            // getPastAppointments();
          }
        }
      }, 60 * 1000);
    }

    return () => {
      componentMounted = false;
      if (mainPolling) {
        clearInterval(mainPolling);
        mainPolling = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  async function _connectToRoom(item) {
    if (item.room_id !== undefined) {
      setDataVideo({
        ...dataVideo,
        doctor: item.doctor,
        specialty: item.specialty?.title,
        patient: item.patient,
        start: new Date(item.dateCompletedStart),
        end: new Date(item.dateCompletedEnd),
        emergency: dataEmergency !== undefined,
      });
      let room;

      room = await _joinRoom(item.doctor?._id, item.room_id);

      if (room._id) {
        const data = await fetch(`${API_URL}${apiVersion}/videoToken`, {
          method: "POST",
          body: JSON.stringify({
            identity: user.fullName,
            room: room._id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => res.json());

        if (
          data.token &&
          data.token !== "" &&
          data.token !== "null" &&
          room._id
        ) {
          setTwilioHandler({
            ...twilioHandler,
            isLoading: false,
            status: "connecting",
            token: data.token,
            roomName: room._id,
          });
        } else {
          setTwilioHandler({
            ...twilioHandler,
            isLoading: false,
            status: "disconnected",
          });
          alert("Error", "No fue posible conectarse a la sala");
        }
      } else {
        alert("Error", "No fue posible crear a la sala");
      }
    } else {
      navigate.push({
        pathname: "/waitingRoom",
        objeto: {
          isSchedule: !item?.emergency,
          appointmentId: item?._id,
          isAppointment: false,
          appointment: item,
        },
      });
    }
  }

  const _joinRoom = async (doctorId, roomId) => {
    const { data } = await axios
      .get(`${API_URL}${apiVersion}/room/${roomId}`, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => console.error(err));

    return data.room;
  };

  useEffect(() => {
    if (dataEmergency !== undefined) {
      let url = `${API_URL}${apiVersion}/appointment/${dataEmergency.appointment._id}`;
      axios
        .get(url, {
          headers: {
            Authorization: token,
          },
        })
        .then(async (res) => {
          setDataVideo({
            ...dataVideo,
            doctor: res?.data.appointment.doctor?.fullName,
            specialty: res?.data.appointment.specialty?.title,
            patient: dataEmergency.patient?.fullName,
            start: new Date(res?.data.appointment.dateCompletedStart),
            end: new Date(res?.data.appointment.dateCompletedEnd),
            emergency: true,
          });

          if (res?.data.appointment.room_id !== undefined) {
            let room = await _joinRoom(
              dataEmergency.appointment.doctor_id,
              res?.data.appointment.room_id
            );

            const data = await fetch(`${API_URL}${apiVersion}/videoToken`, {
              method: "POST",
              body: JSON.stringify({
                identity: user.fullName,
                room: room,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => res.json());

            if (
              data.token &&
              data.token !== "" &&
              data.token !== "null" &&
              room
            ) {
              setTwilioHandler({
                ...twilioHandler,
                isLoading: false,
                status: "connecting",
                token: data.token,
                roomName: room._id,
              });
            } else {
              setTwilioHandler({
                ...twilioHandler,
                isLoading: false,
                status: "disconnected",
              });
              alert("Error", "No fue posible conectarse a la sala");
            }
          }
        })
        .catch((err) => console.log("error", err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasCheckedAppointments) {
    return null;
  }

  const _renderItem = (item) => {
    let dateHora = new Date();
    dateHora.setUTCHours(item.appointment_time.split(":")[0]);
    dateHora.setUTCMinutes(item.appointment_time.split(":")[1]);
    let setHora = moment(dateHora).format("HH:mm");

    return (
      <Card className="card-proximos-turnos" key={item._id}>
        <div
          className="cardInfo"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "100%", padding: "1rem" }}>
            <span
              className="proxConsulta"
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                display: item.showBtn ? "flex" : "none",
              }}
            >
              Próxima Consulta
            </span>
            <div className="dateAppointment">
              <span style={{ marginRight: "1rem" }}>
                {moment(item.appointment_date).format("DD/MM/YYYY")}
              </span>
              {/* <span style={{ marginRight: "1rem" }}>a las</span> */}
              <span>{setHora} hs</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className="containerPacienteName"
            >
              <span style={{ marginRight: "1rem", fontSize: "1.1rem" }}>
                Paciente:
              </span>
              <span
                style={{
                  marginRight: "1rem",
                  fontSize: "1.1rem",
                  textTransform: "capitalize",
                }}
              >
                {item.patient?.fullName}
              </span>
            </div>

            <Divider style={{ margin: "5px" }}></Divider>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {item.specialty?.title || "null"}
            </span>
          </div>
          <div className="cardContainer2">
            <span
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.1rem",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {user.role === "patient"
                ? `${
                    item.doctor?.fullName === undefined
                      ? ""
                      : `${
                          item.doctor?.gender === "Femenino" ? "Dra" : "Dr"
                        }. ${item.doctor?.fullName}`
                  }`
                : `${item.patient?.fullName}`}
            </span>

            <div
              style={{
                display: item.showBtn ? "flex" : "none",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                style={{
                  background: "#006AFF",
                }}
                onClick={() => _connectToRoom(item)}
                // disabled={
                //   !(
                //     twilioHandler.isLoading ||
                //     item.room_id ||
                //     (role === "doctor" && isSoon)
                //   )
                // }
                loading={twilioHandler.isLoading}
                className="btn-appointment"
                type="primary"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <VideoCameraFilled style={{ fontSize: "1.5rem" }} />
                  <span style={{ paddingLeft: "5px" }}>Ingresar</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  if (appointments.length <= 0 && setAppointmentsPast.length <= 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: "3rem",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: 20, margin: "0 auto" }}>
          {" "}
          No tienes Consultas pendientes
        </span>
      </div>
    );
  }

  if (twilioHandler.token) {
    return (
      <Room
        roomName={twilioHandler.roomName}
        token={twilioHandler.token}
        handleLogout={handleLogout}
        dataVideo={dataVideo}
        appointments={null}
      />
    );
  } else {
    return (
      <>
        <Row>
          <Col xl={24} xs={24} style={{ padding: "3rem 0rem 2rem 1rem" }}>
            <span className="span-text-title"
              style={{ marginTop: "3rem" }}
            >
              Mis consultas
            </span>
          </Col>
        </Row>
        <Row>
          <Col xl={24} xs={24}>
            {upcomingAppointments()}
          </Col>
        </Row>
      </>
    );
  }
};

export default Appointments;
