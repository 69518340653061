import React, { useEffect, useState } from "react";
import { getAccessTokenApi } from "../../api/auth";
import { API_URL, apiVersion } from "../../api/config";
import axios from "axios";
import moment from "moment";
import "./Transactions.css";
import { Spin, Row, Col, Form, DatePicker, Button } from "antd";
import DataTable from "../../components/DataTable";

export default function Transactions() {
  const token = getAccessTokenApi();

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateInit, setDateInit] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateFinish, setDateFinish] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const columns = [
    {
      header: "Referencia",
      accessorKey: "Nroref",
    },
    {
      header: "Fecha",
      accessorKey: "Created",
    },
    {
      header: "Monto",
      accessorKey: "Amount",
    },
    {
      header: "Banco",
      accessorKey: "Banco",
    },
    {
      header: "Paciente",
      accessorKey: "Patient",
    },
    {
      header: "Especialista",
      accessorKey: "Doctor",
    },
    {
      header: "Tipo de pago",
      accessorKey: "Type",
    },
  ];

  let data = [];

  const getTransactionsPagoMovil = async () => {
    setLoading(true);
    let url = `${API_URL}${apiVersion}/list-appointments-pagomovil?date_start=${dateInit}&date_end=${dateFinish}`;

    const r = await axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    if (r?.data) {
      r.data.appointments.forEach((element) => {
        var dataElement = JSON.parse(element.transaction);
        if (dataElement.Banco === "card") {
          dataElement.Created = element.created;
          dataElement.Amount = element.amount;
        }
        dataElement.Patient = element.patient?.fullName;
        dataElement.Doctor = element.doctor?.fullName;
        dataElement.Created = moment(dataElement.Created).format("DD/MM/YYYY");
        dataElement.Amount = dataElement.Amount?.toString();

        data.push(dataElement);
      });
      setTransactions(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactionsPagoMovil();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTransactions]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 50,
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row
        gutter={24}
        className="row-without-margin"
        style={{ height: "100vh", backgroundColor: "#fff" }}
      >
        <Col xs={0} xl={1}></Col>
        <Col xs={24} xl={22} className="row-without-padding">
          <Row
            gutter={24}
            className="row-without-margin"
            style={{ alignItems: "center" }}
          >
            <Col
              span={24}
              className="row-without-padding"
              style={{ paddingTop: "3rem" }}
            >
              <Row gutter={24} className="row-without-margin">
                <Col span={24}>
                  <span className="span-text-title">
                    Transacciones realizadas
                  </span>
                </Col>
              </Row>
              <Row
                gutter={24}
                className="row-without-margin"
                style={{ paddingTop: "2rem" }}
              >
                <Col xl={6} xs={24}>
                  <Form.Item>
                    <label className="label-register">Desde</label>
                    <DatePicker
                      value={dateInit !== undefined ? moment(dateInit) : null}
                      onChange={(e) =>
                        setDateInit(
                          moment(e == null ? new Date() : e).format(
                            "YYYY-MM-DD"
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xl={6} xs={24}>
                  <Form.Item>
                    <label className="label-register">Hasta</label>
                    <DatePicker
                      value={
                        dateFinish !== undefined ? moment(dateFinish) : null
                      }
                      onChange={(e) =>
                        setDateFinish(
                          moment(e == null ? new Date() : e).format(
                            "YYYY-MM-DD"
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xl={4} xs={24} style={{ alignSelf: "center" }}>
                  <Button
                    onClick={getTransactionsPagoMovil}
                    style={{
                      height: "32px",
                      color: `white`,
                      borderRadius: "50px",
                    }}
                    className="ant-btn ant-btn-default btnAprobar btn-background-color-vivi"
                  >
                    Buscar
                  </Button>
                </Col>
                <Col xl={8} xs={0}></Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={24} className="row-without-margin">
            <Col xl={24} xs={24} className="row-without-padding">
              <DataTable
                columns={columns}
                data={transactions}
                classDataTable={"dt-transaction"}
                showDownload={true}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} xl={1}></Col>
      </Row>
    </>
  );
}
