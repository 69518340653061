import React, { useState, useCallback } from "react";
import { Row, Col } from "antd";
import { useDropzone } from "react-dropzone";

// import { CloseOutlined } from "@ant-design/icons";
import { v4 } from "uuid";
import "./UploadFiles.css";
const UploadFiles = (props) => {
  const { setFileSelected, strClass } = props;

  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function UploadFile(props) {
    const { setFiles, setIsLoading } = props;

    const onDrop = useCallback(
      (acceptedFiles) => {
        let filesArray = [];
        setIsLoading(true);
        // const file = acceptedFiles[0];
        // setFile({ file, preview: URL.createObjectURL(file) });
        // setLeadData({ ...leadData, avatar: file });
        acceptedFiles.map(async (file) => {
          let object = {};
          let extSplit = file.name.split(".");
          let fileExt = extSplit[1];

          // const convertedFile = await convertToBase64(file);
          object = {
            id: v4(),
            documentName: file.name,
            type: file.type,
            fileExtension: fileExt,
            file: file,
          };

          filesArray.push(object);
        });

        setIsLoading(false);
        setFiles(filesArray);
        setFileSelected(filesArray);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setFiles]
    );

    const { getRootProps, getInputProps } = useDropzone({
      noKeyboard: true,
      onDrop,
    });

    return (
      <div className="" style={{ marginTop: "2%" }} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={strClass}>
          {files ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {files.map((file, index) => (
                <span style={{ color: "#c3c3c3" }} key={index}>
                  {" "}
                  {file.file?.path?.substr(0, 20) +
                    "... ." +
                    file?.fileExtension}
                </span>
              ))}
            </div>
          ) : (
            <div style={{ display: "grid", textAlign: "center" }}>
              {/* <div className="ant-btn ant-btn-default btn-upload-file"> */}
              <div style={{ fontWeight: 600, fontSize: "1.2rem", fontFamily: "Roboto", }}>
                Haga click o arrastre el archivo
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <Row gutter={24}>
      <Col span={24}>
        <UploadFile
          files={files}
          setFiles={setFiles}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Col>
    </Row>
  );
};
export default UploadFiles;
