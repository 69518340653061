import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import ChangePassword from '../../components/ChangePassword';
import Modal from "../../components/Modal/";
import useAuth from "../../hooks/useAuth";
import "./ResetPassword.css";
import { Button, Input, Row, notification, Col } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import Logo from "../../assets/logo_vivi.png";
import { validateTokenAPI } from "../../api/auth";
import { updateUserForgotPassApi } from "../../api/user";
import jwtDecode from "jwt-decode";

const ResetPassword = () => {
  const { token } = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const [contentModal, setContentModal] = useState(null);
  const [title, setTitle] = useState(null);
  const [userDecode, setUserDecode] = useState(null);

  const [newPassword, setNewPassword] = useState(null);
  const [repeatNewPassword, setRepeatNewPassword] = useState(null);

  const cancel = () => {
    setNewPassword(null);
    setRepeatNewPassword(null);

    window.location.href = "/";
  };

  const changePassword = () => {
    // const token = getAccessTokenApi();
    if (!newPassword || !repeatNewPassword) {
      notification["error"]({
        message: "Debe completar todos los campos requeridos ",
      });
      return;
    }
    if (newPassword !== repeatNewPassword) {
      notification["error"]({
        message: "Las contraseñas no coinciden",
      });
      return;
    }

    if (newPassword.length < 8) {
      notification["error"]({
        message: "La contraseña debe tener al menos 8 caracteres",
      });
      return;
    }

    const userUpdate = {
      password: newPassword,
      email: userDecode.email
    };

    updateUserForgotPassApi(token.replace(':', ''), userUpdate).then((result) => {
      if (result.message === "La contraseña actual indicada es incorrecta.") {
        notification["error"]({
          message: result.message,
        });
      } else {
        notification["success"]({
          message: result.message,
        });
      }
      setNewPassword(null);
      setRepeatNewPassword(null);

      window.location.href = "/";
    });
  };

  useEffect(() => {
    validateToken();
    const userDecode = jwtDecode(token);
    localStorage.setItem("USER", userDecode);
    setUserDecode(userDecode);
  }, []);

  async function validateToken() {
    var valToken = await validateTokenAPI(token);
    if (!valToken.isValid) {
      return (
        <>
          <Redirect to="/landing" />
        </>
      );
    }
  }

  return (
    <div className='reset-pass-main'>
      <div style={{ textAlign: "center" }}>
        <img src={Logo} className="logo-landing" alt="logo vivi" />
      </div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}></Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginTop: "2%", height: "50%", padding: "3rem" }} className='div-container-registration-patient'>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 50,
            }}
          >
            <h1 className="registro-name">Recupera tu contraseña</h1>
            <label className='label-login'>
              Nueva Contraseña
            </label>
            <Input.Password
              className="login-form__input"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              autoComplete="new-password"
            />
            <label className='label-login' style={{ paddingBottom: 3, paddingTop: 15 }}>
              Repetir Nueva Contraseña
            </label>
            <Input.Password
              className="login-form__input"
              value={repeatNewPassword}
              onChange={(e) => {
                setRepeatNewPassword(e.target.value);
              }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              autoComplete="new-password"
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={cancel}
              style={{ borderRadius: "20px !imoprtant" }}
              className="vivi-modal-btn btn-back"
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              style={{ borderRadius: "20px !important" }}
              className="vivi-modal-btn btn-ok"
              onClick={changePassword}
            >
              Cambiar
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}></Col>
      </Row>
    </div>
  );
};

export default ResetPassword;