import React, { useState, useEffect } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useHistory } from "react-router-dom";
import { API_URL, apiVersion } from "../../api/config";
import { getAccessTokenApi } from "../../api/auth";
import { Spin } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import ConfirmAppointmentModal from "../../components/confirmappointmentmodal";

// const STRIPE_PUBLISHABLE_KEY = STRIPE_KEY;

// const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function PaymentPlatform() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const objeto = location?.state?.objeto;
  const token = getAccessTokenApi();
  const [price] = useState(
    objeto?.emergency ? objeto?.price : objeto?.doctor.price
  );
  const [coupon] = useState({
    isValid: false,
    coupon: null,
    text: "",
  });
  //const navigate = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [clientSecret, setClientSecret] = useState("");

  const [appointmentId, setAppointmentId] = useState("");

  useEffect(() => {
    if (objeto === undefined || objeto === null) {
      history.push("/");
    } else {
      const selectedDate = objeto?.date.date;
      const time = objeto?.time;
      const doctor = objeto?.doctor;
      const emergency = objeto?.emergency;

      setLoading(true);
      const apptDate = moment(selectedDate)
        .hour(time.split(":")[0])
        .minute(time.split(":")[1])
        .seconds(0)
        .millisecond(0);

      const date = apptDate.format("YYYY-MM-DD");

      const dateCompletedStart = moment(date + " " + time);
      const dateCompletedEnd = moment(dateCompletedStart).add(20, "minutes");

      axios
        .post(
          `${API_URL}${apiVersion}/create-appointment`,
          {
            appointment_date: apptDate.utc().format("YYYY-MM-DD HH:mm:ss"),
            appointment_time: dateCompletedStart.utc().format("HH:mm"),
            dateCompletedStart: dateCompletedStart,
            dateCompletedEnd: dateCompletedEnd,
            amount: price,
            doctor_id: doctor._id,
            doctor: doctor._id,
            payment_method: "stripe",
            coupon_id: coupon.isValid && coupon?._id ? coupon?._id : null,
            coupon: coupon.isValid && coupon?._id ? coupon._id : null,
            date: date,
            // secret_stripe: data.client_secret,
            status: "pending",
            specialty: objeto?.specialty._id,
            reason: objeto?.reason,
            emergency: emergency ?? false,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .catch(() => {
          setLoading(false);
        })
        .then((dataAppointment) => {
          if (dataAppointment) {
            setAppointmentId(dataAppointment.data.appointment._id);
            const url = `${API_URL}${apiVersion}/create-payment-intent`;
            const params = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              body: JSON.stringify({
                // doctor: objeto?.doctor.fullName,
                // specialty: objeto?.specialty.title,
                // amount: price * 100,
                // emergency: objeto?.emergency,
                // appointmentId: dataAppointment.data.appointment._id
                amount: price * 100,
              }),
            };

            fetch(url, params)
              .then((res) => res.json())
              .then((data) => {
                setClientSecret(data.clientSecret);
                setLoading(false);
              });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const appearance = { /* appearance */ }
  //   const options = { /* options */ }
  //   const elements = .Elements({
  //     mode: 'payment',
  //     amount: 1099,
  //     currency: 'usd',
  //     appearance,
  //   })
  //   const expressCheckoutElement = elements.create('expressCheckout', options)
  //   expressCheckoutElement.mount('#express-checkout-element')
  // });

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        background: "#ffff",
      }}
    >
      {/* <Elements stripe={stripePromise} options={options} > */}
      <ConfirmAppointmentModal
        appointmentId={appointmentId}
        item={{
          doctor: objeto?.doctor,
          time: objeto?.time,
          emergency: objeto?.emergency,
        }}
        selectedDate={objeto?.date}
        //   setConfirmSchedule={setConfirmSchedule}
        reason={objeto?.reason}
        specialtyTitle={objeto?.specialty.title}
        specialty={objeto?.specialty._id}
        price={price}
      />
      {/* </Elements> */}
    </div>
    // <>
    //   <div id="checkout" style={{
    //     background: "#ffff",
    //     height: "100vh"
    //   }}>
    //     {clientSecret && (
    //       <EmbeddedCheckoutProvider
    //         stripe={stripePromise}
    //         options={options}
    //       >
    //         <EmbeddedCheckout />
    //       </EmbeddedCheckoutProvider>
    //     )}
    //   </div>
    // </>
  );
}
