import { React, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import "./datatable.css";
import { CSVLink } from "react-csv";
import { Row, Col, Button } from "antd";
import Filter from "./FilterFunction";

export default function DataTable({
  columns,
  data,
  classDataTable,
  showDownload,
}) {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });

  return (
    <>
      <Row
        gutter={24}
        style={{ marginLeft: "0px", marginRight: "0px", paddingBottom: "1rem" }}
      >
        <Col
          xl={24}
          xs={24}
          style={{
            textAlign: "end",
            display: showDownload ? "inline" : "none",
          }}
          className="btn-datatable-mobile"
        >
          <Button className="ant-btn ant-btn-default btnAprobar btn-background-color-vivi">
            <CSVLink
              data={data}
              style={{ textDecoration: "none" }}
              filename="Transacciones.csv"
            >
              Exportar a CSV
            </CSVLink>
          </Button>
        </Col>
        {/* <Col xl={12} xs={0}>
        </Col> */}
      </Row>
      <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Col span={24}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#F0F2F5",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
            className={classDataTable}
          >
            <table className="separado">
              <thead style={{ borderBottom: "1px solid #c3c3c3" }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <th
                        style={{
                          paddingLeft: index === 0 ? "20px" : "10px",

                          cursor: "pointer",
                        }}
                        key={header.id}
                        id={header.column.columnDef.header}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                              {
                                { asc: "⬆️", desc: "⬇️" }[
                                  header.column.getIsSorted() ?? null
                                ]
                              }
                            </div>

                            <div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} style={{ height: "40px" }}>
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        style={{
                          paddingLeft: index === 0 ? "20px" : "0px",
                          textTransform:
                            cell.column.columnDef.accessorKey === "fullName" ||
                            cell.column.columnDef.accessorKey === "Patient" ||
                            cell.column.columnDef.accessorKey === "Doctor"
                              ? "capitalize"
                              : "",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  );
}
