import { Button } from "antd";
import React from "react";
import styled from "styled-components";

import error404 from "../assets/error-404.webp";

const Div = styled.div`
   {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100vh;
  }

  img {
    max-width: 100%;
  }
`;

export default function Error404() {
  const volverHome = () => {
    window.location.href = "/";
  };
  return (
    <Div>
      <img src={error404} alt="imagen error 404" />
      <br />
      <br />
      <br />
      <Button
        onClick={volverHome}
        style={{
          height: 30,
          paddingLeft: 30,
          paddingRight: 30,
          backgroundColor: "#c3c3c3",
          color: "white",
        }}
      >
        Volver
      </Button>
    </Div>
  );
}
