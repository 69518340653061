import React from "react";
import { View, StyleSheet } from "react-native";
import { Theme } from "../../theme";

const Divider = (props) => {
  return (
    <View
      style={[
        styles.container,
        props.style,
        props.h16 ? { marginHorizontal: 16 } : null,
      ]}
    />
  );
};

export default Divider;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Theme.colors.dividerColor,
    height: 1,
  },
});
